import React from 'react';

import NoData from '../NoData';

// import { getElementByKey } from 'helpers/utils';

// const InfoSourceItem = ({ data }) => {
// 	const indicatorName = getElementByKey(data, 'indicator');
// 	const sourceName = getElementByKey(data, 'source_name', 'N/A');
// 	const sourceLink = getElementByKey(data, 'source_link');
// 	const sourceUpdated = getElementByKey(data, 'source_last_update');
//
// 	const getSourceName = () => `${sourceName},` || 'N/A,';
//
// 	return (
// 		<div className="info-source__item">
// 			<div className="title">{indicatorName}</div>
// 			<div className="info">
// 				{sourceLink ? (
// 					<a href={sourceLink} target="_blank" rel="noreferrer">
// 						{getSourceName()}
// 					</a>
// 				) : (
// 					getSourceName()
// 				)}
// 				<div>&nbsp;last updated {sourceUpdated || 'N/A'}</div>
// 			</div>
// 		</div>
// 	);
// };

const InfoSource = ({ text }) => {
	if (!text) return <NoData />;

	return <div dangerouslySetInnerHTML={{ __html: text }} />;

	/* Previous implementation. Needs additional check */

	// return (
	// 	<div className="info-source">
	// 		{data.map((item, key) => (
	// 			<InfoSourceItem
	// 				data={item}
	// 				key={item.indicator ? item.indicator : key}
	// 			/>
	// 		))}
	// 	</div>
	// );
};

export default InfoSource;
