import React from 'react';

import { Tooltip, TooltipProps } from 'react-tippy';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat } from 'utils';

type AcuteHungerIndicatorProps = {
	countries: number;
	value: number;
};

const AcuteHungerIndicator = ({
	value,
	countries,
}: AcuteHungerIndicatorProps) => {
	const valueHuman = getHumanNumberFormat(value);
	const countriesCount = getHumanNumberFormat(countries);

	return (
		<Tooltip
			{...(tooltipStyle as TooltipProps)}
			className="wfp-tooltip wfp-tooltip-v2"
			interactive
			trigger="click"
			position="bottom"
			html={
				<>
					<div className="breakdown-label">Acute Hunger (GORP)</div>
					<div className="breakdown-list">
						<div className="breakdown-list__item">
							<div>
								<span>{countries ? countriesCount : 'N/A'} countries</span>
							</div>
							<div>{value ? valueHuman : 'N/A'}</div>
						</div>
					</div>
				</>
			}
		>
			<div className="population-item-row">
				<span className="population-item-row__label">Acute Hunger (GORP)</span>
				<span className="population-item-row__value breakdown">
					{value ? valueHuman : 'N/A'}
				</span>
			</div>
		</Tooltip>
	);
};

export default AcuteHungerIndicator;
