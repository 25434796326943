import React from 'react';

import { Legend } from 'components/atoms';
import { DoughnutChart } from 'components/molecules';

import { useWindowSize } from 'hooks';

import colors from 'constants/colors';

import { getElementByKey, getHumanNumberFormat, getPercents } from 'utils';

const TopCountriesChart = ({ data = {} }) => {
	const [width] = useWindowSize();
	const projectedRequirements = getElementByKey(
		data,
		'projected_operational_requirements',
		{},
	);
	const { other, top_countries: topCountries, total } = projectedRequirements;

	const chartData = [
		{
			y: topCountries,
			x: 'Top 10 countries',
		},
		{
			y: other,
			x: 'Other countries',
		},
	];

	const lines = ['top', 'other'];
	const labelMap = {
		top: {
			label: chartData[0].x,
			percents:
				chartData[0].y <= 0 ? 'N/A' : `${getPercents(chartData[0].y, total)}%`,
			value: `${getHumanNumberFormat(chartData[0].y)}`,
		},
		other: {
			label: chartData[1].x,
			percents:
				chartData[1].y <= 0 ? 'N/A' : `${getPercents(chartData[1].y, total)}%`,
			value: `${getHumanNumberFormat(chartData[1].y)}`,
		},
	};

	const renderLegendLabel = ({ label, percents, value }) => (
		<div className="legend__label">
			<span className="bold">{label}</span>
			<div className="value-container">
				<div className="bold percents">{percents} </div>
				<div className="value">{value}</div>
			</div>
		</div>
	);

	return (
		<>
			<div className="title">
				Top 10 vs other countries by projected operational requirements
				<div className="title__subtitle">In USD</div>
			</div>

			<div className="doughnut-chart__container">
				<DoughnutChart
					data={chartData}
					bottomTextLines={['PROJECTED', 'OPERATIONAL', 'REQUIREMENTS']}
					colorScale={colors.doughnutChartPalette}
					withLabel={false}
					isLabelCentered={false}
					width={width < 370 ? 230 : 270}
					height={width < 370 ? 230 : 270}
					innerRadius={width < 370 ? 55 : 65}
					valueType="usd"
					animate={null}
				/>
			</div>
			<Legend
				labelRender={renderLegendLabel}
				labelMap={labelMap}
				lines={lines}
				separated
				colorScale={colors.doughnutChartPalette}
			/>
		</>
	);
};

export default TopCountriesChart;
