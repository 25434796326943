/*

$black:               #000000;
$white:               #ffffff;
$red:                 #c5192d; //desaturate(darken(#e6494a, 5%), 15%);
$dark-red:            #a21942;
$magenta:             #dd1367;
$orange:              #fd6925; //#ffc759
$ochre:               #dda63a;
$yellow:              #fcc30b; //#fcdc5d
$green:               #3f7e44; //#00a878
$lime:                #97d700;
$navy:                #19486a;
$aqua:                #26bde2;
*/

/* Basic Colors from WFP UI */
const colors = {
	/* Map Colors */
	mapFillDarker: '#031430',
	mapFillDark: '#002054',
	mapFillMiddle: '#245CFF',
	mapFillLight: '#559CFF',
	mapFillLighter: '#8AC2FF',
	mapFillContrastA: '#0A48A0',
	mapFillContrastB: '#DBC108',
	mapFillContrastC: '#319B3B',
	primary: '#0a6eb4', // #2A93FC
	accent: '#1f6ebc',
	blueDarker: '#037cf5',
	blueLighter: '#b2d8fe',

	/* Brand Colors */
	navy: '#19486a',
	aqua: '#26bde2',
	darkGreen: '#3f7e44',
	lime: '#97d700',
	yellow: '#fcc30b',
	ochre: '#dda63a',
	orange: '#fd6925',
	red: '#c5192d',
	darkRed: '#a21942',
	magenta: '#dd1367',
	green: '#00a878',
	greenLighter: '#95DA38',
	purple: '#FC00A4',
	blueIcon: '#007dbc',

	/* Greys */
	greyLighter: '#e8e8e8',
	greyLight: '#bababa',
	grey: '#8c8c8c',
	greyDark: '#5e5e5e',
	greyDarker: '#303030',

	female: '#70E779',
	male: '#5B96ED',

	/* Charts */
	chartGreen: '#A8C97E',

	/* Charts v2 */
	horizon: '#4997AE',
	catskillWhite: '#D6DDED',
	aquaIsland: '#A5CADE',
	lightBlue: '#9DEAFF',
	blue: '#4AA1E2',
	tooltipBg: '#031C2D',
	barGrey: '#5A6872',

	doughnutChartPalette: [
		'#003F76',
		'#4AA1E2',
		'#9DEAFF',
		'#c8eef7',
		'#D6DDED',
		'#007dbc',
		'#19486a',
	],
	multipleBarsPalette: ['#0780C0', '#73C3FE'],
	progressDoughnutChartPalette: ['#4997AE', '#D6DDED'],

	arrowUp: '#8ad220',
	arrowDown: '#c5192d',

	bBarMore: '#368196',
	bBarMore2: '#7DC3D9',
	bBarLess: '#B35F68',
	bBarGrey: 'rgba(234, 234, 234, 0.8)',
	bBarGrey2: 'rgba(211, 211, 211, 0.8)',
	bBarDGrey: '#979797',
};

export default colors;
