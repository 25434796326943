import React from 'react';

import { Tooltip } from 'react-tippy';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat } from 'utils';

const ChronicHungerIndicator = ({ data }) => {
	const { wfp, sofi } = data || {};

	const wfpHuman = getHumanNumberFormat(wfp?.value);
	const sofiHuman = getHumanNumberFormat(sofi || 'N/A');

	const wfpCountries = getHumanNumberFormat(wfp?.countries);

	return (
		<Tooltip
			{...tooltipStyle}
			className="wfp-tooltip wfp-tooltip-v2"
			interactive
			trigger="click"
			position="bottom"
			html={
				<>
					<div className="breakdown-label">Chronic Hunger</div>
					<div className="breakdown-list">
						<div className="breakdown-list__item">
							<div>
								WFP<span>({wfpCountries} countries)</span>
							</div>
							<div>{wfp ? wfpHuman : 'N/A'}</div>
						</div>
						<div className="breakdown-list__item">
							<div>SOFI</div>
							<div>{sofi ? sofiHuman : 'N/A'}</div>
						</div>
					</div>
				</>
			}
		>
			<div className="population-item-row">
				<span className="population-item-row__label">
					Chronic Hunger (SOFI)
				</span>
				<span className="population-item-row__value breakdown">
					{sofi ? sofiHuman : 'N/A'}
				</span>
			</div>
		</Tooltip>
	);
};

export default ChronicHungerIndicator;
