import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFocusArea } from 'api';

import { InfoSource, NoData } from 'components/atoms';
import { ChartIndicatorsPanel } from 'components/templates';

import { selectCurrentYear } from 'features/global/selectors';

import { getElementByKey } from 'utils';

import FocusAreasCharts from './FocusAreasCharts';

export const PANEL_KEY = 'focus-areas';

const FocusAreasPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useFocusArea(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const year = useSelector(selectCurrentYear);

	const isDataAvailable = useMemo(() => {
		if (!data) {
			return false;
		}

		const {
			management = [],
			needs_based_plan: needsBasedPlan = [],
			original_implementation_plan: originalImplementationPlan = [],
		} = data;

		if (
			!management.length &&
			!needsBasedPlan.length &&
			!originalImplementationPlan.length
		) {
			return false;
		}

		return true;
	}, [data]);

	const infoData = getElementByKey(data, 'info');

	return (
		<ChartIndicatorsPanel
			title={year ? `Focus areas (${year})` : 'Focus areas'}
			panelKey={PANEL_KEY}
			topIndicator={null}
			className="focus-areas"
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			loading={isLoading}
			contentOnlyChildren
		>
			{isDataAvailable && (
				<>
					<div className="focus-areas__disclaimer">
						In USD and excludes DSC and ISC
					</div>
					<FocusAreasCharts data={data} />
				</>
			)}
			{!isDataAvailable && !isLoading && <NoData />}
		</ChartIndicatorsPanel>
	);
};

export default FocusAreasPanel;
