import React from 'react';

import classnames from 'classnames';

import { Tooltip } from 'react-tippy';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat } from 'utils';

const Card = ({
	title,
	description,
	data = [],
	areValuesNumber,
	image,
	activeIndexes = [],
}) => (
	<div className="card__wrap">
		<div className="card">
			<div
				className={`card__title-container ${
					!description ? 'card__title-container-disabled' : ''
				}`}
			>
				<Tooltip
					{...tooltipStyle}
					theme="light"
					position="top"
					interactive
					disabled={!description}
					trigger="click"
					html={
						<div
							className="wfp-tooltip__markup"
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					}
					className={`wfp-tooltip wfp-tooltip-card wfp-tooltip-v2 ${
						!description ? 'wfp-tooltip-disabled' : ''
					}`}
					title=" "
				>
					<p className="card__title">{title}</p>
					<img src={image} className="card__title-image" alt={title} />
				</Tooltip>
			</div>
			<div className="card__body">
				{data.map(({ label, value }, index) => (
					<div
						className={classnames('card__row', {
							active: activeIndexes.includes(index),
						})}
						key={`card-row-${label}-${title}`}
					>
						<span className="card__row-label">{label}</span>
						<span className="card__row-value">
							{areValuesNumber ? getHumanNumberFormat(value || '-') : value}
						</span>
					</div>
				))}
			</div>
		</div>
	</div>
);

export default Card;
