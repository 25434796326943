import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ReactGA from 'react-ga4';

import { selectUserData } from 'features/global/selectors';

const GoogleAnalytics = () => {
	const location = useLocation();

	const userData = useSelector(selectUserData);
	const gaID = process.env.INFO_APP_GA_ID;

	useEffect(() => {
		if (gaID && userData && userData.id) {
			// initialize GA
			ReactGA.initialize(gaID);
			ReactGA.set({ userId: userData.id });
		}
	}, [userData]);

	useEffect(() => {
		if (gaID && userData && userData.id) {
			const fullPath = location.pathname + location.search;
			ReactGA.send({ hitType: 'pageview', page: fullPath });
		}
	}, [location, userData]);

	return null;
};

export default GoogleAnalytics;
