import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import { Checkbox, Modal } from '@wfp/ui';

import { useSiteMessages } from 'api';

const SiteMessage = ({ data }) => {
	const [isModalOpened, setModalOpened] = useState(false);
	const [cookies, setCookies, removeCookies] = useCookies();
	let expiresOptions = {};
	const oneDay = 86400000;

	if (data.discard_days) {
		const current = new Date();
		expiresOptions = new Date(current.getTime() + data.discard_days * oneDay);
	}

	const onCheckboxChange = (value) => {
		if (value) {
			setCookies(data.msg_key, true, { expires: expiresOptions });
		} else {
			removeCookies(data.msg_key);
		}
	};

	useState(() => {
		setModalOpened(!cookies[data.msg_key]);
	}, []);

	const labelText = `Don't show this message again for 
  ${data.discard_days} ${data.discard_days === 1 ? 'day' : 'days'}`;

	return (
		<Modal
			open={isModalOpened}
			modalHeading={data.title}
			primaryButtonText="Ok, got it"
			onRequestSubmit={() => setModalOpened(false)}
			onRequestClose={() => setModalOpened(false)}
		>
			<p dangerouslySetInnerHTML={{ __html: data.message }} />
			{data.discard_days && (
				<Checkbox
					id="check-1"
					labelText={labelText}
					onChange={onCheckboxChange}
				/>
			)}
		</Modal>
	);
};

const EnvironmentIndicator = ({ type = process.env.INFO_APP_HOST_ENV }) => {
	const { data, isLoading } = useSiteMessages();

	const labelByType = {
		local: 'local',
		alpha: 'dev',
		qa: 'qa',
	};

	if (isLoading || !type) {
		return null;
	}

	return (
		<>
			{data &&
				data.map((message) => (
					<SiteMessage key={message.msg_key} data={message} />
				))}
			{type !== 'production' && (
				<div className="environment-indicator">{labelByType[type]}</div>
			)}
		</>
	);
};

export default EnvironmentIndicator;
