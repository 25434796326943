function numberWithCommas(data, toFixed = 0) {
	if ([null, undefined].includes(data)) return '';
	try {
		const value = data.toFixed(toFixed).toString();
		return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	} catch (e) {
		console.warn(e);
		return data;
	}
}

export default numberWithCommas;
