import React from 'react';
import PropTypes from 'prop-types';

import { useStrategicOutcomeCategories } from 'api';

import { InfoSource } from 'components/atoms';
import { HorizontalBarChart } from 'components/molecules';
import { ChartIndicatorsPanel } from 'components/templates';

import { getElementByKey } from 'utils';

const GroupedSwitcherIndicators = ({ data }) => (
	<>
		<div className="title__subtitle">In USD and excludes DSC and ISC</div>
		<HorizontalBarChart maxRowAmmount={2} data={data} />
	</>
);

export const PANEL_KEY = 'strategic-outcomes';

const SOCategoriesPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useStrategicOutcomeCategories(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const strategicRes = getElementByKey(data, 'data', []);
	const infoData = getElementByKey(data, 'info');
	const year = getElementByKey(data, 'year');
	return (
		<ChartIndicatorsPanel
			title={year ? `Strategic outcomes (${year})` : 'Strategic outcomes'}
			panelKey={PANEL_KEY}
			className="strategic-outcome-categories-panel"
			indicatorsComponent={<GroupedSwitcherIndicators data={strategicRes} />}
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			loading={isLoading}
			noData={!data || !data.data || !data.data.length}
			indicatorsOnly
		/>
	);
};

SOCategoriesPanel.propTypes = {
	countryCode: PropTypes.string,
	regionCode: PropTypes.string,
};

SOCategoriesPanel.defaultProps = {
	countryCode: null,
	regionCode: null,
};

export default SOCategoriesPanel;
