import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { NoData, LoadingBar } from 'components/atoms';

import { useScrollToPanel, useContentRefLayout } from 'hooks';

import Panel from './Panel';

const CenterComponent = ({ children, className }) => {
	if (children) return <div className={className}>{children}</div>;

	return <></>;
};

const PanelFullContent = ({
	topIndicator,
	withCenterClass,
	className,
	horizontal,
	disclaimer,
	chart,
	chartOnly,
	indicatorsComponent,
}) => {
	let centerChartClassName = '';
	if (!topIndicator && withCenterClass) centerChartClassName = 'center';

	let indicatorInnerClassName = '';
	if (className) {
		indicatorInnerClassName = `${className}__inner`;
	}

	let bottomIndicatorClassName = 'chart-indicator-panel__bottom';
	if (horizontal) {
		bottomIndicatorClassName += ' chart-indicator-panel__bottom-horizontal';
	}

	return (
		<div
			className={classNames({
				'chart-indicator-panel': true,
				[className]: !!className,
				'chart-indicator-panel-horizontal': horizontal,
			})}
			data-testid="panelFullContent_component"
		>
			<div
				className={classNames({
					[indicatorInnerClassName]: !!indicatorInnerClassName,
				})}
			>
				{!!disclaimer && (
					<div
						className={classNames({
							'chart-indicator-panel__disclaimer': true,
						})}
					>
						{disclaimer}
					</div>
				)}
				<div className="chart-indicator-panel__top">
					<CenterComponent className="indicator">
						{topIndicator}
					</CenterComponent>
					<CenterComponent className={`chart ${centerChartClassName}`}>
						{chart}
					</CenterComponent>
				</div>
			</div>
			{!chartOnly && (
				<div className={bottomIndicatorClassName}>{indicatorsComponent}</div>
			)}
		</div>
	);
};

const PanelIndicatorsContent = ({ className, indicatorsComponent }) => (
	<div className={`indicator-panel ${className}`}>
		<div className="indicator-panel__bottom">{indicatorsComponent}</div>
	</div>
);

export const InfoHoverText = () => <div className="info-link">info</div>;

const ChartIndicatorsPanel = (props) => {
	const {
		panelKey,
		loading,
		noData,
		indicatorsOnly,
		title,
		infoText,
		titleSwitcher,
		infoTitle,
		horizontal,
		errorBannerText,
		allowFullExcelExport,
		className,
		contentOnlyChildren,
		children,
	} = props;

	const ref = useRef(null);
	const contentRef = useRef(null);

	useScrollToPanel(panelKey, ref, loading);

	let content;
	if (loading) content = <LoadingBar isSmall={false} />;
	else if (noData) content = <NoData />;
	else if (indicatorsOnly) content = <PanelIndicatorsContent {...props} />;
	else content = <PanelFullContent {...props} />;

	const innerClassName = loading ? ' chart-indicator-panel-empty' : '';

	const { html, pdfEnabled } = useContentRefLayout({
		contentRef,
		loading,
		title,
		infoText,
	});

	return (
		<div ref={contentRef} data-testid="chart-indicators-panel">
			<Panel
				title={title}
				titleSwitcher={titleSwitcher}
				hoverText={<InfoHoverText />}
				componentHTML={html}
				infoText={infoText}
				infoTitle={infoTitle}
				theme="light"
				enableFooter
				loading={loading}
				horizontal={horizontal}
				panelKey={panelKey}
				errorBannerText={errorBannerText}
				allowFullExcelExport={allowFullExcelExport}
				enablePDF={pdfEnabled}
				className={className}
			>
				<div ref={ref} className={innerClassName}>
					{contentOnlyChildren ? children : content}
					{contentOnlyChildren && loading && content}
				</div>
			</Panel>
		</div>
	);
};

ChartIndicatorsPanel.propTypes = {
	title: PropTypes.string,
	topIndicator: PropTypes.any,
	chart: PropTypes.any,
	className: PropTypes.string,
	indicatorsTitle: PropTypes.string,
	indicatorsSubTitle: PropTypes.string,
	indicatorsData: PropTypes.array,
	indicatorsBaseValue: PropTypes.number,
	loading: PropTypes.bool,
	indicatorsComponent: PropTypes.object,
	noData: PropTypes.bool,
	disclaimer: PropTypes.string,
	panelKey: PropTypes.string,
	bottomDisclaimer: PropTypes.string,
	titleSwitcher: PropTypes.object,
	indicatorsOnly: PropTypes.bool,
	chartOnly: PropTypes.bool,
	contentOnlyChildren: PropTypes.bool,
	errorBannerText: PropTypes.string,
	allowFullExcelExport: PropTypes.bool,
	horizontal: PropTypes.bool,
};

ChartIndicatorsPanel.defaultProps = {
	title: '',
	topIndicator: null,
	chart: null,
	className: '',
	indicatorsTitle: null,
	indicatorsSubTitle: null,
	allowFullExcelExport: true,
	indicatorsData: [],
	indicatorsBaseValue: 0,
	loading: false,
	indicatorsComponent: null,
	noData: true,
	disclaimer: null,
	bottomDisclaimer: null,
	titleSwitcher: null,
	indicatorsOnly: false,
	chartOnly: false,
	withCenterClass: true,
};

export default ChartIndicatorsPanel;
