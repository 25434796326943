import React from 'react';

import { Link } from '@wfp/ui';

const Links = () => (
	<div className="footer-links">
		<h3>Related platforms</h3>
		<ul className="footer-links__list">
			<li>
				<Link
					href="https://analytics.wfp.org/#/views/APRdashboard2022/APR2022"
					target="_blank"
					rel="noopener noreferrer"
				>
					APR Dashboard
				</Link>
			</li>
			<li>
				<Link
					href="https://cspdata.wfp.org"
					target="_blank"
					rel="noopener noreferrer"
				>
					CSP Data Portal
				</Link>
			</li>
			<li>
				<Link
					href="https://dots.wfp.org"
					target="_blank"
					rel="noopener noreferrer"
				>
					DOTS
				</Link>
			</li>
			<li>
				<Link
					href="https://factory.wfp.org"
					target="_blank"
					rel="noopener noreferrer"
				>
					FACTory
				</Link>
			</li>
			<li>
				<Link
					href="https://hungermap.wfp.org"
					target="_blank"
					rel="noopener noreferrer"
				>
					HungerMap
					<sup style={{ fontSize: '70%' }}>LIVE</sup>
				</Link>
			</li>
			<li>
				<Link
					href="https://newgo.wfp.org/rm.analytics"
					target="_blank"
					rel="noopener noreferrer"
				>
					RM Analytics Platform
				</Link>
			</li>
			<li>
				<Link
					href="https://dataviz.vam.wfp.org"
					target="_blank"
					rel="noopener noreferrer"
				>
					VAM DataViz
				</Link>
			</li>
		</ul>
	</div>
);

export default Links;
