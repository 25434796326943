import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getHeader = async () => api.get(appConfig.API_V2_URLS.HEADER);

export const useHeader = (options) =>
	useQuery({
		queryFn: () => getHeader(),
		...options,
	});
