import React, { useRef } from 'react';

import { useWindowSize } from 'hooks';

const PieChartTooltip = ({ position, label, value }) => {
	const [windowWidth] = useWindowSize();
	const ref = useRef();
	let width = 0;

	if (label) width = (label.length + value.length) * 6 + 14;

	const styles = {
		zIndex: 1,
		padding: '7px 10px',
		minWidth: width,
		top: position.top,
	};

	const left = position.left - width / 2;

	if (left + width + 60 > windowWidth) {
		styles.right = 0;
	} else if (left < 0) {
		styles.left = 0;
	} else {
		styles.left = left;
	}

	return (
		<>
			<div style={styles} ref={ref} className="pie-chart-tooltip">
				<span className="pie-chart-tooltip__label">{label}:</span>
				<span className="pie-chart-tooltip__value">{value}</span>
			</div>
			<div
				style={{ left: position.left, top: styles.top + 30 }}
				className="pie-chart-tooltip__caret"
			/>
		</>
	);
};

export default PieChartTooltip;
