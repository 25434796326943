import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const OverviewRedirect = () => {
	const { regionCode, countryCode } = useParams();

	let pathname = '/overview';

	if (countryCode) {
		pathname = `/countries/${countryCode}/overview`;
	}

	if (regionCode) {
		pathname = `/regions/${regionCode}/overview`;
	}

	return <Navigate to={pathname} />;
};

export default OverviewRedirect;
