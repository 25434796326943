import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Tooltip } from 'react-tippy';

import { LoadingBar } from 'components/atoms';

import tooltipStyle from 'constants/tooltipStyle';

import { selectIsAdvanced } from 'features/global/selectors';

const ExportLinks = ({
	excelFullEnabled = true,
	excelRawDataEnabled = true,
	pdfEnabled = true,
	excelClickHandler,
	pdfClickHandler,
	level = '',
	withRetry = true,
}) => {
	const [loadingState, setLoadingState] = useState({});
	const [isTooltipVisible, setTooltipVisible] = useState(false);
	const isAdvancedMode = useSelector(selectIsAdvanced);

	const withRetryIfFailed = (func, key) => {
		let counter = 0;
		const callback = async () => {
			setLoadingState((prev) => ({ ...prev, [key]: true }));
			const state = await func();
			if (state === 'retry') {
				counter += 1;
				if (counter < 20) {
					setTimeout(() => {
						callback();
					}, 3000);
				} else {
					setLoadingState((prev) => ({ ...prev, [key]: false }));
				}
			} else {
				setLoadingState((prev) => ({ ...prev, [key]: false }));
			}
		};

		return () => {
			setTooltipVisible(false);
			callback();
		};
	};

	return (
		<div className="xlsx-export">
			<span className="xlsx-export__title">Export to: </span>
			<Tooltip
				{...tooltipStyle}
				theme="light"
				trigger="click"
				position="bottom"
				open={isTooltipVisible}
				onRequestClose={() => setTooltipVisible(false)}
				interactive
				html={
					<>
						<div className="xlsx-export__subitem">
							<button
								data-testid="excel-general-button"
								type="button"
								disabled={loadingState.excel}
								className="xlsx-export__subitem__value"
								onClick={withRetryIfFailed(
									() =>
										excelClickHandler('single', 'xlsx', null, isAdvancedMode),
									'excel',
								)}
							>
								{level[0].toUpperCase()}
								{level.slice(1)} only
							</button>
						</div>
						{excelFullEnabled && level !== 'country' && (
							<div className="xlsx-export__subitem">
								<button
									data-testid="excel-full-button"
									type="button"
									disabled={loadingState['excel-full']}
									className="xlsx-export__subitem__value"
									onClick={withRetryIfFailed(
										() =>
											excelClickHandler('full', 'xlsx', null, isAdvancedMode),
										'excel-full',
									)}
								>
									All countries
								</button>
							</div>
						)}
						{excelRawDataEnabled && (
							<div className="xlsx-export__subitem">
								<button
									data-testid="excel-rawdata-button"
									type="button"
									disabled={loadingState.raw}
									className="xlsx-export__subitem__value"
									onClick={withRetryIfFailed(
										() =>
											excelClickHandler('raw', 'xlsx', null, isAdvancedMode),
										'raw',
									)}
								>
									Raw data
								</button>
							</div>
						)}
					</>
				}
			>
				<div className="xlsx-export__item">
					<button
						data-testid="excel-button"
						type="button"
						disabled={
							loadingState.excel ||
							loadingState['excel-full'] ||
							loadingState.raw
						}
						className="xlsx-export__item__value arrow"
						onClick={() => setTooltipVisible(!isTooltipVisible)}
					>
						<span>Excel</span>
					</button>
					<LoadingBar
						isLoading={
							!!loadingState.excel ||
							!!loadingState['excel-full'] ||
							!!loadingState.raw
						}
						text=""
					/>
				</div>
			</Tooltip>
			{pdfEnabled && (
				<div className="xlsx-export__item">
					<button
						data-testid="pdf-button"
						type="button"
						disabled={loadingState.pdf}
						className="xlsx-export__item__value"
						onClick={
							withRetry
								? withRetryIfFailed(pdfClickHandler, 'pdf')
								: pdfClickHandler
						}
					>
						<span>PDF</span>
					</button>
					<LoadingBar isLoading={loadingState.pdf || false} text="" />
				</div>
			)}
		</div>
	);
};

ExportLinks.propTypes = {
	excelEnabled: PropTypes.bool,
	excelClickHandler: PropTypes.func,
	withRetry: PropTypes.bool,
};

export default ExportLinks;
