import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'react-tippy';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat, getHumanNumberWithSymbol } from 'utils';

const HorizontalIndicator = ({
	value: valueProp,
	disclaimerText,
	isBigNumber,
	currency,
	subLabel,
	label,
	extraIndicators,
}) => {
	const [value, symbol] = getHumanNumberWithSymbol(valueProp);

	const DisclaimerContainer = disclaimerText
		? ({ children, title }) => (
				<Tooltip
					{...tooltipStyle}
					theme="light"
					position="top"
					style={{ height: 30 }}
					className="wfp-tooltip wfp-tooltip-v2"
					title={title}
				>
					{children}
					<span className="disclaimer-icon">*</span>
				</Tooltip>
		  )
		: ({ children }) => <>{children}</>;

	return (
		<div className="horizontal-indicator">
			<div className="horizontal-indicator__main-content">
				<div className="horizontal-indicator__values">
					<div className="currency">{currency}</div>
					<div className="value">
						<DisclaimerContainer title={disclaimerText}>
							<span>{value}</span>
							<span className="symbol">{symbol}</span>
						</DisclaimerContainer>
					</div>
				</div>
				<div className="horizontal-indicator__labels">
					{subLabel ? (
						<div className={`sub-label${isBigNumber ? '-big' : ''}`}>
							{subLabel}
						</div>
					) : null}
					<div className="label">{label}</div>
				</div>
			</div>
			{!!extraIndicators && (
				<div className="horizontal-indicator__extra-content">
					{extraIndicators.map((item) => (
						<div className="item" key={item.label}>
							<div className="value">{getHumanNumberFormat(item.value)}</div>
							<div className="label">{item.label}</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

HorizontalIndicator.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	currency: PropTypes.string,
	disclaimerText: PropTypes.string,
};

HorizontalIndicator.defaultProps = {
	value: null,
	label: null,
	subLabel: null,
	currency: 'USD',
	disclaimerText: null,
};

export default HorizontalIndicator;
