import React from 'react';

import { HorizontalBarChart } from 'components/molecules';

const GroupedSwitcherIndicators = ({ data }) => (
	<>
		<div className="title__subtitle">In USD and excludes ISC</div>
		<HorizontalBarChart data={data} />
	</>
);

export default GroupedSwitcherIndicators;
