import { useParams } from 'react-router-dom';

const useOfficeLevel = () => {
	const { regionCode, countryCode } = useParams();
	let level = 'global';

	if (regionCode) {
		level = 'region';
	}
	if (countryCode) {
		level = 'country';
	}

	return level;
};

export default useOfficeLevel;
