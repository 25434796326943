import React from 'react';
import { useSelector } from 'react-redux';

import SU from 'assets/emergencies/CSU-large.svg';
import CA from 'assets/emergencies/CA-large.svg';
import AR from 'assets/emergencies/EAER-large.svg';

import { selectEmergencies } from 'features/office/selectors';

const Tag = ({ data }) => {
	const opWebId = data.opweb_id;
	const opWebIdUrl = `http://opweb.wfp.org/emergencies/${opWebId}`;
	const level = data.level.replace('-', '').toLowerCase();
	const extraClass = `crisis-level ${level}`;
	const displayedEmergenciesLabels = ['l1', 'l2', 'l3', 'm'];
	const displayedEmergenciesImages = {
		su: SU,
		ca: CA,
		ar: AR,
	};

	return (
		<a
			className={extraClass}
			href={opWebIdUrl}
			target="_blank"
			rel="noreferrer"
		>
			{displayedEmergenciesLabels.includes(level) ? (
				level
			) : (
				<img
					src={window.location.origin + displayedEmergenciesImages[level]}
					alt={level}
				/>
			)}
		</a>
	);
};

const EmergencyTags = ({ className }) => {
	const emergency = useSelector(selectEmergencies);

	const items = emergency.map((item) => {
		const key = `${item.opweb_id}-${item.level}`;
		return <Tag data={item} key={key} />;
	});

	return (
		<div
			className={`emergency-tags ${className || ''}`}
			data-testid="EmergencyTags_component"
		>
			{items}
		</div>
	);
};

export default EmergencyTags;
