import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useResources } from 'api';

import { NoData, InfoSource, PieChartTooltip, Legend } from 'components/atoms';
import { DoughnutChart } from 'components/molecules';
import { ChartIndicatorsPanel } from 'components/templates';

import colors from 'constants/colors';

import { getElementByKey, getHumanNumberFormat, getPercents } from 'utils';

import ResourcingOutlookIndicators from './ResourcingOutlookIndicators';

const ResourcesPieChart = (props) => {
	const { data } = props;

	const funding = getElementByKey(data, 'funding');
	const totalValue = funding ? funding.total : null;
	const resourcedValue = funding ? funding.resourced : null;
	const netFundingRequirements = funding
		? funding.net_funding_requirements
		: null;

	const period = funding ? funding.period : null;

	if (!totalValue && totalValue !== 0) return <NoData />;

	return (
		<div className="chart__container">
			<div className="chart__title">
				6-month
				{period && period.from && period.to
					? ` (${period.from} - ${period.to})`
					: null}
			</div>
			<div className="chart-indicator-panel__disclaimer">In USD</div>
			<div className="chart-wrap">
				<DoughnutChart
					bottomTextLines={['TOTAL', 'REQUIREMENTS']}
					total={totalValue}
					labelComponent={<PieChartTooltip {...props} />}
					withLabel={false}
					width={240}
					height={270}
					innerRadius={65}
					data={[
						{ y: resourcedValue, value: resourcedValue, x: 'Resourced' },
						{
							y: netFundingRequirements,
							value: netFundingRequirements,
							x: 'Net funding requirements',
						},
					]}
				/>
				<div className="legend-wrap">
					<Legend
						colorScale={colors.doughnutChartPalette}
						lines={['resourced', 'shortfall']}
						separated
						labelMap={{
							resourced: {
								label: 'Resourced',
								percents: `${getPercents(resourcedValue, totalValue)}%`,
								value: getHumanNumberFormat(resourcedValue),
							},
							shortfall: {
								label: 'Net funding requirements',
								percents: `${getPercents(netFundingRequirements, totalValue)}%`,
								value: getHumanNumberFormat(netFundingRequirements),
							},
						}}
						orientation="vertical"
					/>
				</div>
			</div>
		</div>
	);
};

export const PANEL_KEY = 'resourcing-outlook';

const ResourcesPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useResources(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const level = useMemo(() => {
		let temp = 'global';
		if (countryCode) {
			temp = 'country';
		} else if (regionCode) {
			temp = 'region';
		}
		return temp;
	}, [countryCode, regionCode]);

	const year = getElementByKey(data, 'year');
	const infoData = getElementByKey(data, 'info');

	return (
		<ChartIndicatorsPanel
			title={year ? `Resourcing outlook (${year})` : 'Resourcing outlook'}
			topIndicator={null}
			panelKey={PANEL_KEY}
			className="resources-panel"
			chart={<ResourcesPieChart data={data} level={level} />}
			indicatorsComponent={
				<ResourcingOutlookIndicators data={data} level={level} />
			}
			horizontal
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			loading={isLoading}
			noData={!data}
		/>
	);
};

ResourcesPanel.propTypes = {
	countryCode: PropTypes.string,
	regionCode: PropTypes.string,
	panelClassName: PropTypes.string,
};

ResourcesPanel.defaultProps = {
	countryCode: null,
	regionCode: null,
};

export default ResourcesPanel;
