import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';

import { selectMenu } from 'features/global/selectors';

import { getCountryCode, getRegionCode } from 'utils';

const RouteChecker = () => {
	const { countries: countriesList, regions: regionsList } =
		useSelector(selectMenu);

	const navigate = useNavigate();
	const location = useLocation();
	const countryCode = getCountryCode();
	const regionCode = getRegionCode();

	const isMobile = useMatch('/mobile');
	const isErrorPage = useMatch('/error-page');
	const isOperationGlobal = useMatch({ path: '/operation' });
	const isOperationRegion = useMatch('/regions/:regionCode/operation');
	const isOperationCountry = useMatch('/countries/:countryCode/operation');
	const operationRoutesResults = [
		isOperationGlobal,
		isOperationRegion,
		isOperationCountry,
	];

	const isOverviewGlobal = useMatch({ path: '/overview', exact: true });
	const isOverviewRegion = useMatch('/regions/:regionCode/overview');
	const isOverviewCountry = useMatch('/countries/:countryCode/overview');
	const overviewRoutesResults = [
		isOverviewGlobal,
		isOverviewRegion,
		isOverviewCountry,
	];

	const isResourcesGlobal = useMatch({ path: '/resources' });
	const isResourcesRegion = useMatch('/regions/:regionCode/resources');
	const isResourcesCountry = useMatch('/countries/:countryCode/resources');
	const resourcesRoutesResults = [
		isResourcesGlobal,
		isResourcesRegion,
		isResourcesCountry,
	];

	const isPlanGlobal = useMatch({ path: '/planvsactuals' });
	const isPlanRegion = useMatch('/regions/:regionCode/planvsactuals');
	const isPlanCountry = useMatch('/countries/:countryCode/planvsactuals');
	const planRoutesResults = [isPlanGlobal, isPlanRegion, isPlanCountry];

	const isHome = ['/', '/overview', '/planvsactuals', '/resources'].includes(
		location.pathname,
	);

	const isWrong = [
		...operationRoutesResults,
		...overviewRoutesResults,
		...resourcesRoutesResults,
		...planRoutesResults,
		isHome,
		isMobile,
		isErrorPage,
	].every((condition) => !condition);

	useEffect(() => {
		if (!countriesList.length || !regionsList.length) {
			return;
		}

		let isError = false;

		if (regionCode) {
			isError = !regionsList.find(({ code }) => code === regionCode);
		}

		if (countryCode) {
			isError = !countriesList.find(({ code }) => code === countryCode);
		}

		if (isWrong || (!regionCode && !countryCode && !isHome && !isMobile)) {
			isError = true;
		}

		if (isError) {
			navigate('/error-page');
		}
	}, [countriesList, regionsList, countryCode, regionCode]);

	return null;
};

export default RouteChecker;
