import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getStrategicResults = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_STRATEGIC_RESULTS;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_STRATEGIC_RESULTS}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_STRATEGIC_RESULTS}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useStrategicResults = ({ countryCode, regionCode }, options) =>
	useQuery({
		queryFn: () => getStrategicResults({ countryCode, regionCode }),
		...options,
	});
