import React, { useMemo } from 'react';

import { DoughnutChart } from 'components/molecules';
import colors from 'constants/colors';
import { getHumanNumberFormat, getPercents, toTitleCase } from 'utils';

const FocusAreasItem = ({ item, title, lines, labelMap }) => {
	const total = useMemo(
		() => item.reduce((acc, curr) => acc + curr.value, 0),
		[item],
	);

	const [itemWithEmptyFields, colorsPalette] = useMemo(() => {
		const temp = [...item];
		const tempColors = {};
		lines.forEach((line, index) => {
			const { label } = labelMap[line];
			const isFieldAvailable = temp.find(
				({ focus_area: focusArea }) =>
					label.toLowerCase() === focusArea.toLowerCase(),
			);
			if (!isFieldAvailable) {
				temp.push({
					focus_area: label,
					value: 0,
				});
			}
			const key = label.toLowerCase().replace(' ', '');
			tempColors[key] = colors.doughnutChartPalette[index];
		});
		const items = temp
			.filter(({ value }) => !!value)
			.sort((a, b) => a.focus_area.localeCompare(b.focus_area));

		const resultColors = [];
		items.forEach((item) =>
			resultColors.push(
				tempColors[item.focus_area.toLowerCase().replace(' ', '')],
			),
		);

		return [items, resultColors];
	}, [item]);

	if (!item || !item.length) {
		return null;
	}

	return (
		<div className="focus-areas-chart__container">
			<div className="focus-areas-chart">
				<div className="chart__title">{title}</div>
				<DoughnutChart
					withLabel={false}
					onlyMainLabel
					tooltipRenderMethod={(datum, total) =>
						`${getPercents(datum.y, total)}% (${getHumanNumberFormat(datum.y)})`
					}
					width={220}
					height={220}
					innerRadius={50}
					colorScale={colorsPalette}
					data={itemWithEmptyFields.map(({ focus_area: focusArea, value }) => ({
						y: value,
						x: toTitleCase(focusArea),
					}))}
				/>
				<div className="desc">
					{item
						.filter(({ value }) => !!value)
						.map(({ focus_area: focusArea, value }) => (
							<div
								className="desc__item"
								key={`focus-area-${focusArea}-${value}-desc`}
							>
								<div className="desc__label">{toTitleCase(focusArea)}</div>
								<div className="desc__values">
									<div className="desc__value">
										{getHumanNumberFormat(value)}
									</div>
									<div className="desc__percents desc__value">
										{getPercents(value, total, 0)}%
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default FocusAreasItem;
