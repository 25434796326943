import React from 'react';
import PropTypes from 'prop-types';

import {
	Search,
	SubNavigation,
	SubNavigationContent,
	SubNavigationFilter,
	SubNavigationGroup,
	SubNavigationHeader,
	SubNavigationItem,
	SubNavigationLink,
	SubNavigationList,
	SubNavigationTitle,
} from '@wfp/ui';

import { InfoIndicator, ExtLink } from 'components/molecules';
import { InfoHoverText } from 'components/templates/ChartIndicatorsPanel';

import { gaOfficeSelectionEvent, gaSearchUsingEvent } from 'anatytics';

import { getSubTab, replaceDiacritic } from 'utils';

// Generate Items for the Glossary
const GlossaryItem = ({ el }) => (
	<div className="menu__glossary wfp-grid">
		<div className="wfp-u-1 wfp-u-md-3-4 menu__glossary__content">
			<h6 className="heading--smallest">{el.name}</h6>
			<span dangerouslySetInnerHTML={{ __html: el.description }} />
		</div>
		<ul className="wfp-u-1 wfp-u-md-1-4 menu__glossary__meta valuelist valuelist--columns">
			{!!el.source && (
				<li>
					<span className="valuelist__title">Source:</span>
					<span dangerouslySetInnerHTML={{ __html: el.source }} />
				</li>
			)}
			{!!el.owner && (
				<li>
					<span className="valuelist__title">Data Owner:</span>
					<span dangerouslySetInnerHTML={{ __html: el.owner }} />
				</li>
			)}
			{!!el.refresh_rate && (
				<li>
					<span className="valuelist__title">Updated:</span>
					<span dangerouslySetInnerHTML={{ __html: el.refresh_rate }} />
				</li>
			)}
		</ul>
	</div>
);

class MenuList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			order: props.defaultOrder,
			search: '',
		};
	}

	/* eslint-disable class-methods-use-this */
	findElements = (parentKey, el) => el.parent === parentKey;
	/* eslint-enable class-methods-use-this */

	getNavigationTitle = () => {
		const { title } = this.props;
		return <SubNavigationTitle>{title}</SubNavigationTitle>;
	};

	getNavigationExtLink = () => {
		const { extLink } = this.props;

		if (extLink)
			return (
				<SubNavigationLink>
					<ExtLink small to={extLink.to}>
						{extLink.name}
					</ExtLink>
				</SubNavigationLink>
			);
		return null;
	};

	getNavigationOrder = () => {
		const { displayOrder, orderText } = this.props;
		const { order } = this.state;

		const onClickAZ = () => this.setState({ order: 'asc' });
		const onClickTopic = () => this.setState({ order: 'topic' });

		if (displayOrder) {
			const classNameAZ =
				order === 'asc' ? 'order__link active' : 'order__link';
			const classNameTopic =
				order === 'topic' ? 'order__link active' : 'order__link';

			return (
				<div className="headingwrap__center order">
					<span className="order__text">Order by:</span>
					<span onClick={onClickAZ} className={classNameAZ}>
						A-Z
					</span>
					<span onClick={onClickTopic} className={classNameTopic}>
						{orderText}
					</span>
				</div>
			);
		}

		return null;
	};

	getNavigationSearch = () => {
		const { title, searchId } = this.props;
		const { search } = this.state;

		return (
			<div className="headingwrap__right search__wrap">
				<Search
					labelText=""
					placeholder={`Search in ${title}...`}
					value={search}
					id={searchId}
					light={false}
					onChange={(value) => {
						this.setState({ search: value });
					}}
					onFocus={() => gaSearchUsingEvent(title)}
				/>
			</div>
		);
	};

	getNavigationFilter = () => (
		<SubNavigationFilter>
			{this.getNavigationOrder()}
			{this.getNavigationSearch()}
		</SubNavigationFilter>
	);

	getNavigationContent = (listChild) => {
		const { title, parentSource } = this.props;
		const { order } = this.state;

		const isAZOrder = order === 'asc' && title !== 'Glossary';

		if (!parentSource || isAZOrder)
			return (
				<SubNavigationGroup>
					<div className="grouped-items">{listChild}</div>
				</SubNavigationGroup>
			);

		return <SubNavigationList>{listChild}</SubNavigationList>;
	};

	/* List Elements */
	render() {
		const {
			location,
			source,
			parentSource,
			title,
			parentType,
			type,
			infoData,
		} = this.props;

		const { search, order } = this.state;
		const { subTab, hash } = getSubTab(location, true);
		/* Sort list by ASC */
		const byAlpha = function (a, b) {
			const aName = a.name.toLowerCase();
			const bName = b.name.toLowerCase();

			if (aName < bName) {
				return -1;
			}

			if (aName > bName) {
				return 1;
			}

			return 0;
		};

		if (!source) return null;

		let sortedItems = [...source].sort(byAlpha);
		let sortedParentItems = [];

		if (parentSource) {
			sortedParentItems = [...parentSource].sort(byAlpha);
		}

		if (search) {
			const searchValue = replaceDiacritic(search.toLowerCase());
			sortedItems = sortedItems.filter((row) =>
				replaceDiacritic(String(row.name.toLowerCase())).includes(searchValue),
			);
		}

		let listChild;
		/* Glossary by Topic */
		if (parentSource && title === 'Glossary') {
			if (order === 'topic') {
				listChild = sortedParentItems.map((parentEl, index) => (
					<div key={index}>
						{sortedItems.find(this.findElements.bind(this, parentEl.key)) !==
							undefined && <h5 className="menu__subtitle">{parentEl.name}</h5>}
						<ul className="menu__list">
							{sortedItems.map((el, index) => {
								if (el.parent === parentEl.key) {
									return <GlossaryItem key={index} el={el} />;
								}
								return null;
							})}
						</ul>
					</div>
				));
			} else {
				/* Glossary List View */
				listChild = sortedItems.map((el, index) => (
					<GlossaryItem key={index} el={el} />
				));
			}
		} else if (parentSource && order === 'topic') {
			/* List with Topic */
			listChild = sortedParentItems.map((parentEl, index) => (
				<div key={index} className="region">
					{sortedItems.find(this.findElements.bind(this, parentEl.code)) !==
						undefined && (
						<h5 className="menu__subtitle region__subtitle">
							<ExtLink
								className="menu__item menu__item--inline"
								favorite
								index={parentEl.code}
								subTab={`${subTab}${hash}`}
								gaEvent={() => gaOfficeSelectionEvent(parentEl.code)}
								type={parentType}
								url={parentEl.url}
								exact
								activeClassName="active"
							>
								{parentEl.name.replace('Office', '')}
							</ExtLink>
						</h5>
					)}
					<SubNavigationGroup>
						{sortedItems.map((el, index) => {
							if (el.parent === parentEl.code) {
								return (
									<SubNavigationItem
										key={index}
										className={el.name.length >= 20 ? 'longname' : ''}
									>
										<ExtLink
											className="menu__item--inline"
											url={el.url}
											type={type}
											favorite
											gaEvent={() => gaOfficeSelectionEvent(el.code)}
											subTab={`${subTab}${hash}`}
											index={el.code}
											exact
											activeClassName="active"
										>
											{el.name}
										</ExtLink>
									</SubNavigationItem>
								);
							}
							return null;
						})}
					</SubNavigationGroup>
				</div>
			));
		} else {
			listChild = sortedItems.map((el, index) => (
				<SubNavigationItem key={index}>
					<ExtLink
						className="menu__item--inline"
						url={el.url}
						type={type}
						favorite
						subTab={subTab}
						index={el.code}
						exact
						activeClassName="active"
					>
						{el.name}
					</ExtLink>
				</SubNavigationItem>
			));
		}

		return (
			<SubNavigation>
				<div className={title !== 'Glossary' ? 'custom-menu' : 'glossary-menu'}>
					<SubNavigationHeader>
						<div className="menu-list__title-container">
							{this.getNavigationTitle()}
							{!!infoData && (
								<InfoIndicator
									isMenu
									hoverText={<InfoHoverText />}
									isHover
									title={infoData.title}
								>
									<div
										dangerouslySetInnerHTML={{
											__html: infoData.text,
										}}
									/>
								</InfoIndicator>
							)}
						</div>
						{this.getNavigationExtLink()}
						{this.getNavigationFilter()}
					</SubNavigationHeader>

					<SubNavigationContent>
						{this.getNavigationContent(listChild)}
					</SubNavigationContent>
				</div>
			</SubNavigation>
		);
	}
}

MenuList.propTypes = {
	needTitle: PropTypes.bool,
	parentType: PropTypes.string,
	defaultOrder: PropTypes.string,
	parentSource: PropTypes.array,
	displayOrder: PropTypes.bool,
	el: PropTypes.object,
	extLink: PropTypes.object,
	orderText: PropTypes.string,
	searchId: PropTypes.string,
	withInfoIndicator: PropTypes.bool,
};

MenuList.defaultProps = {
	needTitle: true,
	displayOrder: false,
	parentType: undefined,
	parentSource: null,
	extLink: null,
	defaultOrder: 'topic',
	orderText: 'order by',
	searchId: 'menu-search-id',
	withInfoIndicator: false,

	el: {
		name: null,
		description: null,
		owner: null,
		source: null,
		refresh_rate: null,
	},
};

export default MenuList;
