import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollOnTabChange = () => {
	const location = useLocation();

	useEffect(() => {
		if (!location.hash) {
			window.scrollTo({ behavior: 'smooth', top: 0 });
		}
	}, [location.pathname]);
};

export default useScrollOnTabChange;
