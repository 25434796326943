import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
	useGlobalHeaderEmployees,
	useGlobalHeaderBeneficiaries,
	useGlobalHeaderPopulation,
} from 'api';

import {
	ExportLinks,
	EmployeesHeaderPanel,
	BeneficiariesHeaderPanel,
	PopulationHeaderPanel,
	HeaderBottomIndicators,
	HeaderInfo,
} from 'components/molecules';

import { useScrollToPanel, useContentRefLayout } from 'hooks';

import { selectCurrentYear } from 'features/global/selectors';

import { exportRequest, getElementByKey } from 'utils';

const HeaderGlobalDashboard = () => {
	const { data: employeesData, isLoading: isEmployeesLoading } =
		useGlobalHeaderEmployees({
			queryKey: ['global-header-employees'],
		});

	const { data: beneficiariesData, isLoading: isBeneficiariesLoading } =
		useGlobalHeaderBeneficiaries({
			queryKey: ['global-header-beneficiaries'],
		});

	const { data: populationData, isLoading: isPopulationLoading } =
		useGlobalHeaderPopulation({
			queryKey: ['global-header-population'],
		});

	const containerRef = useRef(null);
	const year = useSelector(selectCurrentYear);

	const isAllLoading =
		isEmployeesLoading && isBeneficiariesLoading && isPopulationLoading;
	useScrollToPanel('outlook', containerRef, isAllLoading);

	let infoData = getElementByKey(employeesData, 'info');
	if (infoData && infoData.length)
		infoData = infoData.find((obj) => obj.indicator === 'header');

	const { html, pdfEnabled } = useContentRefLayout({
		loading:
			isEmployeesLoading || isBeneficiariesLoading || isPopulationLoading,
		title: `Outlook (${year})`,
		contentRef: containerRef,
		infoText: infoData ? infoData.text : '',
		withMarkupRender: false,
		additionalMarkup: `<h1 class="wfp--module__title">Outlook (${year})</h1>`,
		year,
	});

	return (
		<>
			<div
				className="header-panel-dashboard global"
				ref={containerRef}
				data-testid="header-global-dashboard"
			>
				<div className="header-panel-dashboard__wrap">
					<EmployeesHeaderPanel
						level="global"
						data={employeesData}
						loading={isEmployeesLoading}
					/>
					<BeneficiariesHeaderPanel
						level="global"
						loading={isBeneficiariesLoading}
						data={beneficiariesData}
					/>
					<PopulationHeaderPanel
						level="global"
						loading={isPopulationLoading}
						data={populationData}
					/>
				</div>
				<HeaderBottomIndicators
					data={employeesData}
					loading={isEmployeesLoading}
				/>
			</div>

			<div className="header-footer">
				<ExportLinks
					excelClickHandler={(mode = 'single') =>
						exportRequest('global', 'outlook', mode)
					}
					pdfClickHandler={() =>
						exportRequest('global', 'outlook', 'single', 'pdf', html)
					}
					isExcelDivided
					pdfEnabled={pdfEnabled}
					excelFullEnabled
					level="global"
				/>
				<HeaderInfo data={infoData} />
			</div>
		</>
	);
};

export default HeaderGlobalDashboard;
