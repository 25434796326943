import getPercents from './getPercents';
import getHumanNumberFormat from './getHumanNumberFormat';

const formatDonorsChartData = (donorsData) => {
	const {
		confirmed_contribution: confirmed,
		forecast_contribution: forecast,
		total_confirmed_contribution: totalConfirmed,
		total_forecast_contribution: totalForecasted,
	} = donorsData || {};
	if ((!confirmed || !confirmed.length) && (!forecast || !forecast.length)) {
		return {
			confirmedContribution: {
				data: [], // {values: [{number, label: string, year: number, value: number}], previous: {value: number, label: string, year: number, borderStyle: string}[]}
				maxValue: 0,
				legend: [], // {borderStyle, label}[]
				total: totalConfirmed,
			},
			forecastedContribution: {
				data: [],
				maxValue: 0,
				total: 0,
			},
			year: 0,
		};
	}

	const sortedConfirmed = confirmed.sort((a, b) => b.year - a.year);
	const currentConfirmed = sortedConfirmed[0] || {};
	const borders = ['solid', 'dashed', 'dotted'];
	const totalCurrentConfirmed = totalConfirmed[0] || {};

	const countMaxConfirmedValue = () => {
		let max = 0;
		confirmed.forEach(({ data }) => {
			data.forEach(({ total }) => {
				if (max < total) {
					max = total;
				}
			});
		});
		return max;
	};

	const countMaxForecastedValue = () => {
		let max = 0;
		forecast.forEach(({ total }) => {
			if (max < total) {
				max = total;
			}
		});
		return max;
	};

	const maxConfirmedValue = countMaxConfirmedValue();
	const maxForecastedValue = countMaxForecastedValue();

	const sumOfTotalForecasted = totalForecasted.hight + totalForecasted.medium;

	const chart = {
		confirmedContribution: {
			data: [], // {values: [{number, label: string, year: number, value: number}], previous: {value: number, label: string, year: number, borderStyle: string}[]}
			maxValue: 0,
			legend: [], // {borderStyle, label}[]
			total: totalConfirmed,
		},
		forecastedContribution: {
			data: forecast.map(
				({ donor_country: donorCountry, total, hight, medium }) => ({
					label: donorCountry,
					value: total,
					percents: getPercents(total, sumOfTotalForecasted),
					multipleValues: [
						{
							length: getPercents(hight, maxForecastedValue),
							value: hight,
							label: 'High probability',
						},
						{
							length: getPercents(medium, maxForecastedValue),
							value: medium,
							label: 'Medium probability',
						},
					],
				}),
			),
			maxValue: Math.max(...forecast.map(({ total }) => total)),
			total: totalForecasted,
		},
		year: currentConfirmed.year,
	};

	if (currentConfirmed.data)
		currentConfirmed.data.forEach(({ total, label }) => {
			if (total > chart.confirmedContribution.maxValue) {
				chart.confirmedContribution.maxValue = total;
			}
			chart.confirmedContribution.data.push({
				value: total,
				label,
				year: currentConfirmed.year,
				previous: [],
				percents: getPercents(total, totalCurrentConfirmed.value),
				length: getPercents(total, maxConfirmedValue),
				tooltip: [
					{ label: currentConfirmed.year, value: getHumanNumberFormat(total) },
				],
			});
		});

	const displayedYears = [currentConfirmed.year - 1, currentConfirmed.year - 2];

	confirmed.slice(1).forEach(({ data, year }, i) => {
		data.forEach(({ total, label }) => {
			if (total > chart.confirmedContribution.maxValue) {
				chart.confirmedContribution.maxValue = total;
			}
			const countryData = chart.confirmedContribution.data.find(
				(country) => country.label === label,
			);
			if (countryData) {
				if (displayedYears.includes(year))
					countryData.previous.push({
						value: total,
						label,
						year,
						borderStyle: borders[i],
						length: getPercents(total, maxConfirmedValue),
					});

				countryData.tooltip.push({
					label: year,
					value: getHumanNumberFormat(total),
				});
			}
			if (
				!chart.confirmedContribution.legend.find(({ label }) => label === year)
			) {
				if (displayedYears.includes(year))
					chart.confirmedContribution.legend.push({
						borderStyle: borders[i],
						label: year,
					});
			}
		});
	});

	chart.confirmedContribution.legend.reverse();

	return chart;
};

export default formatDonorsChartData;
