import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Tooltip } from 'react-tippy';
import Tippy from '@tippyjs/react';

import { Icon, Modal } from '@wfp/ui';
import { iconInfoGlyph } from '@wfp/icons';

import { gaInfoLinkEvent } from 'anatytics';

import { NoData } from 'components/atoms';

import tooltipStyle from 'constants/tooltipStyle';

const InfoIndicatorPopup = ({ logInfoShowing, hoverText, title, children }) => {
	const [open, setOpen] = useState(false);
	const color = 'rgba(0, 0, 0, 0.3)';

	const onClick = () => {
		setOpen(!open);
		logInfoShowing();
	};

	return (
		<>
			{hoverText ? (
				<div className="hover-info" onClick={onClick}>
					{hoverText}
				</div>
			) : (
				<Icon
					icon={iconInfoGlyph}
					width="16"
					height="16"
					fill={color}
					onClick={onClick}
				/>
			)}
			<Modal
				open={open}
				modalHeading={title}
				onRequestClose={() => setOpen(false)}
				passiveModal
			>
				{children || <NoData />}
			</Modal>
		</>
	);
};

const InfoIndicatorHover = ({
	isMenu,
	theme,
	children,
	logInfoShowing,
	hoverText,
}) => {
	const color = 'rgba(0, 0, 0, 0.3)';

	if (isMenu) {
		return (
			<Tippy
				zIndex={10000}
				maxWidth={380}
				placement="bottom"
				animation="fade"
				duration={200}
				trigger="click"
				content={
					children ? <div className="tooltip-info">{children}</div> : <NoData />
				}
			>
				<div className="hover-info">
					<span onClick={logInfoShowing}>
						{hoverText || (
							<Icon icon={iconInfoGlyph} width="16" height="16" fill={color} />
						)}
					</span>
				</div>
			</Tippy>
		);
	}

	return (
		<Tooltip
			{...tooltipStyle}
			style={{ zIndex: 10000 }}
			position="bottom"
			interactive
			theme={theme}
			trigger="click"
			className="hover-info"
			html={
				children ? <div className="tooltip-info">{children}</div> : <NoData />
			}
		>
			<span onClick={logInfoShowing}>
				{hoverText || (
					<Icon icon={iconInfoGlyph} width="16" height="16" fill={color} />
				)}
			</span>
		</Tooltip>
	);
};

const InfoIndicator = ({
	isMenu = false,
	isHover,
	parentName,
	className,
	hoverText,
	theme,
	title,
	children,
}) => {
	let ContentInfoIndicator = InfoIndicatorPopup;
	if (isHover) ContentInfoIndicator = InfoIndicatorHover;

	const { countryCode, regionCode } = useParams();

	const logInfoShowing = () => {
		if (parentName) {
			gaInfoLinkEvent(countryCode || regionCode || 'global', parentName);
		}
	};

	return (
		<div
			className={`info-indicator ${className || ''}`}
			data-testid="InfoIndicator_component"
		>
			<ContentInfoIndicator
				isMenu={isMenu}
				hoverText={hoverText}
				theme={theme}
				logInfoShowing={logInfoShowing}
				title={title}
			>
				{children}
			</ContentInfoIndicator>
		</div>
	);
};

InfoIndicator.propTypes = {
	isHover: PropTypes.bool,
	hoverText: PropTypes.object,
	theme: PropTypes.string,
	parentName: PropTypes.string,
};

InfoIndicator.defaultProps = {
	isHover: false,
	hoverText: null,
	theme: 'dark',
};

export default InfoIndicator;
