import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { getHeaderEmployeesWithPhoto } from './getHeaderEmployeesWithPhoto';

const getCountryHeaderEmployees = async ({ code }) =>
	getHeaderEmployeesWithPhoto(
		`${appConfig.API_V2_URLS.COUNTRY_HEADER_EMPLOYEES + code}/`,
	);

export const useCountryHeaderEmployees = ({ code }, options) =>
	useQuery({
		queryFn: () => getCountryHeaderEmployees({ code }),
		...options,
	});
