import React, { useMemo } from 'react';

import { useFoodDistribution } from 'api';

import { InfoSource } from 'components/atoms';
import { ChartIndicatorsPanel } from 'components/templates';

import { getElementByKey } from 'utils';

import FoodDistributionIndicators from './FoodDistributionIndicators';

export const PANEL_KEY = 'food-distribution';

const FoodDistributionPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useFoodDistribution(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const isDataAvailable = useMemo(() => {
		if (!data) return false;

		return !(
			!data.needs_based_plan &&
			!data.implementation_plan &&
			!data.actual_distribution
		);
	}, [data]);

	const infoData = getElementByKey(data, 'info');

	return (
		<ChartIndicatorsPanel
			indicatorsOnly
			noData={!isDataAvailable}
			panelKey={PANEL_KEY}
			title={data ? `Food distribution (${data.year})` : 'Food distribution'}
			loading={isLoading}
			className="food-distribution-panel"
			indicatorsComponent={<FoodDistributionIndicators data={data} />}
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
		/>
	);
};

export default FoodDistributionPanel;
