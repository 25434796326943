import React from 'react';
import { useSelector } from 'react-redux';

import { useTopCountries } from 'api';

import { InfoSource } from 'components/atoms';
import { ChartIndicatorsPanel } from 'components/templates';

import { selectCurrentYear } from 'features/global/selectors';

import { getElementByKey } from 'utils';

import TopCountriesChart from './TopCountriesChart';
import TopCountriesIndicators from './TopCountriesIndicators';

export const PANEL_KEY = 'top-countries';

const TopCountriesPanel = ({ regionCode }) => {
	const { data, isLoading } = useTopCountries(
		{
			regionCode,
		},
		{
			queryKey: [regionCode, PANEL_KEY],
		},
	);

	const year = useSelector(selectCurrentYear);

	const infoData = getElementByKey(data, 'info');

	return (
		<ChartIndicatorsPanel
			loading={isLoading}
			noData={!data}
			panelKey={PANEL_KEY}
			title={year ? `Top 10 countries (${year})` : `Top 10 countries`}
			withCenterClass={false}
			allowFullExcelExport={false}
			chart={<TopCountriesChart data={data} />}
			indicatorsComponent={<TopCountriesIndicators data={data} />}
			className="top-countries-panel"
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			horizontal
		/>
	);
};

export default TopCountriesPanel;
