import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';

import { Button, Loading } from '@wfp/ui';

import { api } from 'api';

import { signIn, getTokenRedirect } from 'auth/azure-service';

import { setUserConfig } from 'features/global/globalSlice';

import appConfig from 'config';

const fetchUserConfig = async () => api.get(appConfig.API_V2_URLS.CONFIG);

const AuthState = {
	Authenticated: 'Authenticated',
	UnAuthenticated: 'UnAuthenticated',
	InProgress: 'InProgress',
};

const AuthProvider = ({ children, forceLogin }) => {
	const { instance } = useMsal();

	const dispatch = useDispatch();

	const [account, setAccount] = useState(null);
	const [authState, setAuthState] = useState(AuthState.UnAuthenticated);
	const [error, setError] = useState(null);

	const { data: userConfigData, isLoading: isUserConfigLoading } = useQuery({
		queryKey: [account, 'user-config'],
		queryFn: fetchUserConfig,
		enabled: !!account,
	});

	const login = async () => {
		if (authState !== AuthState.UnAuthenticated) {
			return;
		}

		setAuthState(AuthState.InProgress);
		signIn();
	};

	useEffect(() => {
		if (authState === AuthState.InProgress) {
			setTimeout(() => {
				getTokenRedirect()
					.then((tokenResponse) => {
						if (tokenResponse) {
							setAccount(tokenResponse.account);
							setAuthState(AuthState.Authenticated);
						}
					})
					.catch((e) => {
						console.warn(e);
						console.log("Can't find auth token. Call re-login.");
						signIn();
					});
			}, 500);
		}
	}, [authState]);

	useEffect(() => {
		instance
			.handleRedirectPromise()
			.then((response) => {
				if (response) {
					setAccount(response.account);
					setAuthState(AuthState.Authenticated);
				} else if (
					forceLogin &&
					!error &&
					authState === AuthState.UnAuthenticated
				) {
					setAuthState(AuthState.InProgress);
				}
			})
			.catch((error) => {
				setAuthState(AuthState.UnAuthenticated);
				setError(error.errorMessage);
				console.log('error happened', error);
			});
	}, [authState, error, forceLogin]);

	useEffect(() => {
		if (userConfigData) {
			dispatch(setUserConfig({ config: userConfigData }));
		}
	}, [userConfigData]);

	if (error) {
		return (
			<div>
				Auth Error: {error} <Button onClick={login}>login</Button>
			</div>
		);
	}

	if (authState === AuthState.Authenticated && !isUserConfigLoading) {
		return children;
	}

	if (authState === AuthState.UnAuthenticated && !forceLogin)
		return (
			<div>
				<Button onClick={login}>Login</Button>
			</div>
		);

	return (
		<div className="initializing-icon">
			<Loading withOverlay={false} />
		</div>
	);
};

export default AuthProvider;
