const rootURL = process.env.INFO_APP_API_ROOT;

function getAPIUrl(path, version = 'v2.1') {
	return `${rootURL}/api/${version}/${path}/`;
}

module.exports = {
	LOCALE: 'en-us',
	PDF_URL: `${rootURL}/api/render`,

	API_V2_URLS: {
		HEADER: getAPIUrl('header'),
		EXPORT: getAPIUrl('export-data', 'v2.4'),
		CONFIG: getAPIUrl('user-configuration'),
		SITE_MESSAGES: getAPIUrl('site-messages'),
		COUNTRY_HEADER_EMPLOYEES: getAPIUrl('country-header-employees', 'v2.9'),
		COUNTRY_HEADER_BENEFICIARIES: getAPIUrl(
			'country-header-beneficiaries',
			'v2.7',
		),
		COUNTRY_HEADER_POPULATION: getAPIUrl('country-header-population', 'v2.9'),
		COUNTRY_RESOURCES: getAPIUrl('country-resources', 'v2.8'),
		COUNTRY_DONORS: getAPIUrl('country-donors', 'v2.6'),
		COUNTRY_PARTNERS: getAPIUrl('country-ngo-partners', 'v2.4'),
		COUNTRY_IMPLEMENTATION: getAPIUrl('country-implementation', 'v2.2'),
		COUNTRY_FOCUS_AREAS: getAPIUrl('country-focus-area', 'v2.3'),
		COUNTRY_FOOD_DISTRIBUTION: getAPIUrl('country-food-distribution', 'v2.3'),
		COUNTRY_BENEFICIARIES: getAPIUrl('country-beneficiaries', 'v2.9'),
		COUNTRY_STRATEGIC_OUTCOME_CATEGORIES: getAPIUrl(
			'country-strategic-outcomes',
			'v2.4',
		),
		COUNTRY_STRATEGIC_RESULTS: getAPIUrl('country-strategic-results', 'v2.4'),
		COUNTRY_SDG_TARGETS: getAPIUrl('country-sdg-targets', 'v2.4'),
		COUNTRY_LOCAL_ECONOMIES: getAPIUrl('country-local-economies', 'v2.8'),
		COUNTRY_PROJECTED_REQUIREMENTS: getAPIUrl(
			'country-projected-requirements',
			'v2.8',
		),

		REGION_HEADER_EMPLOYEES: getAPIUrl('region-header-employees', 'v2.9'),
		REGION_HEADER_BENEFICIARIES: getAPIUrl(
			'region-header-beneficiaries',
			'v2.8',
		),
		REGION_HEADER_POPULATION: getAPIUrl('region-header-population', 'v2.9'),
		REGION_RESOURCES: getAPIUrl('region-resources', 'v2.8'),
		REGION_DONORS: getAPIUrl('region-donors', 'v2.6'),
		REGION_PARTNERS: getAPIUrl('region-ngo-partners', 'v2.4'),
		REGION_IMPLEMENTATION: getAPIUrl('region-implementation', 'v2.2'),
		REGION_FOCUS_AREAS: getAPIUrl('region-focus-area', 'v2.3'),
		REGION_FOOD_DISTRIBUTION: getAPIUrl('region-food-distribution', 'v2.3'),
		REGION_BENEFICIARIES: getAPIUrl('region-beneficiaries', 'v2.9'),
		REGION_TOP_COUNTRIES: getAPIUrl('region-top-countries', 'v2.8'),
		REGION_STRATEGIC_OUTCOME_CATEGORIES: getAPIUrl(
			'region-strategic-outcomes',
			'v2.4',
		),
		REGION_STRATEGIC_RESULTS: getAPIUrl('region-strategic-results', 'v2.4'),
		REGION_SDG_TARGETS: getAPIUrl('region-sdg-targets', 'v2.4'),
		REGION_LOCAL_ECONOMIES: getAPIUrl('region-local-economies', 'v2.8'),
		REGION_PROJECTED_REQUIREMENTS: getAPIUrl(
			'region-projected-requirements',
			'v2.8',
		),

		GLOBAL_HEADER_EMPLOYEES: getAPIUrl('global-header-employees', 'v2.8'),
		GLOBAL_HEADER_BENEFICIARIES: getAPIUrl(
			'global-header-beneficiaries',
			'v2.8',
		),
		GLOBAL_HEADER_POPULATION: getAPIUrl('global-header-population', 'v2.9'),
		GLOBAL_RESOURCES: getAPIUrl('global-resources', 'v2.8'),
		GLOBAL_DONORS: getAPIUrl('global-donors', 'v2.6'),
		GLOBAL_PARTNERS: getAPIUrl('global-ngo-partners', 'v2.4'),
		GLOBAL_IMPLEMENTATION: getAPIUrl('global-implementation', 'v2.2'),
		GLOBAL_STRATEGIC_OUTCOME_CATEGORIES: getAPIUrl(
			'global-strategic-outcomes',
			'v2.4',
		),
		GLOBAL_FOCUS_AREAS: getAPIUrl('global-focus-area', 'v2.3'),
		GLOBAL_FOOD_DISTRIBUTION: getAPIUrl('global-food-distribution', 'v2.3'),
		GLOBAL_BENEFICIARIES: getAPIUrl('global-beneficiaries', 'v2.9'),
		GLOBAL_TOP_COUNTRIES: getAPIUrl('global-top-countries', 'v2.8'),
		GLOBAL_STRATEGIC_RESULTS: getAPIUrl('global-strategic-results', 'v2.4'),
		GLOBAL_SDG_TARGETS: getAPIUrl('global-sdg-targets', 'v2.4'),
		GLOBAL_REGIONAL_BUREAUS: getAPIUrl('global-regional-bureaus', 'v2.4'),
		GLOBAL_OUTPUT_INDICATORS: getAPIUrl('global-output-indicators', 'v2.8'),
		GLOBAL_LOCAL_ECONOMIES: getAPIUrl('global-local-economies', 'v2.8'),
		GLOBAL_PROJECTED_REQUIREMENTS: getAPIUrl(
			'global-projected-requirements',
			'v2.8',
		),
	},
};
