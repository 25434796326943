import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	ResourcesPanel,
	DonorsPanel,
	LocalEconomiesPanel,
	ProjectedRequirementsPanel,
} from 'components/organisms';

import { useScrollOnTabChange } from 'hooks';

import { selectIsAdvanced } from 'features/global/selectors';

const ResourcesPage = () => {
	const navigate = useNavigate();
	const { regionCode, countryCode } = useParams();

	const isAdvancedMode = useSelector(selectIsAdvanced);

	useScrollOnTabChange();

	useEffect(() => {
		if (!isAdvancedMode) {
			navigate('/overview');
		}
	}, []);

	return (
		<>
			<div
				className="main-dashboard__panels"
				data-testid="OperationTab_main_dashboard__panels"
			>
				<ResourcesPanel countryCode={countryCode} regionCode={regionCode} />
			</div>
			<div className="main-dashboard__panels">
				<ProjectedRequirementsPanel
					countryCode={countryCode}
					regionCode={regionCode}
				/>
				<LocalEconomiesPanel
					countryCode={countryCode}
					regionCode={regionCode}
				/>
			</div>
			<div
				className="main-dashboard__panels"
				data-testid="OperationTab_main_dashboard__panels"
			>
				<DonorsPanel countryCode={countryCode} regionCode={regionCode} />
			</div>
		</>
	);
};

export default ResourcesPage;
