function getHumanNumberWithSymbol(
	data,
	toFixed = 1,
	toFixedIfLess1000 = false,
) {
	const value = Math.abs(data);

	try {
		if (value > 999999999) return [(data / 1000000000).toFixed(toFixed), 'B'];
		if (value > 999999) return [(data / 1000000).toFixed(toFixed), 'M'];
		if (value > 999) return [(data / 1000).toFixed(toFixed), 'K'];
		if (value)
			return [parseFloat(data).toFixed(toFixedIfLess1000 ? toFixed : 0), ''];
	} catch (e) {
		console.warn(value, e);
	}
	return [data, ''];
}

export default getHumanNumberWithSymbol;
