import React from 'react';

import { Tooltip } from 'react-tippy';

import { Legend } from 'components/atoms';
import BarIndicatorWithLabels, {
	TooltipContent,
} from 'components/molecules/BarIndicatorWithLabels';

import colors from 'constants/colors';
import tooltipStyle from 'constants/tooltipStyle';

import { useWindowSize } from 'hooks';

import { getHumanNumberFormat, getPercents } from 'utils';

const ForecastedBars = ({ forecastedContribution }) => {
	const [width] = useWindowSize();
	const totalForecast = forecastedContribution.total || {};
	const hightForecast = totalForecast.hight || 0;
	const mediumForecast = totalForecast.medium || 0;
	const currentTotal = hightForecast + mediumForecast;

	const forecastedContributionData = [
		{ label: 'High probability', value: hightForecast },
		{ label: 'Medium probability', value: mediumForecast },
	];

	return (
		<div className="donors-bars__forecasted">
			<div className="indicators-row__title__container">
				<h4 className="indicators-row__title">
					Forecasted contribution from top 10 donors
				</h4>
				<div className="indicators-row__subtitle">
					In USD and % of total forecasted contribution (
					<Tooltip
						{...tooltipStyle}
						theme="dark"
						position="bottom"
						className="tooltip"
						html={
							<TooltipContent
								values={forecastedContributionData}
								title="Total forecasted contribution"
							/>
						}
					>
						{`${getHumanNumberFormat(currentTotal)} USD`}
					</Tooltip>
					)
				</div>
			</div>
			{forecastedContribution.data.map((item, i) => (
				<BarIndicatorWithLabels
					key={`${item.label}-${item.value}-forecasted`}
					order={i + 1}
					withTooltip
					maxLabelLength={14}
					truncateWidth={width < 350 ? 120 : 160}
					isMultiple
					{...item}
					length={getPercents(item.value, forecastedContribution.maxValue)}
				/>
			))}
			<div className="donors-legend__container">
				<Legend
					lines={['high', 'medium']}
					labelMap={{
						high: {
							label: 'High probability',
						},
						medium: {
							label: 'Medium probability',
						},
					}}
					colorScale={colors.multipleBarsPalette}
					orientation="horizontal"
				/>
			</div>
		</div>
	);
};

export default ForecastedBars;
