import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import cn from 'classnames';

import { Tooltip } from 'react-tippy';

import { BarIndicator } from 'components/atoms';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat } from 'utils';
import Truncate from './Truncate';

export const TooltipContent = ({
	values,
	title,
	withTooltipLegend,
	className,
}) => (
	<div className={cn(className, 'bar-tooltip')}>
		<div className="bar-tooltip__title">{title}</div>
		{values.map(({ value, label, extraData }) => {
			const _value = value ? getHumanNumberFormat(value) : 0;

			return (
				<React.Fragment key={`tooltip-content-${value}-${label}`}>
					<div className="bar-tooltip__item horizontal">
						{!!withTooltipLegend && <span className="mark" />}
						<div className="label">{label}</div>
						<div className="value">{_value}</div>
					</div>

					{!!extraData && (
						<div className="extra-data-wrap">
							<div className="extra-data-wrap__inner">
								{extraData.map(({ label: extraLabel, value }, index) => (
									<div
										className="extra-data-wrap__item"
										key={`${extraLabel}-${index}`}
									>
										<div className="label">{extraLabel}</div>
										<div className="value">
											{value ? getHumanNumberFormat(value) : 0}
										</div>
									</div>
								))}
							</div>
						</div>
					)}
				</React.Fragment>
			);
		})}
	</div>
);

const BarIndicatorWithLabels = ({
	order,
	label,
	value,
	length,
	percents,
	isMultiple,
	multipleValues,
	withTooltip,
	previous,
	truncateWidth = 160,
	labelWidth,
	additionSymbol,
	tooltipData = {},
	labelClassName = '',
	percentsEmptyValue,
	color,
	isDark = true,
	extraData = null,
	withTooltipLegend,
	tooltipClassName,
	thirdValue,
	thirdPercents,
}) => {
	const [isLabelTooltipVisible, setLabelTooltipVisible] = useState(false);
	const [isLabelTruncated, setLabelTruncated] = useState(false);

	const showLabelTooltip = () => {
		if (isLabelTruncated) {
			setLabelTooltipVisible(true);
		}
	};

	const onTruncate = (isTruncated) => {
		setLabelTruncated(isTruncated);
	};

	return (
		<div className="bar-indicator-row__container">
			<Tooltip
				{...tooltipStyle}
				open={isLabelTooltipVisible}
				theme="light"
				position="top"
				className={cn(`'wfp-tooltip wfp-tooltip-v2`, {
					pointer: isLabelTruncated,
				})}
				title={label}
			>
				<div className="bar-indicator-row__desc">
					<div className="bar-indicator-row__main-content">
						{!!order && (
							<p className="bar-indicator-row__item order">{order}.</p>
						)}
						{!!label && (
							<div
								className={cn(
									`bar-indicator-row__item bar-indicator-row__item-label`,
									labelClassName,
								)}
							>
								<Truncate
									onTruncate={onTruncate}
									lines={1}
									width={truncateWidth}
									onMouseEnter={showLabelTooltip}
									style={labelWidth ? { width: labelWidth } : {}}
									onMouseLeave={() => setLabelTooltipVisible(false)}
								>
									<p>{label}</p>
								</Truncate>
							</div>
						)}
					</div>
				</div>
			</Tooltip>
			{withTooltip ? (
				<Tooltip
					{...tooltipStyle}
					style={{ display: 'flex' }}
					theme={isDark ? 'dark' : 'light'}
					position="bottom"
					className="tooltip"
					html={
						<TooltipContent
							values={tooltipData.values || multipleValues}
							title={label}
							className={tooltipClassName}
							withTooltipLegend={withTooltipLegend}
						/>
					}
				>
					<BarIndicator
						value={value}
						length={length}
						percents={percents}
						isMultiple={isMultiple}
						multipleValues={multipleValues}
						additionSymbol={additionSymbol}
						previous={previous}
						percentsEmptyValue={percentsEmptyValue}
						color={color}
						extraData={extraData}
						extraDataLabel={label}
						thirdValue={thirdValue}
						thirdPercents={thirdPercents}
					/>
				</Tooltip>
			) : (
				<BarIndicator
					value={value}
					length={length}
					percents={percents}
					isMultiple={isMultiple}
					multipleValues={multipleValues}
					additionSymbol={additionSymbol}
					previous={previous}
					percentsEmptyValue={percentsEmptyValue}
					color={color}
					extraData={extraData}
					extraDataLabel={label}
					thirdValue={thirdValue}
					thirdPercents={thirdPercents}
				/>
			)}
		</div>
	);
};

BarIndicatorWithLabels.propTypes = {
	order: PropTypes.number,
	label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	percents: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	multipleValues: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		}),
	),
	previous: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			borderStyle: PropTypes.string,
		}),
	),
	truncateWidth: PropTypes.number,
	isMultiple: PropTypes.bool,
	withTooltip: PropTypes.bool,
	labelClassName: PropTypes.string,
	color: PropTypes.string,
	tooltipData: PropTypes.shape({
		values: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
				label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			}),
		),
	}),
	extraData: PropTypes.array,
	thirdValue: PropTypes.number,
	thirdPercents: PropTypes.number,
};

export default BarIndicatorWithLabels;
