import React from 'react';
import PropTypes from 'prop-types';

import { useImplementation } from 'api';

import { InfoSource } from 'components/atoms';
import { ChartIndicatorsPanel } from 'components/templates';

import { getElementByKey } from 'utils';

import GroupedSwitcherIndicators from './GroupedSwitcherIndicators';

export const PANEL_KEY = 'cost-categories';

const ImplementationPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useImplementation(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const modality = getElementByKey(data, 'breakdown_by_modality', []);
	const infoData = getElementByKey(data, 'info');
	const year = getElementByKey(data, 'year');

	return (
		<ChartIndicatorsPanel
			title={year ? `Cost categories (${year})` : 'Cost categories'}
			panelKey={PANEL_KEY}
			className="implementation-plan-panel"
			indicatorsComponent={<GroupedSwitcherIndicators data={modality} />}
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			loading={isLoading}
			noData={
				!data ||
				!data.breakdown_by_modality ||
				!data.breakdown_by_modality.length
			}
			indicatorsOnly
		/>
	);
};

ImplementationPanel.propTypes = {
	countryCode: PropTypes.string,
	regionCode: PropTypes.string,
};

ImplementationPanel.defaultProps = {
	countryCode: null,
	regionCode: null,
};

export default ImplementationPanel;
