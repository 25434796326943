const keyLabelMap = {
	refugees: 'Refugees',
	migrant: 'Migrants',
	tdp: 'TDPs',
	idp: 'IDPs',
	non_refugees: 'Non-Refugees',
	resident: 'Residents',
	returnee: 'Returnees',
	other: 'Other',
};

export default keyLabelMap;
