import React from 'react';

import { DashLegend } from 'components/atoms';

import CompareGroupedIndicators from './CompareGroupedIndicators';

const TopGroupedIndicators = ({
	data = [],
	title = 'Top Grouped Indicators',
	subTitle,
	hideStick = false,
	baseValue,
	autoOrdering = true,
	barLabelWidth,
	legend = [],
}) => (
	<div
		style={{
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
		}}
		className="top-grouped-indicators"
		data-testid="topGroupedIndicators_component"
	>
		<div className="title">
			{title}
			<div className="title__subtitle">{subTitle}</div>
		</div>
		<CompareGroupedIndicators
			data={data}
			hideStick={hideStick}
			baseValue={baseValue}
			autoOrdering={autoOrdering}
			barLabelWidth={barLabelWidth}
			maxAsBase
		>
			<DashLegend legend={legend} />
		</CompareGroupedIndicators>
	</div>
);

export default TopGroupedIndicators;
