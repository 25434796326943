import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getSdgTargets = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_SDG_TARGETS;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_SDG_TARGETS}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_SDG_TARGETS}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useSdgTargets = ({ countryCode, regionCode }, options) =>
	useQuery({
		queryFn: () => getSdgTargets({ countryCode, regionCode }),
		...options,
	});
