import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	name: '',
	code: '',
	title: '',
	emergency: [],
};

const officeSlice = createSlice({
	name: 'office',
	initialState,
	reducers: {
		setOffice: (state, action) => {
			const { emergency, ...other } = action.payload;

			return {
				...other,
				emergency: emergency || [],
			};
		},
	},
});

export const { setOffice } = officeSlice.actions;

export default officeSlice.reducer;
