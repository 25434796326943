import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getGlobalHeaderPopulation = async () =>
	api.get(appConfig.API_V2_URLS.GLOBAL_HEADER_POPULATION);

export const useGlobalHeaderPopulation = (options) =>
	useQuery({
		queryFn: () => getGlobalHeaderPopulation(),
		...options,
	});
