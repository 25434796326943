import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getRegionHeaderBeneficiaries = async ({ code }) =>
	api.get(`${appConfig.API_V2_URLS.REGION_HEADER_BENEFICIARIES + code}/`);

export const useRegionHeaderBeneficiaries = ({ code }, options) =>
	useQuery({
		queryFn: () => getRegionHeaderBeneficiaries({ code }),
		...options,
	});
