import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Tabs as WfpTabs, Tab as WfpTab } from '@wfp/ui';

import { gaTabSelectionEvent } from 'anatytics';

import { selectCurrentYear, selectIsAdvanced } from 'features/global/selectors';

import { getCountryCode, getRegionCode } from 'utils';

import HamburgerMenu from './HamburgerMenu';
import TabLink from './TabLink';

export const getTabs = (isNewCrf, countryCode, regionCode, isAdvanced) => {
	let result = [
		{
			href: 'overview',
			label: `Overview`,
			disabled: false,
			panels: [
				{ label: 'Outlook', href: 'outlook' },
				{ label: 'Beneficiaries', href: 'beneficiaries-by-programme-area' },
			],
		},
		{
			href: 'resources',
			label: `Resources`,
			disabled: false,
			panels: [
				{ label: 'Resourcing outlook', href: 'resourcing-outlook' },
				{
					label: 'Projected operational requirements',
					href: 'projected-requirements',
				},
				{ label: 'Supporting local economies', href: 'local-economies' },
				{ label: 'Top 10 donors', href: 'top-10-donors' },
			],
		},
		{
			href: 'planvsactuals',
			label: 'Plan vs actuals',
			disabled: false,
			panels: [
				{ label: 'NGO partners', href: 'NGO-partners' },
				{ label: 'Strategic outcomes', href: 'strategic-outcomes' },
				{ label: 'Cost categories', href: 'cost-categories' },
				{
					label: isNewCrf ? 'SDG targets' : 'Strategic results',
					href: isNewCrf ? 'sdg-targets' : 'strategic-results',
				},
				{ label: 'Food distribution', href: 'food-distribution' },
				{ label: 'Focus areas', href: 'focus-areas' },
			],
		},
	];

	if (!countryCode && !regionCode) {
		result[0].panels.push({
			label: 'Output indicators',
			href: 'output-indicators',
		});
		result[0].panels.push({
			label: 'Regional Bureaux',
			href: 'regional-bureaux',
		});
	}

	if (!countryCode) {
		result[0].panels.push({
			label: 'Top 10 countries',
			href: 'top-countries',
		});
	}

	if (!isAdvanced) {
		result = [
			{
				...result[0],
				panels: [...result[0].panels, ...result[1].panels, ...result[2].panels],
			},
		];
	}

	return result;
};

const Tabs = () => {
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const location = useLocation();

	const countryCode = getCountryCode();
	const regionCode = getRegionCode();

	const currentYear = useSelector(selectCurrentYear);
	const isAdvancedMode = useSelector(selectIsAdvanced);

	const isNewCrf = currentYear >= 2023;

	const tabs = useMemo(
		() => getTabs(isNewCrf, countryCode, regionCode, isAdvancedMode),
		[isNewCrf, countryCode, regionCode, isAdvancedMode],
	);

	useEffect(() => {
		const tabIndex = tabs
			.map(({ href }) => `/${href}`)
			.findIndex((href) =>
				location.pathname ? location.pathname.includes(href) : false,
			);
		const tabHREF = tabs[tabIndex] ? tabs[tabIndex].href : location.pathname;

		gaTabSelectionEvent(countryCode || regionCode || 'global', tabHREF);

		setActiveTabIndex(tabIndex);
	}, [location]);

	return (
		<div className="tabs__container">
			<HamburgerMenu tabs={tabs} />
			<WfpTabs selected={activeTabIndex} triggerHref="#" role="navigation">
				{tabs.map((item) => (
					<WfpTab
						renderAnchor={({ href, label }) => (
							<TabLink href={href} label={label} />
						)}
						href={item.href}
						label={item.label}
						key={item.href}
						disabled={item.disabled}
					/>
				))}
			</WfpTabs>
		</div>
	);
};

export default Tabs;
