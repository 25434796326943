import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useNgoPartners } from 'api';

import { Tooltip } from 'react-tippy';

import { NoData, InfoSource, Legend } from 'components/atoms';
import { DoughnutChart } from 'components/molecules';
import { TooltipContent } from 'components/molecules/BarIndicatorWithLabels';
import { ChartIndicatorsPanel } from 'components/templates';

import colors from 'constants/colors';
import tooltipStyle from 'constants/tooltipStyle';

import { useWindowSize } from 'hooks';

import { getElementByKey, getHumanNumberFormat, getPercents } from 'utils';

import TopGroupedIndicators from './TopGroupedIndicators';

const PartnersPieChart = (props) => {
	const { local, international } = props;
	const [width] = useWindowSize();

	const total = local + international;
	const data = [
		{
			y: international,
			x: 'International partners',
		},
		{ y: local, x: 'Local partners' },
	];

	const doughnutChartProps = {
		withLabel: false,
		width: width < 370 ? 230 : 270,
		height: width < 370 ? 230 : 270,
		innerRadius: width < 370 ? 55 : 65,
		data,
		valueType: 'usd',
		colorScale: colors.doughnutChartPalette,
		bottomTextLines: ['NGO', 'EXPENDITURES'],
	};

	const lines = ['international', 'local'];
	const labelMap = {
		international: {
			label: data[0].x,
			percents:
				data[0].y <= 0
					? 'N/A'
					: `${data[0].y > total ? 100 : getPercents(data[0].y, total)}%`,
			value: `${getHumanNumberFormat(data[0].y)}`,
		},
		local: {
			label: data[1].x,
			percents:
				data[1].y <= 0
					? 'N/A'
					: `${data[1].y > total ? 100 : getPercents(data[1].y, total)}%`,
			value: `${getHumanNumberFormat(data[1].y)}`,
		},
	};

	return (
		<>
			<div className="chart__title">
				NGO expenditures
				<div className="subtitle">In USD</div>
			</div>

			{!total ? (
				<NoData />
			) : (
				<>
					<div
						className="doughnut-chart__container"
						data-testid="feesPaidAreaChart_component"
					>
						<DoughnutChart {...doughnutChartProps} />
					</div>
					<Legend
						separated
						labelMap={labelMap}
						lines={lines}
						colorScale={doughnutChartProps.colorScale}
					/>
				</>
			)}
		</>
	);
};

const TitleComponent = ({ totalData, fullText, year, label }) => {
	if (!Array.isArray(totalData))
		return `${fullText} ${getHumanNumberFormat(totalData)} USD`;

	const sortedData = totalData.sort((a, b) => (a.year > b.year ? -1 : 1));

	const currentItem = sortedData.find((item) => item.year === year.toString());
	const currentValue = currentItem ? currentItem.total : 0;

	const correctData = sortedData.map((item) => ({
		label: item.year,
		value: item.total,
	}));

	return (
		<>
			{fullText} (
			<Tooltip
				{...tooltipStyle}
				theme="dark"
				position="bottom"
				className="tooltip"
				html={<TooltipContent values={correctData} title={label} />}
			>
				{`${getHumanNumberFormat(currentValue)} USD`}
			</Tooltip>
			)
		</>
	);
};

const PartnersBreakdown = (props) => {
	const {
		localData,
		internationalData,
		localTotal,
		localTotalCurrent,
		internationalTotal,
		internationalTotalCurrent,
		year,
		localCount,
		internationalCount,
	} = props;

	const [width] = useWindowSize();
	let barLabelWidth;
	if ((width < 1150 && width > 1024) || width < 400) {
		barLabelWidth = 130;
	}

	return (
		<>
			<TopGroupedIndicators
				data={internationalData}
				barLabelWidth={barLabelWidth}
				baseValue={internationalTotalCurrent}
				title={`Top 5 international partners by NGO expenditures  
          (${internationalCount} in total)`}
				subTitle={
					<TitleComponent
						label="Total NGO exp. international"
						fullText={
							'In USD and % of total NGO expenditures to ' +
							'international partners'
						}
						totalData={internationalTotal}
						year={year}
					/>
				}
				legend={[
					{
						label: `${year - 1}`,
						borderStyle: 'solid',
					},
				]}
			/>
			<TopGroupedIndicators
				data={localData}
				barLabelWidth={barLabelWidth}
				baseValue={localTotalCurrent}
				title={`Top 5 local partners by NGO expenditures (${localCount} in total)`}
				subTitle={
					<TitleComponent
						label="Total NGO exp. local"
						fullText="In USD and % of total NGO expenditures to local partners"
						totalData={localTotal}
						year={year}
					/>
				}
				legend={[
					{
						label: `${year - 1}`,
						borderStyle: 'solid',
					},
				]}
			/>
		</>
	);
};

export const PANEL_KEY = 'NGO-partners';

const PartnersPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useNgoPartners(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const {
		international_data: internationalData,
		local_data: localData,
		local_total: localTotal,
		international_total: internationalTotal,
		international_count: internationalCount = 0,
		local_count: localCount = 0,
	} = data || {};

	// indicator data
	const indicatorsData = getElementByKey(data, 'top_partners', []);

	const bottomDescription =
		'The NGO partnership data is currently under ' +
		'review and will be updated shortly';
	const infoData = getElementByKey(data, 'info');
	const year = getElementByKey(data, 'year');

	let localTotalCurrent = null;
	let internationalTotalCurrent = null;
	if (Array.isArray(localTotal) && Array.isArray(internationalTotal)) {
		const localCurrent = localTotal.find(
			(item) => item.year === year.toString(),
		);

		if (localCurrent) {
			localTotalCurrent = localCurrent.total;
		}
		const internationalCurrent = internationalTotal.find(
			(item) => item.year === year.toString(),
		);

		if (internationalCurrent) {
			internationalTotalCurrent = internationalCurrent.total;
		}
	}

	const isDataAvailable = useMemo(() => {
		if (!data) {
			return false;
		}
		return internationalData || localData;
	}, [data]);

	return (
		<ChartIndicatorsPanel
			title={year ? `NGO partners (${year})` : `NGO partners`}
			className="partners-panel-content"
			bottomDisclaimer={bottomDescription}
			panelKey={PANEL_KEY}
			horizontal
			withCenterClass={false}
			chart={
				isDataAvailable ? (
					<PartnersPieChart
						local={localTotalCurrent}
						international={internationalTotalCurrent}
					/>
				) : (
					<NoData />
				)
			}
			indicatorsComponent={
				<PartnersBreakdown
					localCount={localCount}
					internationalCount={internationalCount}
					localData={localData}
					internationalData={internationalData}
					localTotal={localTotal}
					localTotalCurrent={localTotalCurrent}
					internationalTotal={internationalTotal}
					internationalTotalCurrent={internationalTotalCurrent}
					indicatorsData={indicatorsData}
					data={data}
					year={year}
				/>
			}
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			loading={isLoading}
			noData={!data}
		/>
	);
};

PartnersPanel.propTypes = {
	countryCode: PropTypes.string,
	regionCode: PropTypes.string,
};

PartnersPanel.defaultProps = {
	countryCode: null,
	regionCode: null,
};

export default PartnersPanel;
