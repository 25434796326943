import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import CSULarge from 'assets/emergencies/CSU-large.svg';
import CALarge from 'assets/emergencies/CA-large.svg';
import EAERLarge from 'assets/emergencies/EAER-large.svg';

import { getHumanNumberFormat } from 'utils';

const BreakdownByCountry = ({ topBeneficiaries = [] }) => {
	const displayedEmergenciesLabels = ['L1', 'L2', 'L3', 'M'];
	const imageMap = {
		SU: CSULarge,
		CA: CALarge,
		AR: EAERLarge,
	};

	return (
		<div className="emergency-list country-breakdown">
			{topBeneficiaries.map(
				({ country, emergencies, total_beneficiary: totalBeneficiary }) => (
					<div
						className="country-breakdown-item"
						key={`beneficiaries-country-${country.name}-${totalBeneficiary}`}
					>
						<NavLink
							className="country-breakdown-item__label"
							to={`/countries/${country.iso_code3}/overview`}
						>
							{country.name}
						</NavLink>
						{!!emergencies.length && (
							<div
								className={`emergency-level ${emergencies[0].toUpperCase()}`}
							>
								{displayedEmergenciesLabels.includes(emergencies[0]) ? (
									emergencies[0]
								) : (
									<img
										src={window.location.origin + imageMap[emergencies[0]]}
										alt={emergencies[0]}
										className="small"
									/>
								)}
							</div>
						)}
						<span className="country-breakdown-item__value">
							{getHumanNumberFormat(totalBeneficiary)}
						</span>
					</div>
				),
			)}
		</div>
	);
};

BreakdownByCountry.propTypes = {
	topBeneficiaries: PropTypes.arrayOf(
		PropTypes.shape({
			country: PropTypes.shape({
				iso_code3: PropTypes.string,
				name: PropTypes.string,
			}),
			emergencies: PropTypes.arrayOf(PropTypes.string),
			total_beneficiary: PropTypes.number,
		}),
	).isRequired,
};

export default BreakdownByCountry;
