import React from 'react';

import GooglePlayImage from 'assets/google-play.png';

const GooglePlayLink = () => (
	<a
		className="google-play-link footer-links__image-wrapper"
		href="https://play.google.com/store/apps/details?id=com.wfpdashboard"
		target="_blank"
		rel="noopener noreferrer"
	>
		<img src={GooglePlayImage} alt="loading" />
	</a>
);

export default GooglePlayLink;
