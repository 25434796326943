import React from 'react';

import { getHumanNumberFormat } from 'utils';

const SimpleIndicator = ({
	data,
	className: classNameProp,
	isBreakdown,
	label,
}) => {
	const value = getHumanNumberFormat(data);
	const className = classNameProp || '';
	const valueClassName = `population-item-row__value ${
		isBreakdown ? 'breakdown' : ''
	}`;

	return (
		<div className={`population-item-row ${className}`}>
			<span className="population-item-row__label">{label}</span>
			<span className={valueClassName}>
				{typeof data !== 'undefined' ? value : 'N/A'}
			</span>
		</div>
	);
};

export default SimpleIndicator;
