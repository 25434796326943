import React, { useMemo } from 'react';

import { NoData } from 'components/atoms';

import { formatDonorsChartData } from 'utils';

import ConfirmedBars from './ConfirmedBars';
import ForecastedBars from './ForecastedBars';

const Bars = ({ data, isCountryLevel, isRegionLevel }) => {
	const { confirmedContribution, forecastedContribution } = useMemo(
		() => formatDonorsChartData(data) || {},
		[data],
	);

	return (
		<div className="donors-bars__container">
			{confirmedContribution.data && confirmedContribution.data.length ? (
				<ConfirmedBars
					isCountryLevel={isCountryLevel}
					isRegionLevel={isRegionLevel}
					confirmedContribution={confirmedContribution}
				/>
			) : (
				<NoData />
			)}
			{forecastedContribution.data && forecastedContribution.data.length ? (
				<ForecastedBars
					isCountryLevel={isCountryLevel}
					isRegionLevel={isRegionLevel}
					forecastedContribution={forecastedContribution}
				/>
			) : (
				<NoData />
			)}
		</div>
	);
};

export default Bars;
