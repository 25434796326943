import React from 'react';

import { Tooltip } from 'react-tippy';

import { LoadingBar } from 'components/atoms';

import tooltipStyle from 'constants/tooltipStyle';

import {
	getElementByKey,
	getHumanNumberFormat,
	getHumanNumberWithSymbol,
} from 'utils';

const ToolTipContent = ({ data, label }) => {
	const items = data.map((item) => {
		const value = getHumanNumberFormat(item.value);

		return (
			<div
				className="bar-tooltip__item horizontal"
				key={`tooltip-content-${item.year}`}
			>
				<div className="label">{item.year}</div>
				<div className="value">{value || ''}</div>
			</div>
		);
	});

	return (
		<div className="bar-tooltip">
			<div className="bar-tooltip__title">{label}</div>
			{items}
		</div>
	);
};

const HorizontalIndicatorMultiple = ({
	year,
	data: dataProp,
	beneficiariesData: beneficiariesDataProp,
	tooltipLabel,
	beneficiariesSubLabel,
	label,
	tooltipOpenPosition,
	tooltipMessage,
}) => {
	if (!year) return <LoadingBar />;

	const data = dataProp && dataProp.length ? dataProp : [];

	const beneficiariesData =
		beneficiariesDataProp && beneficiariesDataProp.length
			? beneficiariesDataProp
			: [];

	const currentItem = data.find(
		(item) => item.year.toString() === year.toString(),
	);
	const [currValue, currSymbol] = getHumanNumberWithSymbol(
		getElementByKey(currentItem, 'value', 0),
	);

	const isBigNumber = Number(currValue) >= 100;

	const beneficiariesCurrentItem = beneficiariesData.find(
		(item) => item.year.toString() === year.toString(),
	);

	const [benCurrValue, benCurrSymbol] = getHumanNumberWithSymbol(
		getElementByKey(beneficiariesCurrentItem, 'value', 0),
	);

	const tooltipLabelComponent =
		tooltipLabel || `${beneficiariesSubLabel} ${label}`;

	return (
		<div className="horizontal-indicator-multiple">
			<div className="horizontal-indicator-multiple__main-content">
				<div className="horizontal-indicator-multiple__values">
					<div className="currency">USD</div>
					<Tooltip
						{...tooltipStyle}
						theme="light"
						position={tooltipOpenPosition}
						className="tooltip"
						html={<ToolTipContent data={data} label={tooltipLabelComponent} />}
					>
						<div className="value">
							<span>{currValue}</span>
							<span className="symbol">{currSymbol}</span>
						</div>
					</Tooltip>
				</div>
				{tooltipMessage ? (
					<Tooltip
						{...tooltipStyle}
						style={{ width: 150 }}
						theme="light"
						position={tooltipOpenPosition}
						className="horizontal-indicator-multiple__labels pointer"
						html={<div className="tooltip-content-msg">{tooltipMessage}</div>}
					>
						<div className="year">
							{year} <b>{beneficiariesSubLabel}</b>
						</div>
						<div className="label">
							<div
								className={`value${isBigNumber ? '-big' : ''}`}
							>{`${label} *`}</div>
						</div>
					</Tooltip>
				) : (
					<div className="horizontal-indicator-multiple__labels">
						<div className="year">
							{year} <b>{beneficiariesSubLabel}</b>
						</div>
						<div className="label">
							<div className={`value${isBigNumber ? '-big' : ''}`}>{label}</div>
						</div>
					</div>
				)}
			</div>

			{!!beneficiariesData?.length && (
				<div className="horizontal-indicator-multiple__beneficiaries">
					<Tooltip
						{...tooltipStyle}
						theme="light"
						position={tooltipOpenPosition}
						className="tooltip"
						html={
							<ToolTipContent
								data={beneficiariesData}
								label={`${beneficiariesSubLabel} beneficiaries`}
							/>
						}
					>
						<div className="value">
							<span>
								{benCurrValue}
								{benCurrSymbol}
							</span>
						</div>
					</Tooltip>
					<span className="year">{year}</span>
					<span className="label">{beneficiariesSubLabel} beneficiaries</span>
				</div>
			)}
		</div>
	);
};

export default HorizontalIndicatorMultiple;
