import React, { useMemo } from 'react';

import { Tooltip } from 'react-tippy';

import { SimpleIndicator } from 'components/atoms';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat, getPercents } from 'utils';

import BarIndicatorWithLabels from '../../BarIndicatorWithLabels';

const BreakDownByRegionIndicator = ({ indicators = [], forcedToFlee }) => {
	const maxValue = useMemo(
		() =>
			Math.max(
				...indicators.map(
					({ refugees, internally_displaced: idp }) => refugees + idp.total,
				),
			),
		[indicators],
	);

	const idpTotal = forcedToFlee?.internally_displaced || {};
	const refugeesTotal = forcedToFlee?.refugees || {};

	return (
		<div>
			<Tooltip
				{...tooltipStyle}
				className="wfp-tooltip wfp-tooltip-v2 header-dashboard-item__label"
				interactive
				disabled={!forcedToFlee?.total}
				trigger="click"
				position="bottom"
				html={
					<>
						<div className="breakdown-label">Forcibly displaced</div>

						<div className="breakdown-list">
							<div className="breakdown-list__item">
								<div>Refugees</div>
								<div>
									{refugeesTotal?.total
										? getHumanNumberFormat(refugeesTotal?.total)
										: 'N/A'}
								</div>
							</div>
							<div className="breakdown-list__item-container">
								<div className="breakdown-list__item">
									<div>Total IDPs</div>
									<div>
										{idpTotal?.total
											? getHumanNumberFormat(idpTotal?.total)
											: 'N/A'}
									</div>
								</div>
								<div className="extra-data-wrap">
									<div className="extra-data-wrap__inner">
										<div className="extra-data-wrap__item">
											<div>Conflict IDPs</div>
											<div>
												{idpTotal?.conflict
													? getHumanNumberFormat(idpTotal?.conflict)
													: 'N/A'}
											</div>
										</div>
										<div className="extra-data-wrap__item">
											<div>Disaster IDPs</div>
											<div>
												{idpTotal?.disaster
													? getHumanNumberFormat(idpTotal?.disaster)
													: 'N/A'}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				}
			>
				<SimpleIndicator
					label="Forcibly displaced"
					data={forcedToFlee?.total}
					isBreakdown
				/>
			</Tooltip>

			{indicators.map(({ region, refugees, internally_displaced: idp }) => (
				<div
					className="header-dashboard-item__indicator"
					key={`global-population-${region.name}-${refugees}`}
				>
					<BarIndicatorWithLabels
						value={refugees + idp.total}
						length={getPercents(refugees + idp.total, maxValue)}
						isMultiple
						withTooltip
						labelClassName="header-dashboard-item__indicator__label"
						tooltipClassName="forcibly-displaced-tooltip"
						withLabelTooltip={false}
						withTooltipLegend
						isDark={false}
						tooltipData={{
							values: [
								{ value: refugees, label: 'Refugees' },
								{
									value: idp.total,
									label: 'Total IDPs',
									extraData: [
										{ value: idp.conflict, label: 'Conflict IDPs' },
										{ value: idp.disaster, label: 'Disaster IDPs' },
									],
								},
							],
						}}
						multipleValues={[
							{
								value: refugees,
								length: getPercents(refugees, maxValue),
							},
							{
								value: idp.total,
								length: getPercents(idp.total, maxValue),
							},
						]}
						label={region.short_name}
					/>
				</div>
			))}
			<div />
		</div>
	);
};

export default BreakDownByRegionIndicator;
