import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Tippy from '@tippyjs/react';

import CSULarge from 'assets/emergencies/CSU-large.svg';
import CALarge from 'assets/emergencies/CA-large.svg';
import EAERLarge from 'assets/emergencies/EAER-large.svg';

import { NoData, LoadingBar } from 'components/atoms';

import { getElementByKey, getHumanNumberFormat, numberWithCommas } from 'utils';

const titleMap = {
	L3: 'Level 3',
	L2: 'Level 2',
	M: 'Monitored events',
	SU: 'Corporate Scale-Up',
	CA: 'Corporate Attention',
	AR: 'Early Action and Emergency Response',
};

export const EmergencyLink = ({ emergencyName, opWebLink }) => (
	<a
		href={opWebLink}
		target="_blank"
		className="emergency-counties-list__item"
		rel="noreferrer"
	>
		{emergencyName}
	</a>
);

export const CountryLink = ({ country }) => {
	if (!getElementByKey(country, 'is_detailed')) {
		return (
			<div key={country.iso_code3} className="emergency-counties-list__item">
				{country.name}
			</div>
		);
	}

	return (
		<NavLink
			key={country.iso_code3}
			to={`/countries/${country.iso_code3}/overview`}
		>
			<div className="emergency-counties-list__item">{country.name}</div>
		</NavLink>
	);
};

export const List = ({ title, children }) => (
	<div className="emergency-counties-list">
		<div className="emergency-counties-list__title">{title}</div>
		{children}
	</div>
);

export const EmergencyCountries = ({ data, level }) => {
	if (!data) return <NoData />;

	const length = data?.length || 0;
	const maxRowAmmount = length > 12 ? 2 : 1;
	const maxItemsInRow = Math.ceil(length / maxRowAmmount);

	const mappedData = useMemo(
		() =>
			data?.reduce((resultArray, item, index) => {
				const chunkIndex =
					typeof maxItemsInRow !== 'undefined'
						? Math.floor(index / maxItemsInRow)
						: 0;

				if (!resultArray[chunkIndex]) {
					resultArray[chunkIndex] = []; // start a new chunk
				}

				resultArray[chunkIndex].push(item);

				return resultArray;
			}, []) || [[]],
		[data],
	);

	return (
		<List
			title={
				titleMap[level]
					? `${titleMap[level]}`
					: `Level ${level.substring(1)} emergencies`
			}
		>
			<div className="emergency-list-items">
				{mappedData.map((data, index) => (
					<div key={index} className="emergency-list-column">
						{data.map((emergencyData) => {
							const countries = getElementByKey(emergencyData, 'countries', []);
							let emergencyName = getElementByKey(
								emergencyData,
								'emergency',
								'N/A',
							);
							const opWebLink = getElementByKey(emergencyData, 'opweb_link');
							if (countries.length === 1) emergencyName = countries[0].name;

							return (
								<React.Fragment key={emergencyName}>
									<div className="emergency-name">
										{countries.length === 1 ? (
											<CountryLink country={countries[0]} />
										) : (
											<EmergencyLink
												emergencyName={emergencyName}
												opWebLink={opWebLink}
											/>
										)}
									</div>
									{countries.length === 1 ? null : (
										<div className="emergency-countries">
											{countries.map((country) => (
												<CountryLink
													country={country}
													key={country.iso_code3}
												/>
											))}
										</div>
									)}
								</React.Fragment>
							);
						})}
					</div>
				))}
			</div>
		</List>
	);
};

const DISPLAYED_EMERGENCIES_LABELS = ['L1', 'L2', 'L3', 'M'];
const IMAGE_MAP = {
	SU: CSULarge,
	CA: CALarge,
	AR: EAERLarge,
};

export const EmergencyItem = ({ data, isFull }) => {
	const level = getElementByKey(data, 'level');
	const targetBeneficiaries = getElementByKey(data, 'target_beneficiaries');
	const emergencies = getElementByKey(data, 'emergencies', []);

	const levelClass = level.replace('-', '').toUpperCase();

	const emergencyLevel = DISPLAYED_EMERGENCIES_LABELS.includes(levelClass) ? (
		levelClass
	) : (
		<img
			src={window.location.origin + IMAGE_MAP[levelClass]}
			alt={levelClass}
		/>
	);

	const emergencyValue = (
		<>
			{numberWithCommas(emergencies.length)} {isFull ? titleMap[level] : level}
		</>
	);

	const targetBeneficiariesValue = !!targetBeneficiaries && (
		<>
			{getHumanNumberFormat(targetBeneficiaries)}
			{isFull ? ' beneficiaries' : ''}
		</>
	);

	return (
		<div className="item" data-testid={`EmergencyItem_item${level}`}>
			<div className={cn('emergency-level', levelClass)}>{emergencyLevel}</div>
			<div className="emergency-values">
				<Tippy
					zIndex={2}
					maxWidth={380}
					popperOptions={{ modifiers: [{ name: 'flip', enabled: false }] }}
					placement="bottom"
					animation="fade"
					duration={200}
					interactive
					trigger="click"
					disabled={!emergencies.length}
					content={<EmergencyCountries data={emergencies} level={level} />}
				>
					<div
						className={cn('value', {
							'without-arrow': !emergencies.length,
						})}
						data-testid={`EmergencyItem_item_value_${level}`}
					>
						{emergencyValue}
					</div>
				</Tippy>
				<div>{targetBeneficiariesValue}</div>
			</div>
		</div>
	);
};

const EmergencyList = ({ data, className, loading }) => {
	if (!data) return null;

	let content = null;

	if (loading) {
		content = <LoadingBar />;
	}

	if (!loading) {
		const isFull = data.length <= 3;

		content = (
			<div
				className={cn('emergency-list__items', {
					'full-size': isFull,
				})}
			>
				{data.length ? (
					data.map((item, i) => (
						<EmergencyItem
							key={item.iso_code3 || i}
							data={item}
							isFull={isFull}
						/>
					))
				) : (
					<NoData />
				)}
			</div>
		);
	}

	return (
		<div
			className={cn(className, 'emergency-list')}
			data-testid="EmergencyList_component"
		>
			{content}
		</div>
	);
};

EmergencyList.propTypes = {
	className: PropTypes.string,
	loading: PropTypes.bool,
};

EmergencyList.defaultProps = {
	className: '',
	loading: false,
};

export default EmergencyList;
