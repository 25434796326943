import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Legend, DashLegend, NoData } from 'components/atoms';
import {
	BarIndicatorWithLabels,
	BreakdownByResidenceStatus,
} from 'components/molecules';

import useWindowSize from 'hooks/useWindowSize';

import { getElementByKey, getHumanNumberFormat, getPercents } from 'utils';

import BreakdownByRegion from './BreakdownByRegion';
import TotalSection from './TotalSection';

const Indicators = ({ data = {} }) => {
	const [width] = useWindowSize();
	const { regionCode, countryCode } = useParams();

	const chartData = getElementByKey(data, 'data', {});

	const {
		result = [],
		actual_year: actualYear,
		planned_year: plannedYear,
		quarterly_period: quarterlyPeriod,
	} = chartData;

	const {
		beneficiaries_by_region: benByRegion = [],
		beneficiaries_by_modality: modality = {},
		beneficiaries_by_residence_status: beneficiariesByResidenceStatus = {},
	} = data;

	const {
		result: modalityResult = [],
		actual_year: modalityActualYear,
		planned_year: modalityPlannedYear,
		quarterly_period: modalityQuarterlyPeriod,
	} = modality;

	const {
		result: brsResult = [],
		actual_year: brsActualYear,
		planned_year: brsPlannedYear,
		quarterly_period: brsQuarterlyPeriod,
	} = beneficiariesByResidenceStatus;

	const isGlobalLevel = !regionCode && !countryCode;

	let labelTruncateWidth = 335;

	if (width < 476) {
		labelTruncateWidth = 170;
	} else if (width < 882) {
		labelTruncateWidth = 245;
	}

	const getMaxValue = (indicators) => {
		const values = [];
		const totalValue = indicators.reduce((acc, { actual, planned }) => {
			if (actual) values.push(actual);
			if (planned) values.push(planned);
			return acc + (+planned || 0);
		}, 0);
		return [Math.max(...values), totalValue];
	};

	const [maxValue, total] = useMemo(() => getMaxValue(result), [chartData]);

	const [modalityMaxValue, modalityTotal] = useMemo(
		() => getMaxValue(modalityResult),
		[chartData],
	);

	const [brsMaxValue, brsTotal] = useMemo(
		() => getMaxValue(brsResult),
		[chartData],
	);

	const quarterlyPeriodLabel = quarterlyPeriod ? `(${quarterlyPeriod})` : '';
	const modalityPeriodLabel = modalityQuarterlyPeriod
		? `(${modalityQuarterlyPeriod})`
		: '';
	const brsPeriodLabel = brsQuarterlyPeriod ? `(${brsQuarterlyPeriod})` : '';

	const keyLabels = ['refugees', 'migrant', 'idp', 'resident', 'returnee'];

	let hasActualData = false;
	let hasQuarterlyData = false;
	result.forEach((item) => {
		if (!hasActualData) hasActualData = !!item.actual;
		if (!hasQuarterlyData) hasQuarterlyData = !!item.quarterly;
	});

	let modalityHasActualData = false;
	let modalityHasQuarterlyData = false;
	modalityResult.forEach((item) => {
		if (!modalityHasActualData) modalityHasActualData = !!item.actual;
		if (!modalityHasQuarterlyData) modalityHasQuarterlyData = !!item.quarterly;
	});

	let brsHasActualData = false;
	let brsHasQuarterlyData = false;
	brsResult.forEach((item) => {
		if (!brsHasActualData) brsHasActualData = !!item.actual;
		if (!brsHasQuarterlyData) brsHasQuarterlyData = !!item.quarterly;
	});

	const getTooltipValues = (planned, actual, quarterly) => {
		const result = [
			{
				label: `${plannedYear} Projected`,
				value: getHumanNumberFormat(planned),
			},
		];
		if (quarterly)
			result.push({
				label: `${plannedYear} Estimated actual ${quarterlyPeriodLabel}`,
				value: getHumanNumberFormat(quarterly),
			});
		if (actual)
			result.push({
				label: `${actualYear} Actual`,
				value: getHumanNumberFormat(actual),
			});

		return result;
	};

	const renderBar = ({ label, actual, planned, quarterly }, i, type) => {
		const max =
			type === 'modality'
				? modalityMaxValue
				: type === 'beneficiariesByResidence'
				? brsMaxValue
				: maxValue;
		const percents =
			type === 'modality'
				? getPercents(planned, modalityTotal)
				: type === 'beneficiariesByResidence'
				? getPercents(planned, brsTotal)
				: getPercents(planned, total);
		return (
			<BarIndicatorWithLabels
				key={`beneficiaries-panel-bar-${label}-${i}`}
				label={label}
				percents={percents}
				tooltipData={{
					values: getTooltipValues(planned, actual, quarterly),
					label,
				}}
				previous={
					actual
						? [
								{
									label,
									value: actual,
									length: getPercents(actual, max),
									borderStyle: 'solid',
								},
						  ]
						: []
				}
				length={getPercents(planned, max)}
				value={planned}
				thirdValue={quarterly}
				thirdPercents={getPercents(quarterly, max)}
				percentsEmptyValue="0%"
				truncateWidth={labelTruncateWidth}
				withTooltip
			/>
		);
	};

	const PlannedLegend = ({ type }) => (
		<Legend
			lines={['actual']}
			labelMap={{
				actual: {
					label: `${
						type === 'modality'
							? modalityPlannedYear
							: type === 'beneficiariesByResidence'
							? brsPlannedYear
							: plannedYear
					} Projected`,
				},
			}}
			colorScale={['#0780C0']}
		/>
	);

	const ActualLegend = ({ type }) => (
		<DashLegend
			legend={[
				{
					label: `${
						type === 'modality'
							? modalityActualYear
							: type === 'beneficiariesByResidence'
							? brsActualYear
							: actualYear
					} Actual`,
					borderStyle: 'solid',
				},
			]}
		/>
	);

	const QuarterlyLegend = ({ type }) => (
		<Legend
			lines={['actual']}
			labelMap={{
				actual: {
					label: `${
						type === 'modality'
							? modalityPlannedYear
							: type === 'beneficiariesByResidence'
							? brsPlannedYear
							: plannedYear
					} Estimated actual ${
						type === 'modality'
							? modalityPeriodLabel
							: type === 'beneficiariesByResidence'
							? brsPeriodLabel
							: quarterlyPeriodLabel
					}`,
				},
			}}
			colorScale={['#9DEAFFFF']}
		/>
	);

	const programmeAreas = (
		<>
			<div className="wrap">
				<div className="title">
					Beneficiaries by programme area
					<div className="title__subtitle">
						In number of beneficiaries and % of total (includes overlap between
						programme areas)
					</div>
				</div>

				<div className="indicators">
					{!result.length && <NoData />}
					{result.map(renderBar)}
				</div>
			</div>

			{!!result.length && (
				<div className="beneficiaries-panel__legend">
					<PlannedLegend type="programme-areas" />
					{hasQuarterlyData ? <QuarterlyLegend type="programme-areas" /> : null}
					{hasActualData ? <ActualLegend type="programme-areas" /> : null}
				</div>
			)}
		</>
	);

	return (
		<>
			<TotalSection
				data={chartData.total}
				plannedLabel={<PlannedLegend type="programme-areas" />}
				actualLabel={<ActualLegend type="programme-areas" />}
				quarterlyLabel={<QuarterlyLegend type="programme-areas" />}
			/>
			{!isGlobalLevel && (
				<div className="programme-area-chart programme-areas-region-country">
					{programmeAreas}
				</div>
			)}
			<div className="beneficiaries-panel__wrap">
				<div className="beneficiaries-panel__main-content">
					{isGlobalLevel && (
						<div className="programme-area-chart programme-areas-global">
							{programmeAreas}
						</div>
					)}
					<div className="modality-chart">
						<div className="wrap">
							<div className="title">
								Beneficiaries by modality
								<div className="title__subtitle">
									In number of beneficiaries and % of total (includes overlap
									between modalities)
								</div>
							</div>

							<div className="indicators">
								{!modalityResult.length && <NoData />}
								{modalityResult.map((indicator, index) =>
									renderBar(indicator, index, 'modality'),
								)}
							</div>
						</div>

						{!!modalityResult.length && (
							<div className="beneficiaries-panel__legend">
								<PlannedLegend type="modality" />
								{modalityHasQuarterlyData ? (
									<QuarterlyLegend type="modality" />
								) : null}
								{modalityHasActualData ? (
									<ActualLegend type="modality" />
								) : null}
							</div>
						)}
					</div>
				</div>

				<div className="projected-charts">
					{isGlobalLevel && (
						<div className="projected-charts__by-region">
							<div className="title">Projected beneficiaries by region</div>
							<BreakdownByRegion
								indicators={benByRegion}
								total={chartData.total.planned}
							/>
						</div>
					)}

					{isGlobalLevel && (
						<div>
							<Legend
								lines={keyLabels}
								orientation="horizontal"
								labelMap={{
									refugees: { label: `Refugees` },
									migrant: { label: `Migrants` },
									tdp: { label: `TDPs` },
									idp: { label: `IDPs` },
									non_refugees: { label: `Non-Refugees` },
									resident: { label: `Residents` },
									returnee: { label: `Returnees` },
									other: 'Other',
								}}
								colorScale={[
									'#0780C0',
									'#73C3FE',
									'#9DEAFF',
									'#C8EEF7',
									'#D6DDED',
								]}
							/>
						</div>
					)}

					<div className="projected-charts__residence-status">
						<div className="title">Beneficiaries by residence status</div>
						<div className="indicators">
							{!brsResult.length && <NoData />}
							{brsResult.map((indicator, index) =>
								renderBar(indicator, index, 'beneficiariesByResidence'),
							)}
						</div>
					</div>

					{!!brsResult.length && (
						<div className="beneficiaries-panel__brs-legend">
							<PlannedLegend type="beneficiariesByResidence" />
							{brsHasQuarterlyData ? (
								<QuarterlyLegend type="beneficiariesByResidence" />
							) : null}
							{brsHasActualData ? (
								<ActualLegend type="beneficiariesByResidence" />
							) : null}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Indicators;
