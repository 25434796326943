import React from 'react';

type NoDataProps = {
	height?: number;
	className?: string;
	text?: string;
};

const NoData = ({
	height,
	className = '',
	text = 'no data available',
}: NoDataProps) => (
	<div
		className={`no-data${className || ''}`}
		style={{
			height: height ? `${height}px` : undefined,
		}}
	>
		{text}
	</div>
);

export default NoData;
