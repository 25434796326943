import React from 'react';

import { BannerNavigation, BannerNavigationItem, Link } from '@wfp/ui';

import Menu from './Menu';

const SideLink = ({ link, label }) => (
	<BannerNavigationItem>
		<Link href={link} target="_blank">
			{label}
		</Link>
	</BannerNavigationItem>
);

const Header = () => (
	<div className="header-container">
		<BannerNavigation pageWidth="lg">
			<SideLink label="WFPgo" link="https://go.wfp.org/" />
			<SideLink label="Communities" link="https://communities.wfp.org/" />
			<SideLink label="Manuals" link="https://manuals.wfp.org/" />
			<SideLink label="GoDocs" link="https://godocs.wfp.org/" />
			<SideLink label="WeLearn" link="https://welearn.wfp.org/" />
			<SideLink label="OpWeb" link="https://opweb.wfp.org/" />
			<SideLink label="Self-Service" link="https://selfservice.go.wfp.org/" />
			<SideLink
				label="WeTravel"
				link="https://erpapps.wfp.org/fiori#ZWETRAVEL-createTravelReq"
			/>
			<SideLink
				label="UN Booking Hub"
				link="https://humanitarianbooking.wfp.org/"
			/>
			<SideLink label="WFP.org" link="https://wfp.org/" />
		</BannerNavigation>
		<Menu />
	</div>
);

export default Header;
