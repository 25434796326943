import React from 'react';

import { useOutputIndicators } from 'api';

import landRehabilitated from 'assets/LAND REHABILITATED transparent half.png';
import forestPlanted from 'assets/FOREST PLANTED - transparent half.png';
import waterPonds from 'assets/WATER PONDS transparent half.png';
import gardenEstablished from 'assets/GARDENS ESTABLISHED transparent half.png';
import culvertsAndDrainage from 'assets/CULVERTS AND DRAINAGE transparent half.png';
import bridges from 'assets/BRIDGES transparent half.png';
import feederRoads from 'assets/FEEDER ROADS transparent half.png';
import irrigationCanals from 'assets/IRRIGATION CANALS transparent half.png';

import { InfoSource } from 'components/atoms';
import { ChartIndicatorsPanel } from 'components/templates';

import { getElementByKey } from 'utils';

import Card from './Card';
import TableIndicators from './TableIndicators';

const imageMap = {
	rehabilitated: landRehabilitated,
	forests_planted: forestPlanted,
	water_points: waterPonds,
	community: gardenEstablished,
	culverts: culvertsAndDrainage,
	bridges,
	roads: feederRoads,
	canals: irrigationCanals,
};

const CardIndicators = ({ data = [], imageMap }) => (
	<div className="output-indicators-cards">
		{data.map((item, i) => (
			<Card
				activeIndexes={[0]}
				title={item.title}
				description={item.description}
				data={item.data}
				image={imageMap[item.key]}
				areValuesNumber
				key={`output-indicators-${item.title}`}
			/>
		))}
	</div>
);

export const PANEL_KEY = 'output-indicators';

const OutputIndicatorsPanel = () => {
	const { data, isLoading } = useOutputIndicators({
		queryKey: [PANEL_KEY],
	});

	const { output_indicators: outputIndicators = [] } = data || {};

	const infoData = getElementByKey(data, 'info');

	return (
		<ChartIndicatorsPanel
			className="output-indicators"
			title="Output indicators"
			panelKey={PANEL_KEY}
			allowFullExcelExport={false}
			loading={isLoading}
			noData={
				!data || !data.output_indicators || !data.output_indicators.length
			}
			indicatorsOnly
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			indicatorsComponent={
				<>
					<TableIndicators
						data={outputIndicators}
						withImages
						imageMap={imageMap}
					/>
					<CardIndicators data={outputIndicators} imageMap={imageMap} />
				</>
			}
		/>
	);
};

export default OutputIndicatorsPanel;
