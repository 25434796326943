/*
Styles: scss/v2/components/charts/doughnut-legend.scss
 */

import React from 'react';
import PropTypes from 'prop-types';

import { getHumanNumberFormat, getPercents } from 'utils';

const DoughnutLegend = ({
	data,
	isSorted,
	valueText,
	colorScale,
	showSubTitle,
}) => {
	if (!data && !data.length) return null;

	let sortedData = data;
	if (isSorted) sortedData = data.sort((a, b) => b.y - a.y);

	const totalValue = sortedData.reduce((a, b) => a + (b.y || 0), 0);
	const items = sortedData.map((item, key) => {
		const title = item.x;

		let keyName;

		if (title) keyName = title.replace(' ', '-').toLocaleUpperCase();

		// prepare percent values
		const percentValue = getPercents(item.y, totalValue || 1, 1);

		// prepare title values
		const humanValue = getHumanNumberFormat(item.y);
		let numberValue = '';
		if (valueText && humanValue) numberValue = `(${humanValue} ${valueText})`;

		const subTitle = `${numberValue}`;

		// prepare color
		let color = '';
		if (key + 1 <= colorScale.length) color = colorScale[key];

		if (!title) return null;
		return (
			<div key={keyName} className="doughnut-legend__item">
				<div className="color-sector" style={{ backgroundColor: color }} />
				<div className="title-sector">
					<div className="title-sector__title">
						{title} <br />
						{percentValue}%
						<span className="title-sector__subtitle">
							{' '}
							{showSubTitle ? subTitle : ''}
						</span>
					</div>
				</div>
			</div>
		);
	});

	return <div className="doughnut-legend">{items}</div>;
};

DoughnutLegend.propTypes = {
	data: PropTypes.array,
	valueText: PropTypes.string,
	colorScale: PropTypes.array,
	isSorted: PropTypes.bool,
	showSubTitle: PropTypes.bool,
};

DoughnutLegend.defaultProps = {
	data: [],
	valueText: 'USD',
	colorScale: ['#608BA3', '#80B2CF', '#A5CADE', '#D1E3ED'],
	isSorted: true,
	showSubTitle: true,
};

export default DoughnutLegend;
