import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getNgoPartners = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_PARTNERS;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_PARTNERS}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_PARTNERS}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useNgoPartners = ({ countryCode, regionCode }, options) =>
	useQuery({
		queryFn: () => getNgoPartners({ countryCode, regionCode }),
		...options,
	});
