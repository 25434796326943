import { useQuery } from '@tanstack/react-query';

import config from 'config';

import { api } from './base';

const getRegionalBureaus = async () =>
	api.get(config.API_V2_URLS.GLOBAL_REGIONAL_BUREAUS);

export const useRegionalBureaus = (options) =>
	useQuery({
		queryFn: getRegionalBureaus,
		...options,
	});
