import React from 'react';
import PropTypes from 'prop-types';

const DashLegend = ({ legend }) => (
	<div className="dash-legend">
		{legend.map(({ borderStyle, label }) => (
			<div
				className="dash-legend__item"
				key={`${label}-${borderStyle}-dash-legend`}
			>
				<div className="dash-legend__sign" style={{ borderStyle }} />
				<span className="dash-legend__label">{label}</span>
			</div>
		))}
	</div>
);

DashLegend.propTypes = {
	legend: PropTypes.arrayOf(
		PropTypes.shape({
			borderStyle: PropTypes.string,
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	),
};

export default DashLegend;
