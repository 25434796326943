import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getFocusArea = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_FOCUS_AREAS;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_FOCUS_AREAS}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_FOCUS_AREAS}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useFocusArea = ({ countryCode, regionCode }, options) =>
	useQuery({
		queryFn: () => getFocusArea({ countryCode, regionCode }),
		...options,
	});
