import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';

import { Search as SearchInput } from '@wfp/ui';

import { buildLink } from 'components/molecules/ExtLink';

import { gaSearchUsingEvent } from 'anatytics';

import { replaceDiacritic } from 'utils';

export function escapeRegexCharacters(str) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function getSuggestionValue(suggestion) {
	return `${suggestion.name}`;
}

export function renderSuggestion(suggestion, { query }) {
	const suggestionText = `${suggestion.name}`;
	const isEmpty = suggestion.type === 'empty-results';
	const matches = AutosuggestHighlightMatch(suggestionText, query);
	const parts = AutosuggestHighlightParse(suggestionText, matches);

	let contentClass = 'suggestion__content';
	if (isEmpty) {
		contentClass += ' suggestion__content-empty';
	}

	return (
		<div className={contentClass}>
			<span className="name">
				{parts.map((part, index) => {
					const className = part.highlight && !isEmpty ? 'highlight' : null;

					return (
						<span className={className} key={index}>
							{part.text}
						</span>
					);
				})}
			</span>
			{suggestion.long_name && (
				<span className="suggestion__small">{suggestion.long_name}</span>
			)}
		</div>
	);
}

export const getSectionSuggestions = (section) => section.content;

export const renderSectionTitle = (section) =>
	!!section.title && <strong>{section.title}</strong>;

export const getSuggestions = (value, source) => {
	const escapedValue = replaceDiacritic(escapeRegexCharacters(value.trim()));

	if (escapedValue === '') {
		return [];
	}

	const regex = new RegExp(`\\b${escapedValue}`, 'i');

	return [
		...source,
		{
			title: '',
			content: [{ type: 'global', name: 'Global' }],
		},
	]
		.map((section) => ({
			title: section.title,
			content: section.content.filter((singleContent) =>
				regex.test(replaceDiacritic(singleContent.name)),
			),
		}))
		.filter(
			(section) => section.content.length > 0 || section.type === 'global',
		);
};

const Search = ({ source: sourceProp, searchId }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const [value, setValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [source, setSource] = useState({});

	const onChange = (event, { newValue, method }) => {
		setValue(newValue);
	};

	const onSuggestionsFetchRequested = useCallback(
		({ value }) => {
			setSuggestions(getSuggestions(value, source));
		},
		[source],
	);

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
		setValue('');
	};

	// When Suggestions Selected Jump to Page
	// TODO: Open Glossary if Glossary Item
	const onSuggestionSelected = (event, { suggestion }) => {
		const pathName = location.pathname;

		let tab = 'overview';
		if (pathName.includes('planvsactuals')) tab = 'planvsactuals';
		else if (pathName.includes('resources')) tab = 'resources';

		if (suggestion.type === 'global') {
			return navigate(`/${tab}`);
		}
		if (suggestion.type === 'empty-results') {
			return;
		}

		const link = buildLink(
			suggestion.type,
			suggestion.code,
			suggestion.to,
			suggestion.url,
		);

		if (link.external === true)
			setTimeout(() => {
				window.location.href = `${link.url}/${tab}`;
			}, 300);
		else navigate(`${link.route}/${tab}`);
	};

	useEffect(() => {
		const suggestionsArray = [];
		if (!sourceProp) return null;
		const keysToSearch = ['regions', 'countries'];
		const keyTitleMap = {
			regions: 'Regions',
			countries: 'Countries',
		};
		keysToSearch.forEach((key) => {
			const arrayEl = {
				key,
				title: keyTitleMap[key],
				content: sourceProp[key].map((element) => ({
					...element,
					type: key,
				})),
			};
			suggestionsArray.push(arrayEl);
		});

		setSource(suggestionsArray);
	}, [sourceProp, searchId, location]);

	const renderInputComponent = ({ ref, onChange, ...otherProps }) => (
		<SearchInput
			inputRef={ref}
			kind="main"
			labelText=""
			placeholdertext="Search"
			id={searchId}
			light={false}
			onChange={(value, event) => onChange(event)}
			{...otherProps}
		/>
	);

	const inputProps = {
		placeholder: 'Search',
		value,
		onChange,
		onFocus: () => gaSearchUsingEvent('Main'),
	};

	return (
		<div
			acceptCharset="UTF-8"
			className="search-toggle quicksearch quicksearch--small"
		>
			<Autosuggest
				suggestions={
					suggestions.length
						? suggestions
						: [
								{
									type: 'empty-results',
									content: [
										{ name: 'No matches found', type: 'empty-results' },
									],
								},
						  ]
				}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				onSuggestionSelected={onSuggestionSelected}
				focusInputOnSuggestionClick={false}
				renderSectionTitle={renderSectionTitle}
				getSectionSuggestions={getSectionSuggestions}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				renderInputComponent={renderInputComponent}
				multiSection
				inputProps={inputProps}
			/>
		</div>
	);
};

Search.propTypes = {
	searchId: PropTypes.string,
};

Search.defaultProps = {
	searchId: 'header-search-id',
};

export default Search;
