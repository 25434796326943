import React from 'react';

import { useLocalEconomies } from 'api';

import { InfoSource } from 'components/atoms';
import { ChartIndicatorsPanel } from 'components/templates';

import { getElementByKey } from 'utils';

import LocalEconomiesChart from './LocalEconomiesChart';

export const PANEL_KEY = 'local-economies';

const LocalEconomiesPanel = ({ countryCode, regionCode, reference }) => {
	const { data, isLoading } = useLocalEconomies(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const infoData = getElementByKey(data, 'info');

	return (
		<ChartIndicatorsPanel
			title={
				data?.year
					? `Supporting local economies (${data.year})`
					: 'Supporting local economies'
			}
			className="local-economies-panel"
			panelKey={PANEL_KEY}
			noData={
				!data?.cbt_and_cv.find(({ value }) => !!value) &&
				!data?.food.find(({ value }) => !!value) &&
				!data?.local_transportation.find(({ value }) => !!value) &&
				!data?.local_salaries.find(({ value }) => !!value)
			}
			chart={<LocalEconomiesChart data={data} reference={reference} />}
			chartOnly
			loading={isLoading}
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			horizontal
		/>
	);
};

export default LocalEconomiesPanel;
