import React from 'react';

import { Footer as FooterEl } from '@wfp/ui';

import Documents from './Documents';
import Links from './Links';
import MobileApps from './MobileApps';

const Footer = () => (
	<FooterEl className="footer" pageWidth="lg">
		<Links />
		<Documents />
		<MobileApps />
	</FooterEl>
);

export default Footer;
