import React, { useMemo } from 'react';

import { Tooltip } from 'react-tippy';

import { Table } from '@wfp/ui';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat } from 'utils';

const TableIndicators = ({
	data = [],
	areValuesNumber = true,
	withImages = false,
	imageMap,
}) => {
	const rows = useMemo(() => {
		const temp = {};
		data.forEach((item) => {
			item.data.forEach(({ label, value }) => {
				if (!temp[label]) temp[label] = [{ title: item.title, value }];
				else temp[label].push({ title: item.title, value });
			});
		});
		return temp;
	}, [data]);

	const entries = Object.entries(rows);
	const array = [...entries.reverse()];

	const DescriptionTooltip = ({ description, children }) => (
		<Tooltip
			{...tooltipStyle}
			trigger="click"
			position="top"
			interactive
			style={{ display: 'block' }}
			disabled={!description}
			html={
				<div
					className="wfp-tooltip__markup"
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			}
			className={`wfp-tooltip wfp-tooltip-v2 ${
				!description ? 'wfp-tooltip-disabled' : ''
			}`}
			title=" "
		>
			{children}
		</Tooltip>
	);

	return (
		<Table>
			<thead>
				{withImages && (
					<tr className="table-images">
						<th />
						{data.map(({ title, key, description }) => (
							<th
								key={`table-image-${title}`}
								className={`table-column-title ${
									!description ? 'table-column-title-disabled' : ''
								}`}
							>
								<div className="table-column-title__inner">
									<DescriptionTooltip description={description}>
										<div>
											<img
												alt="loading..."
												src={imageMap[key]}
												className="table-image"
											/>
											<p>{title}</p>
										</div>
									</DescriptionTooltip>
								</div>
							</th>
						))}
					</tr>
				)}
			</thead>
			<tbody>
				{array.map(([key, values = []], i) => (
					<tr
						className={i === 0 ? 'active' : ''}
						key={`table-row-${key}-${values[0]}`}
					>
						<th className="year">{key}</th>
						{values.map(({ title, value }) => (
							<th key={`table-data-${key}-${title}-${value}`}>
								{areValuesNumber ? getHumanNumberFormat(value || '-') : value}
							</th>
						))}
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default TableIndicators;
