import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	ImplementationPanel,
	StrategicResultsPanel,
	SDGTargetsPanel,
	SOCategoriesPanel,
	FoodDistributionPanel,
	FocusAreasPanel,
	PartnersPanel,
} from 'components/organisms';

import { useScrollOnTabChange } from 'hooks';

import { selectCurrentYear, selectIsAdvanced } from 'features/global/selectors';

const PlanPage = () => {
	const navigate = useNavigate();
	const { countryCode, regionCode } = useParams();

	const isAdvancedMode = useSelector(selectIsAdvanced);
	const currentYear = useSelector(selectCurrentYear);

	useScrollOnTabChange();

	useEffect(() => {
		if (!isAdvancedMode) {
			navigate('/overview');
		}
	}, []);

	return (
		<>
			<div className="main-dashboard__panels">
				<PartnersPanel countryCode={countryCode} regionCode={regionCode} />
			</div>
			<div className="main-dashboard__panels">
				<SOCategoriesPanel countryCode={countryCode} regionCode={regionCode} />
			</div>
			<div
				className="main-dashboard__panels main-dashboard__panels-right"
				data-testid="OperationTab_main_dashboard__panels"
			>
				<ImplementationPanel
					countryCode={countryCode}
					regionCode={regionCode}
				/>
				{currentYear >= 2023 ? (
					<SDGTargetsPanel countryCode={countryCode} regionCode={regionCode} />
				) : (
					<StrategicResultsPanel
						countryCode={countryCode}
						regionCode={regionCode}
					/>
				)}
			</div>

			<div
				className="main-dashboard__panels main-dashboard__panels-right"
				data-testid="OperationTab_big_panel"
			>
				<FoodDistributionPanel
					countryCode={countryCode}
					regionCode={regionCode}
				/>
				<FocusAreasPanel countryCode={countryCode} regionCode={regionCode} />
			</div>
		</>
	);
};

export default PlanPage;
