import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	menu: {
		regions: [],
		countries: [],
		donors: [],
		partners: [],
	},
	config: {
		version: null,
		CURRENT_YEAR: '2023',
	},
	userData: {
		config: {
			favorites: [],
			is_advanced: true,
		},
	},
	loading: false,
};

const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setUserConfig: (state, action) => {
			const { config } = action.payload;
			state.userData = { ...state.userData, config };
		},
		setGlobal: (state, action) => {
			const { menu, config, userData } = action.payload;

			return {
				...state,
				menu,
				config,
				userData,
			};
		},
	},
});

export const { setUserConfig, setGlobal } = globalSlice.actions;

export default globalSlice.reducer;
