import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@wfp/ui';
import { iconWfpHumEmergencyResponsePos } from '@wfp/icons';

import colors from 'constants/colors';

const ErrorPage = () => (
	<div className="error-page">
		<Icon
			icon={iconWfpHumEmergencyResponsePos}
			width="200"
			className="error-page__icon"
			height="200"
			fill={colors.blueIcon}
		/>
		<h1 className="error-page__title">
			Sorry, we couldn&apos;t find that page
		</h1>
		<span className="error-page__link">
			Go back to <Link to="/overview">Homepage</Link>
		</span>
	</div>
);

export default ErrorPage;
