import React from 'react';
import PropTypes from 'prop-types';

const OneLineIndicator = ({
	className,
	label,
	value,
	postValueSymbol,
	children,
}) => (
	<>
		<div
			data-testid="DirectorIndicatorTestID"
			className={`one-line-indicator ${className}`}
			key={`indicator__${label}`}
		>
			<div className="label">{label}</div>
			<div className="value">
				{value || ''}
				{value ? postValueSymbol : ''}
			</div>
		</div>
		{children}
	</>
);

OneLineIndicator.propTypes = {
	className: PropTypes.string,
	label: PropTypes.any,
	value: PropTypes.any,
};

OneLineIndicator.defaultProps = {
	className: '',
	label: '',
	value: '',
};

export default OneLineIndicator;
