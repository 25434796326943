import React from 'react';

import { InlineLoading, Loading } from '@wfp/ui';

const LoadingBar = ({
	isLoading = true,
	isSmall = true,
	text = 'Loading data...',
}) => {
	if (!isLoading) {
		return null;
	}

	return isSmall ? (
		<InlineLoading
			data-testid="inline-loading-bar"
			description={text}
			success={false}
		/>
	) : (
		<div data-testid="loading-bar" className="loading__container">
			<Loading withOverlay={false} />
		</div>
	);
};

export default LoadingBar;
