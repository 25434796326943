import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getImplementation = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_IMPLEMENTATION;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_IMPLEMENTATION}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_IMPLEMENTATION}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useImplementation = ({ countryCode, regionCode }, options) =>
	useQuery({
		queryFn: () => getImplementation({ countryCode, regionCode }),
		...options,
	});
