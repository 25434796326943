import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getGlobalHeaderBeneficiaries = async () =>
	api.get(appConfig.API_V2_URLS.GLOBAL_HEADER_BENEFICIARIES);

export const useGlobalHeaderBeneficiaries = (options) =>
	useQuery({
		queryFn: () => getGlobalHeaderBeneficiaries(),
		...options,
	});
