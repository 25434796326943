import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';

import { selectCurrentYear, selectIsAdvanced } from 'features/global/selectors';
import { selectTitle } from 'features/office/selectors';

import { pdfStyles } from 'hooks/useContentRefLayout/pdf';

const useContentRefLayout = ({
	loading,
	title,
	contentRef,
	infoText,
	withMarkupRender = true,
	additionalMarkup = '',
}) => {
	const [html, setHtml] = useState('');
	const year = useSelector(selectCurrentYear);
	const isAdvancedMode = useSelector(selectIsAdvanced);
	const officeName = useSelector(selectTitle);

	useEffect(() => {
		if (!loading) {
			if (contentRef.current) {
				let layout = `
        <!DOCTYPE html>
        <html lang="en">
          <head>
              <meta charset="UTF-8" />
              <title>${title}</title>
              <style>
                  ${pdfStyles}
              </style>
          </head>
        <body>
        <div class="content-wrap">
          <div class="content">
            <div class="wfp--wrapper wfp--wrapper--width-lg">
               ${additionalMarkup}
              <div class="main-dashboard__panels">
                  ${contentRef.current.outerHTML}
              </div>
            </div>
            <div class="pdf-source-info">
              ${
								withMarkupRender
									? ReactDOMServer.renderToString(infoText)
									: infoText
							}
            </div>
          </div>
          </div>
        </body>
        </html>`;
				layout = layout.replace(
					'<h1 class="wfp--module__title">',
					`
            <h1 class="wfp--module__title">
                <div>${title} - ${officeName}</div>
            </h1>
            <h1 class="wfp--module__title" style="display: none;">
        `,
				);
				setHtml(layout);
			}
		}
	}, [loading, contentRef, year, isAdvancedMode, officeName]);

	return {
		html,
		pdfEnabled: !!year && !loading,
	};
};

export default useContentRefLayout;
