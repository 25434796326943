function getElementByKey(obj, key, _default = undefined) {
	if (
		obj &&
		((Object && Object.hasOwn && Object.hasOwn(obj, key)) ||
			(!Object.hasOwn && Object.prototype.hasOwnProperty.call(obj, key)))
	)
		return obj[key];
	return _default;
}

export default getElementByKey;
