import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { api } from 'api';

import { setUserConfig } from 'features/global/globalSlice';

import config from 'config';

export const buildLink = (type, index, to, url, external = false) => {
	const link = {};
	let extUrl = url;
	link.external = external;

	if (to) {
		link.route = to;
		extUrl = `${to}`;
		link.route = `${to}`;
	} else {
		switch (type) {
			case 'links':
				extUrl = url;
				link.route = `${url}`;
				break;

			default:
				extUrl = `/${type}/${index}`;
				link.route = `/${type}/${index}`;
		}
	}
	link.url = extUrl;
	return link;
};

const mapStateToProps = (state) => ({
	favorites: state.global.userData.config.favorites,
	isAdvancedMode: state.global.userData.config.is_advanced,
});

class FavButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			animate: false,
		};
	}

	findFavorite = (favoriteItem) => {
		const { index } = this.props;
		return favoriteItem === index;
	};

	toggleFavorite = async (isFavorite) => {
		const { favorites, index, isAdvancedMode, dispatch } = this.props;

		try {
			let _favorites = [...favorites];
			if (isFavorite) _favorites = _favorites.filter((code) => code !== index);
			else _favorites = [...favorites, index];
			const userConfig = {
				favorites: _favorites,
				is_advanced: isAdvancedMode,
			};
			dispatch(setUserConfig({ config: userConfig }));
			await api.post(config.API_V2_URLS.CONFIG, 'post', userConfig);
		} catch (e) {
			console.log('toggle favorites error ===', e);
		}
	};

	addAnimation = () => {
		this.setState({
			animate: true,
		});
		setTimeout(() => {
			this.setState({ animate: false });
		}, 3000);
	};

	render() {
		const { favorites } = this.props;
		const { animate } = this.state;

		const isFavorite = favorites.find(this.findFavorite);
		const favClass = cn({
			favorite: true,
			active: isFavorite,
			animate: animate === true,
		});

		return (
			<span
				className={favClass}
				onClick={(e) => {
					e.preventDefault();
					this.toggleFavorite(isFavorite);
					this.addAnimation();
				}}
			>
				<span className="hint">
					{!isFavorite ? 'Add to favorites' : 'Remove from favorites'}
				</span>
			</span>
		);
	}
}

FavButton = connect(mapStateToProps)(FavButton);

const ExtLink = ({
	type,
	index,
	to: toProp,
	url,
	external,
	favorite,
	exact,
	activeClassName,
	subTab,
	gaEvent,
	target,
	children,
}) => {
	const link = buildLink(type, index, toProp, url, external);
	let to = link.route;
	if (subTab) to = `${to}/${subTab}`;

	return (
		<span
			className="link__wrapper"
			onClick={() => {
				if (gaEvent) {
					gaEvent();
				}
			}}
		>
			{link.external === true ? (
				<a href={link.url} target={target}>
					{children}
				</a>
			) : (
				<NavLink
					className={({ isActive }) =>
						cn('wfp--link', { [activeClassName || 'active']: isActive })
					}
					to={to}
				>
					{children}
				</NavLink>
			)}
			{favorite && <FavButton to={link} index={index} type={type} />}
		</span>
	);
};

ExtLink.propTypes = {
	external: PropTypes.bool,
};

ExtLink.defaultProps = {
	external: false,
};

export default ExtLink;
