import React, { useEffect, useRef } from 'react';

import { Wrapper } from '@wfp/ui';

import mobileImage from 'assets/mobile-image.png';
import qrCode from 'assets/QR CODE.png';
import mobile2 from 'assets/mobile2.png';
import mobile3 from 'assets/mobile3.png';
import mobile4 from 'assets/mobile4.png';
import mobile5 from 'assets/mobile5.png';

import GooglePlayLink from 'components/organisms/Footer/MobileApps/GooglePlayLink';

import Step from './Step';

const Mobile = () => {
	const personalPhoneRef = useRef(null);
	const corporatePhoneRef = useRef(null);

	const scrollToPersonalPhone = () =>
		personalPhoneRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	const scrollToCorporatePhone = () =>
		corporatePhoneRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="mobile">
			<div className="mobile__header">
				<Wrapper pageWidth="lg" className="mobile__header-inner">
					<div className="mobile__header-main">
						<h1 className="mobile__header-main__title">
							The WFP Dashboard in your pocket
						</h1>
						<p className="mobile__header-main__desc">
							The mobile app of the WFP Dashboard is available for both iOS and
							Android devices. To install the app on your personal or corporate
							iOS device (iPhone/iPad), please follow the instructions below.
							<br />
							If you have an Android device instead, get the app straight from
							the Google Play store.
						</p>
						<div className="mobile__header-main__links">
							<span
								data-testid="scroll-personal-phone"
								onClick={scrollToPersonalPhone}
								className="mobile__header-main__link"
							>
								Personal iPhone/iPad
							</span>
							<span
								data-testid="scroll-corporate-phone"
								onClick={scrollToCorporatePhone}
								className="mobile__header-main__link"
							>
								Corporate iPhone/iPad
							</span>
							<GooglePlayLink />
						</div>
					</div>
					<img
						src={mobileImage}
						alt="loading"
						className="mobile__header-image"
					/>
				</Wrapper>
			</div>
			<Wrapper pageWidth="lg" className="mobile__main">
				<div className="mobile__main-section">
					<h4 className="mobile__main-label" ref={personalPhoneRef}>
						Install on your personal iPhone/iPad
					</h4>
					<div>
						<Step
							step={1}
							label="Download the app from your device"
							descriptionList={[
								[
									'Scan the',
									{ text: ' QR code ', isBold: true },
									'with your device to open the installation page or ',
									{
										text: ' open it from this link',
										isLink: true,
										href: 'https://install.appcenter.ms/orgs/wfp/apps/wfp-dashboard/distribution_groups/public',
									},
									'.',
								],
								[
									'Tap on the “',
									{ text: 'INSTALL', isBold: true },
									'” button and confirm installation.',
								],
							]}
							imageClass="qr"
							imageProps={{
								name: 'qr-code',
							}}
							image={qrCode}
						/>
						<Step
							step={2}
							label="Open the app"
							descriptionList={[
								[
									'Try launching the',
									{ text: ' WFP Dashboard ', isBold: true },
									'app.',
								],
								[
									'If the app opens up successfully, the installation is complete - enjoy the WFP Dashboard on your device!',
								],
								[
									'If, on the contrary, you get this',
									{ text: ' “Untrusted Enterprise Developer” ', isBold: true },
									'message, tap',
									{ text: ' “Cancel” ', isBold: true },
									'and continue to',
									{ text: ' Step 3.', isBold: true },
								],
							]}
							image={mobile2}
						/>
						<Step
							step={3}
							label="Add as trusted app"
							descriptionList={[
								[
									'Go to',
									{
										text: ' Settings - General - Device Management.',
										isBold: true,
									},
								],
								[
									'Select',
									{
										text: ' "United Nations World Food Programme".',
										isBold: true,
									},
								],
								['Tap on the', { text: ' “Trust” ', isBold: true }, 'button.'],
								[
									'Launch the',
									{ text: ' WFP Dashboard ', isBold: true },
									'app again and enjoy it!',
								],
							]}
							image={mobile3}
						/>
					</div>
				</div>
				<div className="mobile__main-section">
					<h4 className="mobile__main-label" ref={corporatePhoneRef}>
						Install on a corporate iPhone/iPad
					</h4>
					<div className="mobile__main-steps">
						<Step
							step={1}
							label="Enroll your device into InTune Company Portal"
							descriptionList={[
								[
									'Check that Microsoft’s',
									{
										text: ' InTune Company Portal ',
										isBold: true,
									},
									'app is installed on your device. If not, download it from ',
									{
										text: 'Apple’s App Store',
										isLink: true,
										href: 'https://apps.apple.com/us/app/intune-company-portal/id719171358',
									},
									'.',
								],
								[
									'If not already enrolled, enroll your device into',
									{
										text: ' InTune Company Portal ',
										isBold: true,
									},
									'by following the ',
									{
										text: 'instructions available here',
										isLink: true,
										href: 'https://newgo.wfp.org/how-do-i/enrol-my-apple-device-intune',
									},
									'.',
								],
							]}
							image={mobile4}
							imageNeedsFill
						/>
						<Step
							step={2}
							label="Open InTune and install the app"
							descriptionList={[
								[
									'Open the',
									{
										text: ' InTune ',
										isBold: true,
									},
									'app.',
								],
								[
									'Select the',
									{
										text: ' “Apps” tab ',
										isBold: true,
									},
									'where you will find apps such as Acrobat reader, Tableau Mobile and WinZip as well as WFP in-house developed apps.',
								],
								[
									'Most recent apps are displayed first. Tap on',
									{
										text: ' “View all Apps” ',
										isBold: true,
									},
									'to view the complete list.',
								],
								[
									'Select',
									{
										text: ' WFP Dashboard ',
										isBold: true,
									},
									'and confirm by tapping on',
									{
										text: ' “Install”.',
										isBold: true,
									},
								],
								[
									'Launch the',
									{
										text: '  WFP Dashboard ',
										isBold: true,
									},
									'app and enjoy it!',
								],
							]}
							image={mobile5}
							imageNeedsFill
						/>
					</div>
					<div className="mobile__help">
						<h1 className="mobile__help__title">Any help needed?</h1>
						<p className="mobile__help__desc">
							If you are facing any problem in downloading and/or installing the
							app on your devices, please send us an email and we will get back
							to you to support.
						</p>
						<a
							href="mailto:wfp.dashboard@wfp.org"
							className="mobile__help__link"
						>
							Contact us
						</a>
					</div>
				</div>
			</Wrapper>
		</div>
	);
};

export default Mobile;
