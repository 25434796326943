import React from 'react';

import { HorizontalIndicator } from 'components/molecules';

const TotalSection = ({ data, plannedLabel, quarterlyLabel, actualLabel }) => {
	const total = data || {};

	if (!total.planned && !total.quarterly && !total.actual) return null;

	return (
		<div className="beneficiaries-total-section">
			<div className="title">
				Total number of beneficiaries
				<div className="title__subtitle">
					Excludes overlap between programme areas
				</div>
			</div>
			<div className="beneficiaries-total-section__indicators">
				{total.planned && (
					<HorizontalIndicator
						value={total.planned}
						label={plannedLabel}
						currency=""
					/>
				)}
				{total.quarterly && (
					<HorizontalIndicator
						value={total.quarterly}
						label={quarterlyLabel}
						currency=""
					/>
				)}
				{total.actual && (
					<HorizontalIndicator
						value={total.actual}
						label={actualLabel}
						currency=""
					/>
				)}
			</div>
		</div>
	);
};

export default TotalSection;
