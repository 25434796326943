import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getCountryHeaderBeneficiaries = async ({ code }) =>
	api.get(`${appConfig.API_V2_URLS.COUNTRY_HEADER_BENEFICIARIES + code}/`);

export const useCountryHeaderBeneficiaries = ({ code }, options) =>
	useQuery({
		queryFn: () => getCountryHeaderBeneficiaries({ code }),
		...options,
	});
