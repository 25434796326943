import React from 'react';

import { Tooltip } from 'react-tippy';

import { Legend } from 'components/atoms';
import { DoughnutChart } from 'components/molecules';
import { TooltipContent } from 'components/molecules/BarIndicatorWithLabels';

import useWindowSize from 'hooks/useWindowSize';

import colors from 'constants/colors';
import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat, getPercents } from 'utils';

const LegendListData = ({ data: dataProp, title, currentValue }) => {
	const data = dataProp.map((item) => ({
		value: item.value,
		label: item.year,
	}));

	if (!data.length) return 0;

	return (
		<Tooltip
			{...tooltipStyle}
			theme="dark"
			position="bottom"
			className="tooltip"
			html={<TooltipContent values={data} title={title} />}
		>
			{getHumanNumberFormat(currentValue)}
		</Tooltip>
	);
};

const LocalEconomiesChart = ({ data = {} }) => {
	const [width] = useWindowSize();

	const {
		cbt_and_cv: cbtAndCvList,
		food: foodList,
		local_transportation: transportationList,
		local_salaries: salariesList,
		year,
	} = data;

	const { value: food = 0 } = foodList.find((item) => item.year === year) || {};
	const { value: cbtAndCv = 0 } =
		cbtAndCvList.find((item) => item.year === year) || {};
	const { value: transportation = 0 } =
		transportationList.find((item) => item.year === year) || {};
	const { value: salaries = 0 } =
		salariesList.find((item) => item.year === year) || {};

	const chartData = [
		{ y: food, x: 'In-country food expenditures' },
		{ y: cbtAndCv, x: 'Cash-based transfer value' },
		{ y: transportation, x: 'Local transportation costs' },
		{ y: salaries, x: 'Local salaries' },
	];
	const lines = ['food', 'cbtAndCv', 'transportation', 'salaries'];
	const total = food + cbtAndCv + transportation + salaries;

	const labelMap = {
		food: {
			label: chartData[0].x,
			percents: food <= 0 || !total ? 'N/A' : `${getPercents(food, total)}%`,
			value: (
				<LegendListData
					currentValue={food}
					data={foodList}
					title={chartData[0].x}
				/>
			),
		},
		cbtAndCv: {
			label: chartData[1].x,
			percents:
				cbtAndCv <= 0 || !total ? 'N/A' : `${getPercents(cbtAndCv, total)}%`,
			value: (
				<LegendListData
					currentValue={cbtAndCv}
					data={cbtAndCvList}
					title={chartData[1].x}
				/>
			),
		},
		transportation: {
			label: chartData[2].x,
			percents:
				transportation <= 0 || !total
					? 'N/A'
					: `${getPercents(transportation, total)}%`,
			value: (
				<LegendListData
					currentValue={transportation}
					data={transportationList}
					title={chartData[2].x}
				/>
			),
		},
		salaries: {
			label: chartData[3].x,
			percents:
				salaries <= 0 || !total ? 'N/A' : `${getPercents(salaries, total)}%`,
			value: (
				<LegendListData
					currentValue={salaries}
					data={salariesList}
					title={chartData[3].x}
				/>
			),
		},
	};

	const renderLegendLabel = ({ label, percents, value }) => (
		<div className="legend__label">
			<span className="bold">{label}</span>
			<div className="value-container">
				<div className="bold percents">{percents}</div>
				<div className="value">{value}</div>
			</div>
		</div>
	);

	return (
		<div className="chart__container">
			<div className="chart__title">
				Main financial injections to local economy
			</div>
			<div className="chart-indicator-panel__disclaimer">In USD</div>
			<div className="doughnut-chart__container">
				<DoughnutChart
					data={chartData}
					bottomTextLines={['INJECTIONS TO', 'LOCAL ECONOMY']}
					colorScale={colors.doughnutChartPalette}
					withLabel={false}
					isLabelCentered={false}
					width={width < 370 ? 230 : 240}
					height={width < 370 ? 230 : 270}
					innerRadius={width < 370 ? 55 : 65}
					valueType="usd"
					animate={null}
				/>
				<Legend
					labelRender={renderLegendLabel}
					labelMap={labelMap}
					lines={lines}
					colorScale={colors.doughnutChartPalette}
					separated
				/>
			</div>
		</div>
	);
};

export default LocalEconomiesChart;
