import { api } from 'api';

import appConfig from 'config';

const exportRequest = (
	code,
	section,
	mode = 'single',
	format = 'xlsx',
	htmlData,
	isAdvanced,
) =>
	api
		.post(appConfig.API_V2_URLS.EXPORT, {
			code,
			section,
			mode,
			format,
			html_data: htmlData,
		})
		.then((res) => {
			if (res) {
				if (res.xlsx_file) {
					window.open(res.xlsx_file, '_blank');
					return 'success';
				}
				if (res.xlsx_file === null) {
					return 'retry';
				}
			}
		})
		.catch(console.error);

export default exportRequest;
