function getCountryCode() {
	const path = window.location.pathname;
	if (path.match('/countries/')) {
		const regex = /(countries)|([a-zA-Z]+)/g;
		try {
			return path.match(regex)[1];
		} catch (e) {
			console.warn(e);
		}
	}
	return null;
}

export default getCountryCode;
