import React, { useMemo } from 'react';

import { NoData } from 'components/atoms';
import { BarIndicatorWithLabels } from 'components/molecules';

import { getPercents } from 'utils';

const BreakdownByRegion = ({ indicators = [], total = 0 }) => {
	const maxValue = useMemo(
		() =>
			Math.max(
				...indicators.map(({ data }) =>
					Object.values(data).reduce((a, b) => a + (b.value || 0), 0),
				),
			),
		[indicators],
	);

	const sortedData = indicators.sort((a, b) =>
		a.region_code.localeCompare(b.region_code),
	);

	if (!indicators.length) {
		return <NoData height={50} />;
	}

	return (
		<div>
			{sortedData.map(({ region_code: code, data }) => {
				const totalSum = Object.values(data).reduce(
					(a, b) => a + (b.value || 0),
					0,
				);
				const barLength = getPercents(totalSum, maxValue);

				const countPercents = (fullPercents) =>
					(fullPercents * barLength) / 100;

				const keyLabelMap = {
					refugees: 'Refugees',
					migrant: 'Migrants',
					tdp: 'TDPs',
					idp: 'IDPs',
					non_refugees: 'Non-Refugees',
					resident: 'Residents',
					returnee: 'Returnees',
					other: 'Other',
				};
				const tooltipData = Object.keys(data).map((key) => ({
					value: data[key].value,
					label: keyLabelMap[key] || key,
				}));

				const multipleValues = Object.keys(data).map((key) => ({
					value: data[key].value,
					length: countPercents(data[key].percentage),
				}));

				return (
					<div
						className="header-dashboard-item__indicator"
						key={`beneficiaries-${code}`}
					>
						<BarIndicatorWithLabels
							value={totalSum}
							length={barLength}
							percents={getPercents(totalSum, total)}
							tooltipClassName="header-tooltip"
							labelClassName="header-dashboard-item__indicator__label"
							isMultiple
							withTooltip
							withLabelTooltip={false}
							withTooltipLegend
							isDark={false}
							tooltipData={{ values: tooltipData }}
							multipleValues={multipleValues}
							label={code}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default BreakdownByRegion;
