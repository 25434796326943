import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getLocalEconomies = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_LOCAL_ECONOMIES;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_LOCAL_ECONOMIES}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_LOCAL_ECONOMIES}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useLocalEconomies = ({ countryCode, regionCode }, options) =>
	useQuery({
		queryFn: () => getLocalEconomies({ countryCode, regionCode }),
		...options,
	});
