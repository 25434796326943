import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	useRegionHeaderEmployees,
	useRegionHeaderBeneficiaries,
	useRegionHeaderPopulation,
} from 'api';

import {
	ExportLinks,
	EmployeesHeaderPanel,
	BeneficiariesHeaderPanel,
	PopulationHeaderPanel,
	HeaderInfo,
	HeaderBottomIndicators,
} from 'components/molecules';

import { useScrollToPanel, useContentRefLayout } from 'hooks';

import { selectCurrentYear } from 'features/global/selectors';

import { getElementByKey, exportRequest } from 'utils';

const HeaderRegionDashboard = ({ code }) => {
	const { data: employeesData, isLoading: isEmployeesLoading } =
		useRegionHeaderEmployees(
			{ code },
			{
				queryKey: [code, 'region-header-employees'],
			},
		);

	const { data: beneficiariesData, isLoading: isBeneficiariesLoading } =
		useRegionHeaderBeneficiaries(
			{ code },
			{
				queryKey: [code, 'region-header-beneficiaries'],
			},
		);

	const { data: populationData, isLoading: isPopulationLoading } =
		useRegionHeaderPopulation(
			{ code },
			{
				queryKey: [code, 'region-header-population'],
			},
		);

	const year = useSelector(selectCurrentYear);

	const containerRef = useRef(null);
	const { regionCode } = useParams();
	const allIsLoading =
		isEmployeesLoading && isBeneficiariesLoading && isPopulationLoading;
	useScrollToPanel('outlook', containerRef, allIsLoading);

	let infoData = getElementByKey(employeesData, 'info');

	if (infoData && infoData.length) {
		infoData = infoData.find((obj) => obj.indicator === 'header');
	}

	const { html, pdfEnabled } = useContentRefLayout({
		loading:
			isEmployeesLoading || isBeneficiariesLoading || isPopulationLoading,
		title: `Outlook (${year})`,
		contentRef: containerRef,
		infoText: infoData ? infoData.text : '',
		withMarkupRender: false,
		additionalMarkup: `<h1 class="wfp--module__title">Outlook (${year})</h1>`,
		year,
	});

	return (
		<>
			<div
				className="header-panel-dashboard region"
				ref={containerRef}
				data-testid="header-region-dashboard"
			>
				<div className="header-panel-dashboard__wrap">
					<EmployeesHeaderPanel
						level="region"
						data={employeesData}
						loading={isEmployeesLoading}
					/>
					<BeneficiariesHeaderPanel
						level="region"
						data={beneficiariesData}
						loading={isBeneficiariesLoading}
					/>
					<PopulationHeaderPanel
						level="region"
						data={populationData}
						loading={isPopulationLoading}
					/>
				</div>
				<HeaderBottomIndicators
					data={employeesData}
					loading={isEmployeesLoading}
				/>
			</div>
			<div className="header-footer">
				<ExportLinks
					excelClickHandler={(mode) =>
						exportRequest(regionCode, 'outlook', mode)
					}
					pdfClickHandler={() =>
						exportRequest(regionCode, 'outlook', 'single', 'pdf', html)
					}
					isExcelDivided
					pdfEnabled={pdfEnabled}
					excelFullEnabled
					level="region"
				/>
				<HeaderInfo data={infoData} />
			</div>
		</>
	);
};

export default HeaderRegionDashboard;
