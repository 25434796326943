import React from 'react';

import { Tooltip } from 'react-tippy';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat } from 'utils';

const BreakDownIndicator = ({ data, label }) => {
	const { wfp, other, total } = data || {};

	const wfpHuman = getHumanNumberFormat(wfp ? wfp.value : 'N/A');
	const otherHuman = getHumanNumberFormat(other ? other.value : 'N/A');
	const totalHuman = getHumanNumberFormat(total ? total.value : 'N/A');

	const wfpCountries = getHumanNumberFormat(wfp ? wfp.countries : 'N/A');
	const otherCountries = getHumanNumberFormat(other ? other.countries : 'N/A');
	const totalCountries = getHumanNumberFormat(total ? total.countries : 'N/A');

	return (
		<Tooltip
			{...tooltipStyle}
			className="wfp-tooltip wfp-tooltip-v2"
			interactive
			trigger="click"
			position="bottom"
			html={
				<>
					<div className="breakdown-label">{label}</div>
					<div className="breakdown-list">
						<div className="breakdown-list__item">
							<div>
								WFP<span>({wfpCountries} countries)</span>
							</div>
							<div>{wfp ? wfpHuman : 'N/A'}</div>
						</div>
						<div className="breakdown-list__item">
							<div>
								Other<span>({otherCountries} countries)</span>
							</div>
							<div>{other ? otherHuman : 'N/A'}</div>
						</div>
						<div className="breakdown-list__item">
							<div>
								Total<span>({totalCountries} countries)</span>
							</div>
							<div>{total ? totalHuman : 'N/A'}</div>
						</div>
					</div>
				</>
			}
		>
			<div className="population-item-row">
				<span className="population-item-row__label">{label}</span>
				<span className="population-item-row__value breakdown">
					{wfp ? wfpHuman : 'N/A'}
				</span>
			</div>
		</Tooltip>
	);
};

export default BreakDownIndicator;
