import React from 'react';

import OneLineIndicator from '../OneLineIndicator';

const OfficesIndicators = ({ offices = [] }) => {
	const classNames = 'offices-indicators__item offices-indicators__list-item';

	if (!offices.length) return null;

	return (
		<div className="header-dashboard-item offices-indicators">
			<OneLineIndicator
				className="total"
				value={offices.length}
				label="Sub offices"
			/>
			<div className="offices-indicators__items">
				{offices.map(({ name }, index) => {
					let withComa = true;
					if (offices.length === index + 1) withComa = false;

					return (
						<p className={classNames} key={`header-office-${name}`}>
							{name}
							{withComa && ', '}
						</p>
					);
				})}
			</div>
		</div>
	);
};

export default OfficesIndicators;
