import React from 'react';

import { Tooltip } from 'react-tippy';

import tooltipStyle from 'constants/tooltipStyle';

const CountryDirector = ({ data }) => {
	const contactInfo = data ? data.contact : {};
	if (!data || !contactInfo) return null;

	const indicatorTypeMap = {
		mobile: 'Mobile',
		extension: 'Extension',
		foodsat: 'FoodSat',
		work: 'Work',
	};

	const directorContent = (
		<div className="director-details">
			<ul className="valuelist valuelist--flex">
				{contactInfo.phones &&
					contactInfo.phones.map((obj, i) => (
						<li key={i}>
							<span className="valuelist__title">
								{indicatorTypeMap[obj.type] || obj.type}:
							</span>
							<span className="valuelist__value">{obj.phone}</span>
						</li>
					))}
				<li>
					<span className="valuelist__title">Email:</span>
					<span className="valuelist__value">
						<a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
					</span>
				</li>
				{contactInfo.duty_station && (
					<li>
						<span className="valuelist__title">Duty Station:</span>
						<span className="valuelist__value">{contactInfo.duty_station}</span>
					</li>
				)}
			</ul>
		</div>
	);

	return (
		<Tooltip
			{...tooltipStyle}
			className="wfp-tooltip wfp-tooltip-v2"
			position="bottom"
			interactive
			trigger="click"
			html={directorContent}
		>
			<div className="wfp-secondarynav__director">
				{contactInfo.last_name}, {contactInfo.first_name}
			</div>
		</Tooltip>
	);
};

export default CountryDirector;
