import React from 'react';
import { Link } from 'react-router-dom';

import IOSImage from 'assets/ios.png';

import GooglePlayLink from './GooglePlayLink';

const MobileApps = () => (
	<div className="footer-links">
		<h3>Mobile app</h3>
		<div className="footer-links__mobile-apps">
			<GooglePlayLink className="footer-links__image-wrapper" />
			<Link to="/mobile" className="footer-links__image-wrapper">
				<img src={IOSImage} alt="loading" />
			</Link>
		</div>
	</div>
);

export default MobileApps;
