import React from 'react';
import PropTypes from 'prop-types';

import { useDonors } from 'api';

import { InfoSource } from 'components/atoms';
import { ChartIndicatorsPanel } from 'components/templates';

import { getElementByKey } from 'utils';

import Bars from './Bars';

export const PANEL_KEY = 'top-10-donors';

const DonorsPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useDonors(
		{ countryCode, regionCode },
		{ queryKey: [countryCode, regionCode, PANEL_KEY] },
	);

	const { year } = data || {};

	const infoData = getElementByKey(data, 'info');

	return (
		<ChartIndicatorsPanel
			title={year ? `Top 10 donors (${year})` : 'Top 10 donors'}
			panelKey={PANEL_KEY}
			indicatorsComponent={
				<Bars
					data={data}
					title="Top 10 donors"
					isCountryLevel={!!countryCode}
					isRegionLevel={!!regionCode}
					subTitle="Contributions in USD and variations"
				/>
			}
			className="donors-panel"
			indicatorsOnly
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			loading={isLoading}
			noData={!data}
		/>
	);
};

DonorsPanel.propTypes = {
	countryCode: PropTypes.string,
	regionCode: PropTypes.string,
};

DonorsPanel.defaultProps = {
	countryCode: null,
	regionCode: null,
};

export default DonorsPanel;
