import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useMsal } from '@azure/msal-react';

import { useHeader } from 'api';

import { MainNavigation, MainNavigationItem, Link, User } from '@wfp/ui';

import { gaGlossaryEvent } from 'anatytics';

import { ExtLink } from 'components/molecules';

import { setGlobal } from 'features/global/globalSlice';
import { selectMenu, selectUserData } from 'features/global/selectors';

import { getSubTab } from 'utils';

import { useProfilePhotoUrl } from './hooks';

import UserMenu from './UserMenu';
import MenuList from './MenuList';
import SearchEl from './Search';

const TitleLogo = () => (
	<a href="/" title="Home">
		WFP Dashboard
	</a>
);

const CountriesMenu = () => {
	const location = useLocation();
	const data = useSelector(selectMenu);

	return (
		<MenuList
			title="Countries"
			type="countries"
			displayOrder
			orderText="Regions"
			source={data.countries}
			parentType="regions"
			parentSource={data.regions}
			infoData={data.info}
			location={location}
		/>
	);
};

const RegionsMenu = () => {
	const location = useLocation();
	const data = useSelector(selectMenu);

	return (
		<MenuList
			title="Regions"
			type="regions"
			displayOrder={false}
			orderText="Regions"
			source={data.regions}
			location={location}
		/>
	);
};

const GlossaryMenu = () => {
	const location = useLocation();
	const data = useSelector(selectMenu);

	return (
		<MenuList
			title="Glossary"
			type="glossary"
			displayOrder={false}
			orderText="Topic"
			defaultOrder="asc"
			source={data.glossary}
			parentSource={data.glossary_categories}
			location={location}
		/>
	);
};

const Menu = () => {
	const { instance } = useMsal();

	const dispatch = useDispatch();
	const location = useLocation();

	const profilePhotoUrl = useProfilePhotoUrl();

	const { data: headerData } = useHeader({
		queryKey: ['header'],
	});
	const [navInstance, setNavInstance] = useState(null);

	const data = useSelector(selectMenu);
	const userData = useSelector(selectUserData);

	useEffect(() => {
		if (headerData) {
			const { menu, config, user_data: userData } = headerData;

			dispatch(
				setGlobal({
					menu,
					config,
					userData,
				}),
			);
		}
	}, [headerData]);

	useEffect(() => {
		// Close SubNavigation when Route changes
		if (navInstance && navInstance.state.openMobile) {
			navInstance.toggleMenu();
		} else if (navInstance) {
			navInstance.triggerSubNavigation();
		}
	}, [location]);

	let userFullName = 'Anonymous';

	if (userData) {
		userFullName = userData.display_name;
	}

	if (['', 'Anonymous'].includes(userFullName)) {
		const userData = instance.getAllAccounts();
		if (userData && userData[0]) userFullName = userData[0].username;
	}

	const subTab = getSubTab(location);

	return (
		<MainNavigation
			data-testid="menu"
			ref={setNavInstance}
			pageWidth="lg"
			logo={<TitleLogo />}
		>
			<MainNavigationItem>
				<ExtLink small to={`/${subTab}`}>
					Global
				</ExtLink>
			</MainNavigationItem>

			<MainNavigationItem subNavigation={<RegionsMenu />}>
				<Link>Regions</Link>
			</MainNavigationItem>

			<MainNavigationItem subNavigation={<CountriesMenu />}>
				<Link>Countries</Link>
			</MainNavigationItem>

			<MainNavigationItem
				subNavigation={<GlossaryMenu />}
				className="glossary-menu"
			>
				<Link onClick={gaGlossaryEvent}>
					<span>Glossary</span>
				</Link>
			</MainNavigationItem>

			<MainNavigationItem>
				<Link href="mailto:wfp.dashboard@wfp.org">Contact us</Link>
			</MainNavigationItem>

			<MainNavigationItem>
				{data ? <SearchEl source={data} /> : null}
			</MainNavigationItem>

			<MainNavigationItem
				className="wfp--main-navigation__user"
				subNavigation={<UserMenu />}
			>
				<User
					small
					alt={userFullName}
					name={userFullName}
					image={profilePhotoUrl ?? undefined}
				/>
			</MainNavigationItem>
		</MainNavigation>
	);
};

export default Menu;
