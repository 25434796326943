import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getFoodDistribution = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_FOOD_DISTRIBUTION;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_FOOD_DISTRIBUTION}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_FOOD_DISTRIBUTION}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useFoodDistribution = ({ countryCode, regionCode }, options) =>
	useQuery({
		queryFn: () => getFoodDistribution({ countryCode, regionCode }),
		...options,
	});
