import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'react-tippy';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberWithSymbol } from 'utils';

const BarValue = ({
	value: valueProp,
	extraDataLabel,
	extraData,
	theme,
	type,
	additionSymbol,
}) => {
	const [value, symbol] = getHumanNumberWithSymbol(valueProp);

	const valueComponent = (
		<>
			<span className="value">{value}</span>
			<span className="value-symbol">{symbol}</span>
		</>
	);

	if (!extraData) {
		return <div className="bar-indicator__value">{valueComponent}</div>;
	}

	return (
		<div className="bar-indicator__value breakdown">
			<Tooltip
				{...tooltipStyle}
				className="wfp-tooltip wfp-tooltip-v2"
				interactive
				trigger="mouseenter"
				position="bottom"
				theme={theme}
				html={
					<>
						<div className="breakdown-label">{extraDataLabel}</div>

						<div className="breakdown-list">
							{extraData &&
								extraData.map((item) => (
									<div className="breakdown-list__item" key={item.year}>
										<div>{item.year}</div>
										<div>
											{item.value ? getHumanNumberWithSymbol(item.value) : 0}
										</div>
									</div>
								))}
						</div>
					</>
				}
			>
				{valueComponent}
			</Tooltip>
			{type ? ` ${type}` : ''}
			{!!additionSymbol && (
				<span className="value-suffix"> {additionSymbol}</span>
			)}
		</div>
	);
};

const BarIndicator = ({
	length,
	withValueText,
	theme,
	value,
	type,
	additionSymbol,
	extraData,
	extraDataLabel,
	percents,
	percentsEmptyValue,
	isMultiple,
	color,
	label,
	multipleValues,
	previous,
	thirdValue,
	thirdPercents,
}) => {
	let mainLength = length > 100 ? 100 : length;
	if (mainLength < 0) mainLength = 0;

	return (
		<div className={`bar-indicator ${theme}`}>
			{withValueText && (
				<BarValue
					value={value}
					type={type}
					additionSymbol={additionSymbol}
					extraData={extraData}
					extraDataLabel={extraDataLabel}
					theme={theme}
				/>
			)}

			{typeof percents === 'number' && (
				<div className="bar-indicator__value bar-indicator__percents">
					{!Number.isNaN(percents) && percents > 0
						? `${percents}%`
						: percentsEmptyValue}
				</div>
			)}
			<div className="bar-indicator__scale-wrap">
				{!isMultiple ? (
					<>
						<div
							className="bar-indicator__scale-container"
							style={{ width: mainLength > 0 ? `${mainLength}%` : 0 }}
						>
							<div
								className="scale single"
								style={color ? { backgroundColor: color } : {}}
							/>
						</div>
						{!!label && <div className="label">{label}</div>}
					</>
				) : (
					<div
						className="bar-indicator__scale-container"
						style={{ width: mainLength > 0 ? `${mainLength}%` : 0 }}
					>
						{multipleValues.map(({ value, length: itemLength, label }, i) => {
							const customWidth =
								itemLength > 0 ? (itemLength * 100.0) / length : 0;

							return (
								<div
									key={`bar-indicator-${value}-${itemLength}-${label}-${i}`}
									className="scale"
									style={{ width: `${customWidth > 100 ? 100 : customWidth}%` }}
								/>
							);
						})}
					</div>
				)}
				{previous.map(({ length, borderStyle, label, value }, key) => (
					<div
						className="bar-indicator__prev"
						style={{ borderStyle, left: `${length > 100 ? 100 : length}%` }}
						key={`bar-indicator-${label}-${value}-${key}-prev`}
					/>
				))}

				{thirdValue !== undefined && (
					<div
						className="bar-indicator__third"
						style={{
							width: `${thirdPercents > 100 ? 100 : thirdPercents}%`,
						}}
					/>
				)}
			</div>
		</div>
	);
};

BarIndicator.propTypes = {
	theme: PropTypes.string,
	length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label: PropTypes.string,
	type: PropTypes.string,
	multipleValues: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		}),
	),
	previous: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			year: PropTypes.number,
			length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			borderStyle: PropTypes.oneOf(['solid', 'dotted', 'dashed']),
		}),
	),
	percentsEmptyValue: PropTypes.string,
	isMultiple: PropTypes.bool,
	withValueText: PropTypes.bool,
	color: PropTypes.string,
	extraData: PropTypes.array,
};

BarIndicator.defaultProps = {
	theme: 'dark',
	length: 100,
	value: 0,
	type: '',
	previous: [],
	withValueText: true,
	percentsEmptyValue: 'N/A',
	extraData: null,
};

export default BarIndicator;
