import React from 'react';

import { Tooltip } from 'react-tippy';

import { LoadingBar } from 'components/atoms';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat } from 'utils';

import BreakdownByResidenceStatus from '../BreakdownByResidenceStatus';

import BreakdownByCountry from './BreakdownByCountry';
import EmergencyList from './EmergencyList';

const ProjectedBeneficiaries = ({ showDisclaimer, data }) => {
	const DisclaimerContainer = showDisclaimer
		? ({ children }) => (
				<Tooltip
					{...tooltipStyle}
					theme="light"
					position="top"
					style={{ height: 30 }}
					className="wfp-tooltip wfp-tooltip-v2"
					title="Projected beneficiary figures may be under review"
				>
					{children}
				</Tooltip>
		  )
		: React.Fragment;

	const currentData = data.current || {};
	const currentValue = getHumanNumberFormat(currentData.data);
	const currentYear = currentData.year;

	const nextData = data.next || {};
	const nextValue = getHumanNumberFormat(nextData.data);
	const nextYear = nextData.year;

	const hasValues = currentData.data || nextData.data;

	const TitleContainer = ({ children }) => (
		<Tooltip
			{...tooltipStyle}
			className="wfp-tooltip wfp-tooltip-v2"
			interactive
			disabled={!hasValues}
			trigger="click"
			position="bottom"
			html={
				<>
					<div className="breakdown-label">Projected beneficiaries</div>

					<div className="breakdown-list">
						<div className="breakdown-list__item">
							<div>{nextYear} total</div>
							<div>{nextData.data ? nextValue : 'N/A'}</div>
						</div>
						<div className="breakdown-list__item">
							<div>{currentYear} total</div>
							<div>{currentData.data ? currentValue : 'N/A'}</div>
						</div>
					</div>
				</>
			}
		>
			{children}
		</Tooltip>
	);

	return (
		<div className="title__container">
			<h3 className="column__title">Projected beneficiaries</h3>
			<DisclaimerContainer>
				<TitleContainer>
					<span className={`column__subtitle${hasValues ? ' breakdown' : ''}`}>
						{currentData.data ? `${currentValue} total` : 'N/A'}{' '}
						{showDisclaimer && '*'}
					</span>
				</TitleContainer>
			</DisclaimerContainer>
		</div>
	);
};

const BeneficiariesHeaderPanel = ({
	data,
	level,
	loading,
	showProjectedDisclaimer,
}) => {
	const {
		target_beneficiaries: targetBeneficiaries = {
			current: { data: null },
			next: { data: null },
		},
		emergencies = [],
		top_beneficiaries: topBeneficiaries = [],
		residence_status: residenceStatus,
	} = data || {};

	const titles = {
		region: 'Regional',
		country: 'Country',
		global: 'Global',
	};

	const levelTitle = titles[level];

	return (
		<div className="header-dashboard beneficiaries-dashboard">
			<div className="header-dashboard__indicators">
				{loading ? (
					<div className="loading-container">
						<LoadingBar isSmall={false} />
					</div>
				) : (
					<>
						<ProjectedBeneficiaries
							showDisclaimer={showProjectedDisclaimer || false}
							data={targetBeneficiaries}
						/>

						<div className="header-dashboard-item__list">
							{level !== 'country' && (
								<div className="header-dashboard-item">
									<h4 className="header-dashboard-item__label">By emergency</h4>
									<EmergencyList loading={loading} data={emergencies} />
								</div>
							)}

							{level !== 'country' && (
								<div className="header-dashboard-item">
									<h4 className="header-dashboard-item__label">
										By country (top 5)
									</h4>
									<BreakdownByCountry
										loading={loading}
										topBeneficiaries={topBeneficiaries}
									/>
								</div>
							)}

							{level === 'country' && (
								<div className="header-dashboard-item small-indicator-label">
									<h4 className="header-dashboard-item__label">
										By residence status
									</h4>
									<BreakdownByResidenceStatus
										residenceStatus={residenceStatus}
									/>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default BeneficiariesHeaderPanel;
