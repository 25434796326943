import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';

import { api } from 'api';

import {
	Button,
	SubNavigation,
	SubNavigationContent,
	SubNavigationHeader,
	SubNavigationTitle,
} from '@wfp/ui';

import { signOut } from 'auth/azure-service';

import { setUserConfig } from 'features/global/globalSlice';
import { selectUserData } from 'features/global/selectors';

import appConfig from 'config';

import Switch from './Switch';
import FavoritesLinks from './FavoriteLinks';

const UserMenu = () => {
	const { instance } = useMsal();

	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const userData = useSelector(selectUserData);

	const { favorites = [], is_advanced: isAdvancedMode } = userData.config || {};

	const onSwitchChange = async () => {
		try {
			const isAdvanced = !isAdvancedMode;
			const userConfig = { favorites, is_advanced: isAdvanced };
			localStorage.setItem('isAdvancedMode', isAdvanced ? 'advanced' : 'basic');
			dispatch(setUserConfig({ config: userConfig }));
			await api.post(appConfig.API_V2_URLS.CONFIG, userConfig);

			if (!isAdvanced && location.pathname !== '/overview') {
				navigate('/overview');
			}
		} catch (e) {
			console.log('advanced mode switch error ===', e);
		}
	};

	function logout(event) {
		event.preventDefault();
		signOut();
	}

	/* List Elements */
	let userFullName = 'Anonymous';

	if (userData) {
		userFullName = userData.display_name;
	}

	if (['', 'Anonymous'].includes(userFullName)) {
		const userData = instance.getAllAccounts();

		if (userData && userData[0]) {
			userFullName = userData[0].username;
		}
	}

	return (
		<SubNavigation>
			<SubNavigationHeader>
				<SubNavigationTitle className="menu__title">
					{userFullName}
				</SubNavigationTitle>

				<div className="wfp--sub-navigation__link">
					<Button onClick={logout} className="wfp-btn" icon={null}>
						Logout
					</Button>
				</div>
			</SubNavigationHeader>
			<SubNavigationHeader>
				<Switch
					checked={isAdvancedMode}
					onChange={onSwitchChange}
					label="View mode"
				/>
			</SubNavigationHeader>
			<SubNavigationContent>
				<FavoritesLinks />
			</SubNavigationContent>
		</SubNavigation>
	);
};

export default UserMenu;
