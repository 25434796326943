import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getTopCountries = async ({ regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_TOP_COUNTRIES;

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_TOP_COUNTRIES}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useTopCountries = ({ regionCode }, options) =>
	useQuery({
		queryFn: () => getTopCountries({ regionCode }),
		...options,
	});
