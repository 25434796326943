import React from 'react';

import { useProjectedRequirements } from 'api';

import { InfoSource } from 'components/atoms';
import { ChartIndicatorsPanel } from 'components/templates';

import { getElementByKey } from 'utils';

import ProjectedOperationalIndicators from './ProjectedOperationalIndicators';

export const PANEL_KEY = 'projected-requirements';

const ProjectedRequirementsPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useProjectedRequirements(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const infoData = getElementByKey(data, 'info');

	return (
		<ChartIndicatorsPanel
			title={
				data?.year
					? `Projected operational requirements (${data.year})`
					: 'Projected operational requirements'
			}
			className="projected-requirements-panel"
			panelKey={PANEL_KEY}
			noData={!data?.op_req_by_focus_area && !data?.op_req_by_transfer_modality}
			indicatorsOnly
			indicatorsComponent={<ProjectedOperationalIndicators data={data} />}
			loading={isLoading}
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			horizontal
		/>
	);
};

export default ProjectedRequirementsPanel;
