/*
Styles: scss/v2/components/charts/doughnut-charts.scss
 */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { VictoryPie } from 'victory';

import { PieChartTooltip } from 'components/atoms';

import colors from 'constants/colors';

import { getHumanNumberFormat, getPercents } from 'utils';

import DoughnutLegend from './DoughnutLegend';

const DoughnutChart = ({
	width,
	height,
	total,
	data,
	valueType,
	colorScale,
	onlyMainLabel,
	bottomTextLines,
	padAngle,
	tooltipRenderMethod,
	animate,
	innerRadius,
	withLegend,
}) => {
	const [tooltipData, setTooltipData] = useState({
		position: {},
		label: '',
		value: '',
		isVisible: false,
	});

	const showTooltip = (position, label, value) => {
		setTooltipData({ position, label, value, isVisible: true });
	};

	const hideTooltip = () => {
		setTooltipData({ ...tooltipData, isVisible: false });
	};

	const textPositionX = width / 2;
	const textPositionY = height / 2;

	let totalValue = total;

	if (!total) totalValue = data.reduce((a, b) => a + (b.y || 0), 0);

	const humanTotalValue = getHumanNumberFormat(totalValue);
	let centerText = '';

	if (['usd', 'us'].includes(valueType.toLowerCase())) centerText = 'USD';

	let colorsPalette = colorScale;
	let chartData = data;

	const isEmptyData = useMemo(() => {
		if (!data || !data.length) {
			return true;
		}

		return !!data.every(({ y }) => !y || y < 0);
	}, [data]);

	if (isEmptyData) {
		colorsPalette = [colors.catskillWhite];
		chartData = [{ y: 1 }];
	}

	return (
		<div
			className="chart-v2"
			onMouseLeave={hideTooltip}
			data-testid="doughnutChart_component"
		>
			<svg width={width} height={height} className="doughnut-chart">
				<text
					x={textPositionX}
					y={onlyMainLabel ? textPositionY + 30 : textPositionY + 20}
					textAnchor="middle"
					className="center-text"
				>
					{humanTotalValue} {centerText}
				</text>
				{bottomTextLines.map((text, i) => (
					<text
						key={`${text} doughnut-chart`}
						x={textPositionX}
						y={textPositionY + 40 + i * 14}
						textAnchor="middle"
						className="bottom-text"
					>
						{text}
					</text>
				))}
				<VictoryPie
					padAngle={padAngle}
					standalone={false}
					events={[
						{
							target: 'data',
							eventHandlers: {
								onMouseEnter({ nativeEvent }, { datum }) {
									if (!datum.x) {
										return;
									}

									let value;
									if (tooltipRenderMethod) {
										value = tooltipRenderMethod(datum, totalValue);
									} else {
										value = `${
											datum.y < totalValue
												? getPercents(datum.y, totalValue)
												: 100
										}% (${getHumanNumberFormat(datum.y)})`;
									}
									showTooltip(
										{ left: nativeEvent.pageX, top: nativeEvent.pageY - 40 },
										datum.x || '',
										value,
									);
								},
							},
						},
					]}
					labelComponent={<></>}
					animate={animate}
					padding={{ top: 50, bottom: 0, right: 0, left: 0 }}
					innerRadius={innerRadius}
					width={width}
					height={height}
					data={chartData}
					colorScale={colorsPalette}
					style={{
						labels: {
							fill: colors.navy,
							fontSize: 15,
							fontWeight: 'bold',
							fontFamily: 'Open Sans',
						},
					}}
				/>
			</svg>
			{tooltipData.isVisible && <PieChartTooltip {...tooltipData} />}
			{withLegend && (
				<DoughnutLegend
					isSorted={false}
					colorScale={colorsPalette}
					data={chartData}
				/>
			)}
		</div>
	);
};

DoughnutChart.propTypes = {
	data: PropTypes.array,
	valueType: PropTypes.string,
	innerRadius: PropTypes.number,
	padAngle: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	colorScale: PropTypes.array,
	dynamicAnimation: PropTypes.bool,
	withLegend: PropTypes.bool,
	animate: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	bottomTextLines: PropTypes.arrayOf(PropTypes.string),
	withLabel: PropTypes.bool,
	withEmptyDataHandle: PropTypes.bool,
	tooltipRenderMethod: PropTypes.func,
	onlyMainLabel: PropTypes.bool,
};

DoughnutChart.defaultProps = {
	data: [],
	valueType: 'usd',
	innerRadius: 60,
	padAngle: 0,
	colorScale: colors.doughnutChartPalette,
	width: 230,
	height: 230,
	dynamicAnimation: true,
	withLabel: true,
	animate: null,
	bottomTextLines: [],
};

export default DoughnutChart;
