import React from 'react';
import PropTypes from 'prop-types';

const Legend = ({
	lines,
	colorScale,
	labelMap,
	orientation,
	labelRender,
	separated,
}) => (
	<div className={`legend${orientation ? ` legend-${orientation}` : ''}`}>
		{lines.map((line, index) => (
			<div className="mark-wrap" key={`legend-line-${line}`}>
				<div style={{ backgroundColor: colorScale[index] }} className="mark" />
				{separated ? (
					<div className="legend__label">
						<span className="bold">
							{labelMap[line] && labelMap[line].label}
						</span>
						<div className="value-container">
							<div className="value">
								{labelMap[line] && labelMap[line].value}
							</div>
							<div className="percents">
								{labelMap[line] && labelMap[line].percents}
							</div>
						</div>
					</div>
				) : (
					<div className="label">
						{labelMap[line] && labelMap[line].label}
						{labelMap[line] &&
							labelMap[line].subLabel &&
							` (${labelMap[line].subLabel})`}
					</div>
				)}
			</div>
		))}
	</div>
);

Legend.propTypes = {
	lines: PropTypes.arrayOf(PropTypes.string),
	colorScale: PropTypes.arrayOf(PropTypes.string),
	orientation: PropTypes.oneOf(['vertical', 'horizontal']),
	labelRender: PropTypes.func,
};

export default Legend;
