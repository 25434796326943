import React from 'react';

import { LoadingBar } from 'components/atoms';

import { numberWithCommas } from 'utils';

import DirectorIndicator from './DirectorIndicator';
import EmployeesIndicators from './EmployeesIndicators';
import OfficesIndicators from './OfficesIndicators';
import CountryIndicators from './CountryIndicators';
import OneLineIndicator from './OneLineIndicator';

const EmployeesHeaderPanel = ({ data = null, loading = true, level }) => {
	const {
		director: directorDataSet = [],
		deputy_directors: deputyDirectors,
		gdp_per_capita: gdpPerCapita,
		countries: countriesCount,
		headline,
		employees,
		offices,
		involved_countries: involvedCountries,
		iso_code3: isoCode3,
		head_office: headOffice,
	} = data || {};

	return (
		<div
			className="header-dashboard header-general-dashboard"
			data-testid="general-header-panel"
		>
			<div className="header-wrap">
				{loading ? (
					<div className="loading-container navy-bg">
						<LoadingBar isSmall={false} />
					</div>
				) : (
					<>
						<div
							className="header-wrap__indicators"
							data-testid="GeneralHeaderPanel_indicators"
						>
							<div className="header-list-indicators">
								<div className="header-wrap__director">
									<DirectorIndicator data={directorDataSet} />
									{deputyDirectors && deputyDirectors.length ? (
										<div className="deputy-director-title">
											{deputyDirectors[0].type}
										</div>
									) : (
										''
									)}
									{deputyDirectors &&
										deputyDirectors.map((item, i) => (
											<DirectorIndicator
												data={item}
												key={i}
												className="deputy-director"
											/>
										))}
								</div>
								{!!headOffice && (
									<OneLineIndicator label="Head office" value={headOffice} />
								)}
								{!!countriesCount && (
									<OneLineIndicator
										label="Operations"
										value={numberWithCommas(countriesCount)}
									/>
								)}
								{!!gdpPerCapita && (
									<OneLineIndicator
										label="GDP per capita"
										value={numberWithCommas(gdpPerCapita)}
										postValueSymbol=" USD"
									/>
								)}
								{!!headline && (
									<OneLineIndicator
										label="Inflation"
										value={headline}
										postValueSymbol="%"
									/>
								)}
								{level === 'country' && !!offices && (
									<OfficesIndicators offices={offices} />
								)}
								{level === 'country' && !!involvedCountries && (
									<CountryIndicators countries={involvedCountries} />
								)}
							</div>
							{!!employees && <EmployeesIndicators data={employees} />}
						</div>
						{isoCode3 === 'CHN' && (
							<div className="disclaimer-wrap">
								<div />
								<div className="china-disclaimer">
									Note: China/Beijing Office reports to the Strategic
									Partnerships Division and thus the related HR data rolls into
									HQ data.
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default EmployeesHeaderPanel;
