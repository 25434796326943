import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const Step = ({
	step = 1,
	descriptionList = [],
	label = '',
	image,
	imageProps = {},
	imageClass,
	imageNeedsFill,
}) => (
	<div className="mobile__step">
		<p className="mobile__step-label">
			<strong>Step {step} </strong> {label}
		</p>
		<div className="mobile__step-row">
			{!!image && (
				<div className="mobile__step__image-container">
					<img
						className={classNames('image', {
							'image-fill': imageNeedsFill,
							[imageClass]: !!imageClass,
						})}
						src={image}
						alt="loading"
						{...imageProps}
					/>
				</div>
			)}
			{!!descriptionList && (
				<ul className="mobile__step-list">
					{descriptionList.map((items, i) => (
						<li key={`mobile-step-${label}-${i}`} className="item">
							{items.map((item) => {
								if (item.isBold) {
									return <strong key={item.text}>{item.text}</strong>;
								}
								if (item.isLink) {
									return (
										<a
											key={item.text}
											target="_blank"
											rel="noopener noreferrer"
											className="link"
											href={item.href}
											style={{ textDecoration: 'underline' }}
										>
											{item.text}
										</a>
									);
								}
								return typeof item === 'string' && item;
							})}
						</li>
					))}
				</ul>
			)}
		</div>
	</div>
);

Step.propTypes = {
	step: PropTypes.number.isRequired,
	descriptionList: PropTypes.arrayOf(
		PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					text: PropTypes.string,
					href: PropTypes.string,
					isUnderline: PropTypes.bool,
					isBold: PropTypes.bool,
					isLink: PropTypes.bool,
				}),
			]),
		),
	),
	imageNeedsFill: PropTypes.bool,
	image: PropTypes.string,
	label: PropTypes.string,
};

export default Step;
