/* istanbul ignore file */
/* eslint-disable import/no-import-module-exports */
import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/browser';

import packageJson from '../package.json';

import App from './App';

import 'react-tippy/dist/tippy.css';
import 'tippy.js/dist/tippy.css';
import './scss/main.scss';
import './scss/v2/base.scss';

/* Using Redux for global settings */
Sentry.init({
	dsn: process.env.INFO_APP_SENTRY_DSN,
	environment: process.env.INFO_APP_HOST_ENV,
	release: packageJson.version,
});

const root = document.getElementById('root');
ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	root,
);

if (module.hot) {
	module.hot.accept('./App', () => {
		// eslint-disable-next-line global-require
		const NextApp = require('./App').default;
		ReactDOM.render(
			<BrowserRouter>
				<NextApp />
			</BrowserRouter>,
			root,
		);
	});
}
