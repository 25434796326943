import React from 'react';

import { User } from '@wfp/ui';

import { LoadingBar } from 'components/atoms';

import { getElementByKey } from 'utils';
import CountryDirector from './CountryDirector';

const DirectorIndicator = ({ data, className, loading }) => {
	const type = getElementByKey(data, 'type');
	const contact = getElementByKey(data, 'contact');
	const picture = getElementByKey(contact, 'picture');
	const firstName = getElementByKey(contact, 'first_name');
	const lastName = getElementByKey(contact, 'last_name');

	const userFullName = `${firstName} ${lastName}`;

	const imageData = getElementByKey(picture, 'base64');
	const contentType = getElementByKey(picture, 'content_type');
	const pictureUrl = getElementByKey(picture, 'url');

	let userImage;
	if (imageData && contentType && contentType !== 'application/json')
		userImage = `data:${contentType};base64,${imageData}`;
	else if (pictureUrl) userImage = picture.url;

	return (
		<div
			className={`director-indicator${className ? ` ${className}` : ''}`}
			data-testid="DirectorIndicatorTestID"
		>
			{loading ? (
				<LoadingBar />
			) : (
				<>
					{userImage && userImage !== null ? (
						<img
							alt={userFullName}
							src={userImage}
							className="director-indicator__logo"
						/>
					) : (
						<User
							alt="WFP director"
							className="director-indicator__logo empty"
						/>
					)}

					<div className="director-indicator__info">
						<span className="title">{type ? `${type}` : 'Director'}</span>
						<CountryDirector data={data} />
					</div>
				</>
			)}
		</div>
	);
};

export default DirectorIndicator;
