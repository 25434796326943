import { useEffect, useState } from 'react';

import { getPhoto } from 'auth/azure-service';

export const useProfilePhotoUrl = () => {
	const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);

	useEffect(() => {
		getPhoto()
			.then((response) => (response.status === 200 ? response.blob() : null))
			.then((response) =>
				setProfilePhotoUrl(response ? URL.createObjectURL(response) : response),
			);
	}, []);

	return profilePhotoUrl;
};

export default useProfilePhotoUrl;
