import React from 'react';

import { useBeneficiariesByProgrammeArea } from 'api';

import { InfoSource } from 'components/atoms';
import { ChartIndicatorsPanel } from 'components/templates';

import { getElementByKey } from 'utils';

import Indicators from './Indicators';

export const PANEL_KEY = 'beneficiaries-by-programme-area';

const BeneficiariesPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useBeneficiariesByProgrammeArea(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const infoData = getElementByKey(data, 'info');
	const chartData = getElementByKey(data, 'data', {});

	const totalDatas =
		chartData.total &&
		(chartData.total.actual ||
			chartData.total.planned ||
			chartData.total.quartely);

	return (
		<ChartIndicatorsPanel
			title={
				chartData.planned_year
					? `Beneficiaries (${chartData.planned_year})`
					: 'Beneficiaries'
			}
			panelKey={PANEL_KEY}
			noData={(!chartData.result || !chartData.result.length) && !totalDatas}
			indicatorsComponent={<Indicators data={data} />}
			loading={isLoading}
			indicatorsOnly
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			className="beneficiaries-panel"
		/>
	);
};

export default BeneficiariesPanel;
