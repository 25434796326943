import { getPhoto } from 'auth/azure-service';
import { api } from './base';

function blobToBase64(blob) {
	return new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
}

const getDirectorPhotoUrl = (email) =>
	getPhoto(email)
		.then((response) => (response.status === 200 ? response.blob() : null))
		.then((response) => (response ? blobToBase64(response) : response));

export const getHeaderEmployeesWithPhoto = async (url) =>
	api.get(url).then(async (data) => {
		if (data) {
			if (data.deputy_directors && data.deputy_directors.length > 0) {
				let director;
				let url;
				for (let i = 0; i < data.deputy_directors.length; i++) {
					director = data.deputy_directors[i];
					/* eslint-disable */
					url = await getDirectorPhotoUrl(director.contact.email);
					/* eslint-disable */
					director.contact.picture = { url };
				}
			}

			if (data.director) {
				const url = await getDirectorPhotoUrl(data.director.contact.email);
				data.director.contact.picture = { url };
			}
		}

		return data;
	});
