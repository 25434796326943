import React from 'react';

import { Tooltip } from 'react-tippy';

import { DashLegend } from 'components/atoms';
import { BarIndicatorWithLabels } from 'components/molecules';
import { TooltipContent } from 'components/molecules/BarIndicatorWithLabels';

import tooltipStyle from 'constants/tooltipStyle';

import { useWindowSize } from 'hooks';

import { getHumanNumberFormat, getPercents } from 'utils';

const ConfirmedBars = ({
	confirmedContribution,
	isCountryLevel,
	isRegionLevel,
}) => {
	const [width] = useWindowSize();
	const isDirected = isCountryLevel || isRegionLevel;

	let currentTotal;
	let confirmedContributionData = [];

	if (confirmedContribution && confirmedContribution.total) {
		confirmedContributionData = confirmedContribution.total;
		const sortedData = confirmedContributionData.sort((a, b) =>
			a.label > b.label ? -1 : 1,
		);
		currentTotal = sortedData[0].value;
	}

	return (
		<div className="donors-bars__confirmed">
			<div className="indicators-row__title__container">
				<h4 className="indicators-row__title">
					{isDirected ? 'Directed' : 'Confirmed'} contribution from top 10
					donors
				</h4>
				<div className="indicators-row__subtitle">
					In USD and % of total {isDirected ? 'directed' : 'confirmed'}{' '}
					contribution (
					<Tooltip
						{...tooltipStyle}
						theme="dark"
						position="bottom"
						className="tooltip"
						html={
							<TooltipContent
								values={confirmedContributionData}
								title={
									isDirected
										? 'Total directed contribution'
										: 'Total confirmed contribution'
								}
							/>
						}
					>
						{`${getHumanNumberFormat(currentTotal)} USD`}
					</Tooltip>
					)
				</div>
			</div>
			{confirmedContribution.data.map((item, i) => (
				<BarIndicatorWithLabels
					tooltipData={{ values: item.tooltip }}
					withTooltip
					key={`${item.label}-${item.value}-confirmed`}
					truncateWidth={width < 375 ? 120 : 180}
					order={i + 1}
					{...item}
					length={getPercents(item.value, confirmedContribution.maxValue)}
				/>
			))}
			<DashLegend legend={confirmedContribution.legend} />
		</div>
	);
};

export default ConfirmedBars;
