import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import { getHumanNumberWithSymbol } from 'utils';

import LoadingBar from '../LoadingBar';

const getValue = ({ valueType, value, toFixed }) => {
	const _valueType = valueType.toLowerCase();

	let symbolBefore = '';
	let symbolAfter = '';

	if (['us', 'usd'].includes(_valueType)) {
		symbolBefore = 'USD ';
		[value, symbolAfter] = getHumanNumberWithSymbol(value, toFixed);
	}

	if (['percents', 'percent'].includes(_valueType)) {
		symbolAfter = '%';
	}

	if (['number', 'numbers'].includes(_valueType)) {
		[value, symbolAfter] = getHumanNumberWithSymbol(value, 0);
	}

	if (['decimal', 'float'].includes(_valueType)) {
		[value, symbolAfter] = getHumanNumberWithSymbol(value, toFixed);
	}

	return (
		<>
			<span className="symbol-before">{symbolBefore}</span>
			<span>{value}</span>
			<span className="symbol-after">{symbolAfter}</span>
		</>
	);
};

const Indicator = ({
	valueType,
	value,
	toFixed,
	horizontal,
	center,
	hide,
	className,
	loading,
	labelBold,
	label,
	secondLabel,
	grayLabel,
}) => (
	<div
		className={cn(className, {
			indicator: true,
			vertical: !horizontal,
			horizontal,
			center,
			hide,
		})}
		data-testid="indicator_component"
	>
		{loading ? (
			<LoadingBar />
		) : (
			<>
				<div className="indicator__value">
					{getValue({ value, valueType, toFixed })}
				</div>

				<div
					className={cn({
						indicator__labels: true,
						center: center && horizontal,
					})}
					data-testid="Indicator_label"
				>
					<div
						className={cn({
							indicator__label: true,
							bold: labelBold,
						})}
					>
						{label}
					</div>

					<div
						className="indicator__second-label"
						data-testid="Indicator_second_label"
					>
						{secondLabel}
					</div>
					<div
						className="indicator__gray-label"
						data-testid="Indicator_gray_label"
					>
						{grayLabel}
					</div>
				</div>
			</>
		)}
	</div>
);

Indicator.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.any,
	secondLabel: PropTypes.any,
	grayLabel: PropTypes.any,
	labelBold: PropTypes.bool,
	horizontal: PropTypes.bool,
	center: PropTypes.bool,
	valueType: PropTypes.string,
	className: PropTypes.string,
	hide: PropTypes.bool,
	loading: PropTypes.bool,
};

Indicator.defaultProps = {
	value: null,
	label: null,
	secondLabel: null,
	grayLabel: null,
	labelBold: false,
	horizontal: false,
	center: false,
	valueType: 'string',
	className: '',
	hide: false,
	loading: false,
};

export default Indicator;
