import React from 'react';
import { useSelector } from 'react-redux';

import { selectConfig, selectMenu } from 'features/global/selectors';

const ACR_URL = 'https://www.wfp.org/operations/annual-country-report/';

const CountryLinks = ({ countryCode, regionCode }) => {
	const { ACR_YEAR } = useSelector(selectConfig);
	const { countries } = useSelector(selectMenu);

	const acrLinks = [];

	// build csp code
	let cspParam = 'global';

	if ((regionCode || (!regionCode && !countryCode)) && ACR_YEAR) {
		acrLinks.push(
			<a
				key="region-global-link"
				className="acr-link"
				target="_blank"
				href={`https://www.wfp.org/annual-country-reports-${ACR_YEAR}`}
				rel="noreferrer"
			>
				ACR {ACR_YEAR}
			</a>,
		);
	}

	if (regionCode) {
		cspParam = regionCode;
	}

	if (countryCode) {
		const country = countries.find((i) => i.iso_code3 === countryCode);

		if (country && country.iso_code) {
			cspParam = `country/${country.iso_code}`;

			// add ACR links only for countries
			const cspCodes = country.csp_codes || [];
			const useFullName = cspCodes.length !== 1;

			cspCodes.forEach((code) => {
				const operationParam = `?operation_id=${code}`;
				const link = `${ACR_URL}${operationParam}&year=${ACR_YEAR}`;
				let name = `ACR ${ACR_YEAR}`;

				if (useFullName) {
					name = `${name} (${code})`;
				}

				acrLinks.push(
					<a
						key={code}
						className="acr-link"
						target="_blank"
						href={link}
						rel="noreferrer"
					>
						{name}
					</a>,
				);
			});
		}
	}

	const cspLink = `https://cspdata.wfp.org/${cspParam}`;

	return (
		<div className="links">
			<a className="csp-link" target="_blank" href={cspLink} rel="noreferrer">
				CSP Data
			</a>
			{acrLinks}
		</div>
	);
};

export default CountryLinks;
