import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getResources = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_RESOURCES;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_RESOURCES}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_RESOURCES}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useResources = ({ countryCode, regionCode }, options) =>
	useQuery({
		queryFn: () => getResources({ countryCode, regionCode }),
		...options,
	});
