import React from 'react';
import { useSelector } from 'react-redux';

import { SubNavigationGroup, SubNavigationItem } from '@wfp/ui';

import { ExtLink } from 'components/molecules';

import { selectFavorites, selectMenu } from 'features/global/selectors';

import { getElementByKey } from 'utils';

const FavoriteItems = () => {
	const menuData = useSelector(selectMenu);
	const favorites = useSelector(selectFavorites) || [];
	const countries = getElementByKey(menuData, 'countries', []);
	const regions = getElementByKey(menuData, 'regions', []);

	const favRegions = favorites
		.filter((item) => item !== 'global' && item.startsWith('RB'))
		.map((item) => regions.find((i) => i.code === item))
		.sort((a, b) => a.name.localeCompare(b.name));

	const favCountries = favorites
		.filter((item) => item !== 'global' && !item.startsWith('RB'))
		.map((item) => countries.find((i) => i.code === item))
		.sort((a, b) => a.name.localeCompare(b.name));

	const links = [...favRegions, ...favCountries].map((menuItem) => {
		if (menuItem) {
			return (
				<SubNavigationItem key={menuItem.code}>
					<ExtLink
						index={menuItem.code}
						type={menuItem.code.startsWith('RB') ? 'regions' : 'countries'}
						activeClassName="active"
						subTab="overview"
						favorite
						exact
					>
						{menuItem.name}
					</ExtLink>
				</SubNavigationItem>
			);
		}
		return null;
	});

	if (links.length)
		return (
			<div>
				<SubNavigationGroup title="Favorites">{links}</SubNavigationGroup>
			</div>
		);
	return null;
};

const FavoriteLinks = () => {
	const favorites = useSelector(selectFavorites);
	if (!favorites.length)
		return (
			<div className="menu__nofavorites">
				<h4>No favorites saved</h4>
				<p>
					Click a star icon in the Countries or Regions menu to add favorites
				</p>
			</div>
		);

	return (
		<div className="menu__list">
			<FavoriteItems />
		</div>
	);
};

export default FavoriteLinks;
