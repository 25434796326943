function getSubTab(location, divided) {
	const parts = location.pathname.split('/');
	const { hash } = location;
	let subTab = parts[parts.length - 1] || '';
	const availableTabs = ['overview', 'operation', 'resources', 'planvsactuals'];
	if (!availableTabs.includes(subTab)) subTab = 'overview';
	return divided ? { subTab, hash } : `${subTab}${hash}`;
}

export default getSubTab;
