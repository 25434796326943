import React from 'react';

import { Tooltip } from 'react-tippy';

import { SimpleIndicator } from 'components/atoms';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat } from 'utils';

const IDPsBreakDownIndicator = ({ data }) => {
	const { total, conflict, disaster } = data || {};

	const totalHuman = getHumanNumberFormat(total);
	const conflictHuman = getHumanNumberFormat(conflict);
	const disasterHuman = getHumanNumberFormat(disaster);

	return (
		<Tooltip
			{...tooltipStyle}
			className="wfp-tooltip wfp-tooltip-v2"
			interactive
			trigger="click"
			position="bottom"
			html={
				<div className="breakdown-list">
					<div className="breakdown-list__item-container">
						<div className="breakdown-list__item">
							<div>Total IDPs</div>
							<div>
								<div>{total ? totalHuman : 'N/A'}</div>
							</div>
						</div>
						<div className="extra-data-wrap">
							<div className="extra-data-wrap__inner">
								<div className="extra-data-wrap__item">
									<div>Conflict IDPs</div>
									<div>{conflict ? conflictHuman : 'N/A'}</div>
								</div>
								<div className="extra-data-wrap__item">
									<div>Disaster IDPs</div>
									<div>{disaster ? disasterHuman : 'N/A'}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		>
			<SimpleIndicator
				isBreakdown
				label="Total IDPs"
				data={total ? totalHuman : 'N/A'}
			/>
		</Tooltip>
	);
};

export default IDPsBreakDownIndicator;
