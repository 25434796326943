import React from 'react';

import { Toggle } from '@wfp/ui';

const Switch = ({ label, onChange, checked, ...rest }) => (
	<div className="switch">
		<span className="switch__label">{label}</span>
		<div className="switch__control__container">
			<Toggle
				onToggle={onChange}
				toggled={checked}
				labelA="Condensed"
				labelB="Full"
			/>
		</div>
	</div>
);

export default Switch;
