import React, { useState } from 'react';

import { Modal } from '@wfp/ui';

const BrowserSupportIndicator = () => {
	const [open, setOpen] = useState(true);

	const ua = navigator.userAgent;
	// is Internet Explorer
	const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

	const edgeLink = 'https://www.microsoft.com/windows/microsoft-edge';
	const chromeLink = 'https://www.google.com/intl/eu/chrome/';
	const firefoxLink = 'https://www.mozilla.org/firefox/new/';

	if (isIE) {
		return (
			<Modal
				open={open}
				onRequestClose={() => setOpen(false)}
				className="ie-warring"
				onRequestSubmit={() => setOpen(false)}
				primaryButtonText="Continue with current browser"
			>
				<div className="ie-warring__pre-title">WARNING</div>
				<div className="ie-warring__title">
					It looks like you are using Internet Explorer 11 as your favourite
					browser
				</div>
				<div className="ie-warring__content">
					<p>
						We recommend that you switch to a more modern browser to ensure that
						you get the best experience out of this application
					</p>
					<p>
						Recommended browser: &nbsp;
						<a href={edgeLink} target="_blank" rel="noreferrer">
							IE Edge
						</a>
						, &nbsp;
						<a href={chromeLink} target="_blank" rel="noreferrer">
							Chrome
						</a>
						&nbsp;or{' '}
						<a href={firefoxLink} target="_blank" rel="noreferrer">
							Firefox
						</a>
						.
					</p>
				</div>
			</Modal>
		);
	}

	return null;
};

export default BrowserSupportIndicator;
