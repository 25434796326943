import React from 'react';

import { InfoSource } from 'components/atoms';

import { InfoHoverText } from 'components/templates/ChartIndicatorsPanel';

import InfoIndicator from '../InfoIndicator';

const HeaderInfo = ({ data }) => (
	<div className="header-info">
		<InfoIndicator
			hoverText={<InfoHoverText />}
			title={data ? data.title : null}
			isHover={false}
			parentName="outlook"
			theme="light"
		>
			<InfoSource text={data ? data.text : null} />
		</InfoIndicator>
	</div>
);

export default HeaderInfo;
