import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import cn from 'classnames';

const TabLink = ({ href, label }) => {
	const { pathname } = useLocation();

	const routes = [
		'overview',
		'management',
		'programme',
		'resources',
		'planvsactuals',
	];
	const lastPath = pathname?.split('/').slice(-1)[0] || routes[0];

	let path;

	if (routes.includes(lastPath)) {
		path = pathname?.replace(`/${lastPath}`, '') || '';
	}

	let to = `${path}/${href}`;

	if (path === '/') {
		to = `/${href}`;
	}

	if (!href) {
		to = path;
	}

	return (
		<NavLink
			to={{ pathname: to }}
			className={({ isActive }) => cn({ active: isActive })}
		>
			{label}
		</NavLink>
	);
};

export default TabLink;
