import React from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';

import { MsalProvider } from '@azure/msal-react';

import AuthProvider from 'auth/AuthProvider';
import { msalInstance } from 'auth/azure-service';

import { Wrapper } from '@wfp/ui';

import {
	GlobalTabs,
	BrowserSupportIndicator,
	Header,
	Footer,
	EnvironmentIndicator,
} from 'components/organisms';

import {
	OperationPage,
	PlanPage,
	ResourcesPage,
	ErrorPage,
	MobilePage,
} from 'components/pages';

import {
	GoogleAnalytics,
	LocalStorageCleanup,
	RouteChecker,
	OverviewRedirect,
} from 'components/support';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { setupStore } from './store';

const queryClient = new QueryClient();
const store = setupStore();

const App = () => {
	const location = useLocation();
	const routesWithoutGlobalTabs = ['/mobile', '/error-page'];

	return (
		<div className="App">
			<MsalProvider instance={msalInstance}>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<AuthProvider forceLogin>
							<div className="content-wrap">
								<BrowserSupportIndicator />
								<EnvironmentIndicator />
								<LocalStorageCleanup />
								<RouteChecker />
								<GoogleAnalytics />
								<Header />
								{routesWithoutGlobalTabs.includes(location.pathname) || (
									<GlobalTabs />
								)}
								<Routes>
									<Route path="/mobile" element={<MobilePage />} />
									<Route path="/error-page" element={<ErrorPage />} />
									<Route
										element={
											<div className="content">
												<Wrapper pageWidth="lg">
													<Outlet />
												</Wrapper>
											</div>
										}
									>
										<Route
											exact
											path="/operation"
											element={<OverviewRedirect />}
										/>
										<Route exact path="/overview" element={<OperationPage />} />
										<Route
											exact
											path="/resources"
											element={<ResourcesPage />}
										/>
										<Route exact path="/planvsactuals" element={<PlanPage />} />
										<Route
											path="/countries/:countryCode/operation"
											element={<OverviewRedirect />}
										/>
										<Route
											path="/countries/:countryCode/overview"
											element={<OperationPage />}
										/>
										<Route
											exact
											path="/countries/:countryCode/resources"
											element={<ResourcesPage />}
										/>
										<Route
											exact
											path="/countries/:countryCode/planvsactuals"
											element={<PlanPage />}
										/>
										<Route
											exact
											path="/countries/:countryCode"
											element={<OverviewRedirect />}
										/>
										<Route
											path="/regions/:regionCode/operation"
											element={<OverviewRedirect />}
										/>
										<Route
											path="/regions/:regionCode/overview"
											element={<OperationPage />}
										/>
										<Route
											exact
											path="/regions/:regionCode/resources"
											element={<ResourcesPage />}
										/>
										<Route
											exact
											path="/regions/:regionCode/planvsactuals"
											element={<PlanPage />}
										/>
										<Route
											exact
											path="/regions/:regionCode"
											element={<OverviewRedirect />}
										/>
									</Route>
								</Routes>
								<Footer />
							</div>
						</AuthProvider>
					</Provider>
				</QueryClientProvider>
			</MsalProvider>
		</div>
	);
};

export default App;
