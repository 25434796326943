import { useQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import appConfig from 'config';

import { api } from './base';

const getDonors = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_DONORS;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_DONORS}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_DONORS}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useDonors = ({ countryCode, regionCode }, options) =>
	useQuery({
		queryFn: () => getDonors({ countryCode, regionCode }),
		...options,
	});
