import React from 'react';

import { NoData } from 'components/atoms';
import { BarIndicatorWithLabels } from 'components/molecules';

import { getElementByKey } from 'utils';

const ProjectedOperationalIndicators = ({ data }) => {
	const focusArea = getElementByKey(data, 'op_req_by_focus_area', []);
	const transferModality = getElementByKey(
		data,
		'op_req_by_transfer_modality',
		[],
	);

	const renderIndicators = (data) => {
		const maxValue = Math.max(...data.map(({ value }) => value));
		return data.map((item) => {
			// let showExtraData = false;

			// if (Array.isArray(item.value)) {
			// 	if (item.value.length > 1) showExtraData = true;
			// }

			const baseProps = {
				value: item.value,
				label: item.label,
				length: (item.value / maxValue) * 100,
				truncateWidth: 200,
			};
			return (
				<BarIndicatorWithLabels
					{...baseProps}
					key={`bar-indicator-${item.label}-${item.value}`}
					// extraData={showExtraData ? item.value : null}
				/>
			);
		});
	};

	return (
		<div className="projected-indicators__container">
			<div className="indicators-focus-area">
				<div className="indicators-row__title__container">
					<div className="indicators-row__title">By focus area</div>
					<div className="indicators-row__subtitle">In USD</div>
				</div>
				{focusArea.length ? renderIndicators(focusArea) : <NoData />}
			</div>
			<div className="indicators-transfer-modality">
				<div className="indicators-row__title__container">
					<div className="indicators-row__title">By transfer modality</div>
					<div className="indicators-row__subtitle">In USD</div>
				</div>
				{transferModality.length ? (
					renderIndicators(transferModality)
				) : (
					<NoData />
				)}
			</div>
		</div>
	);
};

export default ProjectedOperationalIndicators;
