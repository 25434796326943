import React from 'react';

import { NoData } from 'components/atoms';

import { keyLabelMap } from 'utils';
import BarIndicatorWithLabels from '../BarIndicatorWithLabels';

const BreakdownByResidenceStatus = ({ residenceStatus: data }) => {
	if (!data || !Object.keys(data).length) return <NoData height={50} />;

	const colorsList = ['#0780C0', '#73C3FE', '#9DEAFF', '#C8EEF7', '#D6DDED'];
	let colorCounter = -1;
	return (
		<div>
			{Object.keys(keyLabelMap).map((key) => {
				if (!data || !data[key]) return null;
				colorCounter += 1;
				const objData = data[key];
				const value = objData ? objData.value : 'N/A';
				const percentage = objData ? +objData.percentage.toFixed(1) : 'N/A';
				const length = objData ? objData.percentage : 0;

				return (
					<div className="header-dashboard-item__indicator" key={key}>
						<BarIndicatorWithLabels
							label={keyLabelMap[key] || key}
							labelClassName="header-dashboard-item__indicator__label"
							truncateWidth={85}
							value={value}
							color={colorsList[colorCounter] || '#0780C0'}
							percents={percentage}
							length={length}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default BreakdownByResidenceStatus;
