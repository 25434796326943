import { combineReducers, configureStore } from '@reduxjs/toolkit';

import globalReducer from 'features/global/globalSlice';
import officeReducer from 'features/office/officeSlice';

const rootReducer = combineReducers({
	global: globalReducer,
	office: officeReducer,
});

export const setupStore = (preloadedState) =>
	configureStore({
		reducer: rootReducer,
		preloadedState,
	});
