import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	HeaderGlobalDashboard,
	HeaderRegionDashboard,
	HeaderCountryDashboard,
	TopCountriesPanel,
	RegionalBureausPanel,
	BeneficiariesPanel,
	PartnersPanel,
	ResourcesPanel,
	DonorsPanel,
	OutputIndicatorsPanel,
	LocalEconomiesPanel,
	ProjectedRequirementsPanel,
} from 'components/organisms';

import { useScrollOnTabChange } from 'hooks';

import { selectIsAdvanced } from 'features/global/selectors';

const OperationPage = () => {
	const headerRef = useRef();
	const { regionCode, countryCode } = useParams();
	useScrollOnTabChange();

	const isAdvancedMode = useSelector(selectIsAdvanced);
	const isGlobal = !countryCode && !regionCode;

	let headerDashboard;
	if (countryCode)
		headerDashboard = <HeaderCountryDashboard code={countryCode} />;
	else if (regionCode)
		headerDashboard = <HeaderRegionDashboard code={regionCode} />;
	else headerDashboard = <HeaderGlobalDashboard />;

	return (
		<>
			<div ref={headerRef}>{headerDashboard}</div>

			<div className="main-dashboard__panels">
				<BeneficiariesPanel countryCode={countryCode} regionCode={regionCode} />
			</div>

			{isGlobal && (
				<div className="main-dashboard__panels">
					<OutputIndicatorsPanel />
				</div>
			)}

			{isGlobal && isAdvancedMode && (
				<div className="main-dashboard__panels">
					<RegionalBureausPanel />
				</div>
			)}

			{!countryCode && (
				<div className="main-dashboard__panels">
					<TopCountriesPanel regionCode={regionCode} />
				</div>
			)}

			{!isAdvancedMode && (
				<>
					<div className="main-dashboard__panels">
						<ResourcesPanel countryCode={countryCode} regionCode={regionCode} />
					</div>
					<div className="main-dashboard__panels">
						<ProjectedRequirementsPanel
							countryCode={countryCode}
							regionCode={regionCode}
						/>
						<LocalEconomiesPanel
							countryCode={countryCode}
							regionCode={regionCode}
						/>
					</div>

					<div className="main-dashboard__panels">
						<DonorsPanel countryCode={countryCode} regionCode={regionCode} />
					</div>

					<div className="main-dashboard__panels">
						<PartnersPanel countryCode={countryCode} regionCode={regionCode} />
					</div>
				</>
			)}
		</>
	);
};

export default OperationPage;
