import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
	Breadcrumb,
	BreadcrumbHome,
	BreadcrumbItem,
	SecondaryNavigation,
	SecondaryNavigationTitle,
} from '@wfp/ui';

import { selectGlobal, selectIsAdvanced } from 'features/global/selectors';
import { setOffice } from 'features/office/officeSlice';
import { selectTitle } from 'features/office/selectors';

import { getCountryCode, getElementByKey, getRegionCode } from 'utils';

import EmergencyTags from './EmergencyTags';
import Tabs from './Tabs';
import CountryLinks from './CountryLinks';

const GlobalTabs = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const countryCode = getCountryCode();
	const regionCode = getRegionCode();

	const { menu } = useSelector(selectGlobal);
	const title = useSelector(selectTitle);
	const isAdvancedMode = useSelector(selectIsAdvanced);

	const [currentRegion, setCurrentRegion] = useState(null);

	useEffect(() => {
		let title = '';
		let office = {};

		if (countryCode && menu.countries) {
			const countryItem = menu.countries.find((i) => i.code === countryCode);
			const regionCode = getElementByKey(countryItem, 'parent');

			if (regionCode && menu.regions) {
				setCurrentRegion(menu.regions.find((i) => i.code === regionCode));
			}

			title = getElementByKey(countryItem, 'name', '');
			office = countryItem;
		} else if (regionCode && menu.regions) {
			const regionItem = menu.regions.find((i) => i.code === regionCode);

			office = regionItem;
			title = getElementByKey(regionItem, 'name', '').replace('Office', '');
		} else {
			title = 'Global';
		}

		dispatch(setOffice({ ...office, title }));
	}, [countryCode, regionCode, menu]);

	if (location.pathname === '/') {
		navigate({
			pathname: '/overview',
			search: location.search,
		});

		return null;
	}

	return (
		<>
			<Breadcrumb className="breadcrumbs wfp--wrapper wfp--wrapper--width-lg">
				<BreadcrumbItem>
					<NavLink to={{ pathname: '/overview' }}>
						<BreadcrumbHome />
					</NavLink>
				</BreadcrumbItem>
				{!!countryCode && !!currentRegion && (
					<BreadcrumbItem>
						<NavLink
							to={{ pathname: `/regions/${currentRegion.code}/overview` }}
						>
							{currentRegion.name}
						</NavLink>
					</BreadcrumbItem>
				)}
				<BreadcrumbItem disableLink>
					<span>{title}</span>
				</BreadcrumbItem>
			</Breadcrumb>
			<SecondaryNavigation additional="" className="tabs" pageWidth="lg">
				<div
					style={{
						position: 'sticky',
						top: 0,
						zIndex: 10000,
					}}
				>
					<SecondaryNavigationTitle className="tabs__emergency-title">
						<div className="title-wrap">
							{!!countryCode && <EmergencyTags country_name={title} />}
							{!!title && (
								<>
									<div className="title">{title}</div>
									<CountryLinks
										countryCode={countryCode}
										regionCode={regionCode}
									/>
								</>
							)}
						</div>
					</SecondaryNavigationTitle>
					{isAdvancedMode && <Tabs />}
				</div>
			</SecondaryNavigation>
		</>
	);
};

export default GlobalTabs;
