import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { scrollToRef } from 'utils';

const useScrollToPanel = (panelKey, ref, loading) => {
	const location = useLocation();

	useEffect(() => {
		const { hash } = location;
		const key = hash.replace('#', '');
		if (key === panelKey && !loading) {
			if (ref && ref.current) {
				scrollToRef(ref, -200);
			}
		}
	}, [location.key, loading]);
};

export default useScrollToPanel;
