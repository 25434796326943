import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getBeneficiariesByProgrammeArea = async ({ countryCode, regionCode }) => {
	let apiUrl = appConfig.API_V2_URLS.GLOBAL_BENEFICIARIES;

	if (countryCode) {
		apiUrl = `${appConfig.API_V2_URLS.COUNTRY_BENEFICIARIES}${countryCode}/`;
	}

	if (regionCode) {
		apiUrl = `${appConfig.API_V2_URLS.REGION_BENEFICIARIES}${regionCode}/`;
	}

	return api.get(apiUrl);
};

export const useBeneficiariesByProgrammeArea = (
	{ countryCode, regionCode },
	options,
) =>
	useQuery({
		queryFn: () => getBeneficiariesByProgrammeArea({ countryCode, regionCode }),
		...options,
	});
