import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'react-tippy';

import { BarIndicator } from 'components/atoms';
import { TooltipContent } from 'components/molecules/BarIndicatorWithLabels';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat } from 'utils';

const MultipleLabel = ({ value, labelSymbol, multipleValues, label }) => {
	const mainText = `${getHumanNumberFormat(value)} ${labelSymbol}`;
	const correctData = multipleValues.map((item) => ({
		label: item.year,
		value: item.total,
	}));

	return (
		<Tooltip
			{...tooltipStyle}
			className="wfp-tooltip wfp-tooltip-v2"
			theme="dark"
			interactive
			trigger="mouseenter"
			position="bottom"
			html={<TooltipContent title={label} values={correctData} />}
		>
			<div className="current-value">{mainText}</div>
		</Tooltip>
	);
};

const BarIndicatorColumn = ({
	value,
	length,
	label,
	color,
	indicatorLabelAdditionSymbol = '',
	multipleValues,
	...otherProps
}) => (
	<div className="bar-indicator-column">
		<p className="bar-indicator-column__label">{label}</p>
		<BarIndicator
			value={value}
			withValueText={false}
			length={length * 0.8}
			color={color}
			label={
				<MultipleLabel
					value={value}
					label={label}
					multipleValues={multipleValues}
					labelSymbol={indicatorLabelAdditionSymbol}
				/>
			}
		/>
	</div>
);

BarIndicatorColumn.propTypes = {
	order: PropTypes.number,
	label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	percents: PropTypes.number,
	multipleValues: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		}),
	),
	isMultiple: PropTypes.bool,
	withTooltip: PropTypes.bool,
	tooltipData: PropTypes.shape({
		values: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
				label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			}),
		),
	}),
};

export default BarIndicatorColumn;
