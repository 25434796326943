import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@wfp/ui';
import { iconHeaderHamburger } from '@wfp/icons';

import { gaHamburgerMenuOptionEvent } from 'anatytics';

import colors from 'constants/colors';

const Navigation = ({ tabs = [], onTabSelect }) => (
	<div className="hamburger-menu" data-testid="hamburger-menu">
		{tabs.map((tab) => (
			<div
				className="hamburger-menu__item"
				key={`tab-${tab.label}-${tab.href}`}
			>
				<NavLink
					data-testid={tab.href}
					onClick={onTabSelect}
					to={tab.href}
					className="hamburger-menu__item-label"
				>
					{tab.label}
				</NavLink>
				{!!tab.panels.length && (
					<div className="hamburger-menu__panels">
						{tab.panels.map((panel) => (
							<NavLink
								data-testid={`${tab.href}#${panel.href}`}
								key={`panel-${panel.label}-${panel.href}`}
								onClick={() => {
									gaHamburgerMenuOptionEvent(panel.href);
									onTabSelect();
								}}
								exact
								to={{ pathname: tab.href, hash: `#${panel.href}` }}
								className="hamburger-menu__panel-label"
							>
								{panel.label}
							</NavLink>
						))}
					</div>
				)}
			</div>
		))}
	</div>
);

const HamburgerMenu = ({ tabs }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleTabSelect = () => setIsOpen(false);

	const handleClickHamburger = () => {
		setIsOpen((prev) => !prev);
	};

	return (
		<div className="hamburger-container">
			<Icon
				data-testid="hamburger-handler"
				className={`hamburger-icon ${isOpen ? 'active' : ''}`}
				onClick={handleClickHamburger}
				fill={isOpen ? colors.primary : '#000'}
				icon={iconHeaderHamburger}
				width="40"
				height="35"
			/>
			{isOpen && <Navigation onTabSelect={handleTabSelect} tabs={tabs} />}
		</div>
	);
};

export default HamburgerMenu;
