import React from 'react';
import PropTypes from 'prop-types';

import { useSdgTargets } from 'api';

import { InfoSource } from 'components/atoms';
import { HorizontalBarChart } from 'components/molecules';
import { ChartIndicatorsPanel } from 'components/templates';

import { getElementByKey } from 'utils';

const GroupedSwitcherIndicators = ({ data }) => (
	<>
		<div className="title__subtitle">In USD and excludes DSC and ISC</div>
		<HorizontalBarChart maxRowAmmount={2} data={data} />
	</>
);

export const PANEL_KEY = 'sdg-targets';
const PANEL_TITLE = 'SDG targets';

/* !!!New CRF!!! */
// It means that the component uses a data model of 2023 years and above.
const SDGTargetsPanel = ({ countryCode, regionCode }) => {
	const { data, isLoading } = useSdgTargets(
		{ countryCode, regionCode },
		{
			queryKey: [countryCode, regionCode, PANEL_KEY],
		},
	);

	const strategicRes = getElementByKey(data, 'data', []);
	const infoData = getElementByKey(data, 'info');
	const year = getElementByKey(data, 'year');

	return (
		<ChartIndicatorsPanel
			title={`${PANEL_TITLE}${year ? ` (${year})` : ''}`}
			className="sdg-targets-panel"
			panelKey={PANEL_KEY}
			indicatorsComponent={<GroupedSwitcherIndicators data={strategicRes} />}
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
			loading={isLoading}
			noData={!data || !data.data || !data.data.length}
			indicatorsOnly
		/>
	);
};

SDGTargetsPanel.propTypes = {
	countryCode: PropTypes.string,
	regionCode: PropTypes.string,
};

SDGTargetsPanel.defaultProps = {
	countryCode: null,
	regionCode: null,
};

export default SDGTargetsPanel;
