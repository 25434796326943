import React from 'react';

import { Legend } from 'components/atoms';

import FocusAreasItem from './FocusAreasItem';

const FocusAreasCharts = ({ data }) => {
	const lines = ['crisis_response', 'root_causes', 'resilience_building'].sort(
		(a, b) => a.localeCompare(b),
	);

	const labelMap = {
		root_causes: {
			label: 'Root causes',
		},
		crisis_response: {
			label: 'Crisis response',
		},
		resilience_building: {
			label: 'Resilience building',
		},
	};

	if (!data) return null;

	return (
		<>
			<div className="focus-areas-charts">
				<FocusAreasItem
					item={data.needs_based_plan}
					title="Approved needs-based plan"
					lines={lines}
					labelMap={labelMap}
				/>
				<FocusAreasItem
					item={data.original_implementation_plan}
					title="Updated implementation plan"
					lines={lines}
					labelMap={labelMap}
				/>
				<FocusAreasItem
					item={data.expenditures}
					title="Expenditures"
					lines={lines}
					labelMap={labelMap}
				/>
			</div>
			<Legend
				orientation="horizontal"
				colorScale={['#005F9B', '#4AA1E2', '#9DEAFF']}
				labelMap={labelMap}
				lines={lines}
			/>
		</>
	);
};

export default FocusAreasCharts;
