import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	useCountryHeaderEmployees,
	useCountryHeaderBeneficiaries,
	useCountryHeaderPopulation,
} from 'api';

import {
	ExportLinks,
	EmployeesHeaderPanel,
	BeneficiariesHeaderPanel,
	PopulationHeaderPanel,
	HeaderInfo,
	HeaderBottomIndicators,
} from 'components/molecules';

import { useScrollToPanel, useContentRefLayout } from 'hooks';

import { selectCurrentYear } from 'features/global/selectors';

import { exportRequest, getElementByKey, getHumanNumberFormat } from 'utils';

const HeaderCountryDashboard = ({ code }) => {
	const { data: employeesData, isLoading: isEmployeesLoading } =
		useCountryHeaderEmployees(
			{ code },
			{
				queryKey: [code, 'country-header-employees'],
			},
		);

	const { data: beneficiariesData, isLoading: isBeneficiariesLoading } =
		useCountryHeaderBeneficiaries(
			{ code },
			{
				queryKey: [code, 'country-header-beneficiaries'],
			},
		);

	const { data: populationData, isLoading: isPopulationLoading } =
		useCountryHeaderPopulation(
			{ code },
			{
				queryKey: [code, 'country-header-population'],
			},
		);

	const containerRef = useRef(null);
	const { countryCode } = useParams();

	const allIsLoading =
		isEmployeesLoading && isBeneficiariesLoading && isPopulationLoading;
	useScrollToPanel('outlook', containerRef, allIsLoading);

	let infoData = getElementByKey(employeesData, 'info');
	if (infoData && infoData.length) {
		infoData = infoData.find((obj) => obj.indicator === 'header');
	}

	const year = useSelector(selectCurrentYear);

	// for showProjectedDisclaimer issue
	const {
		approved_beneficiaries: approvedBeneficiaries = [],
		need_based_plan: needBasedPlan = [],
		operational_requirements: operationalRequirements = [],
	} = employeesData || {};

	const {
		target_beneficiaries: targetBeneficiaries = {
			current: { data: null },
			next: { data: null },
		},
	} = beneficiariesData || {};

	const approvedBeneficiariesCurrent =
		approvedBeneficiaries.find((ind) => +ind.year === +year) || {};
	const targetBeneficiariesCurrent = targetBeneficiaries.current || {};
	const needBasedPlanCurrent =
		needBasedPlan.find((ind) => +ind.year === +year) || {};
	const operationalRequirementsCurrent =
		operationalRequirements.find((ind) => +ind.year === +year) || {};

	const showProjectedDisclaimer =
		![0, null].includes(approvedBeneficiariesCurrent.value) &&
		![0, null].includes(targetBeneficiariesCurrent.data) &&
		getHumanNumberFormat(approvedBeneficiariesCurrent.value) ===
			getHumanNumberFormat(targetBeneficiariesCurrent.data) &&
		getHumanNumberFormat(operationalRequirementsCurrent.value) !==
			getHumanNumberFormat(needBasedPlanCurrent.value);

	const { html, pdfEnabled } = useContentRefLayout({
		loading:
			isEmployeesLoading || isBeneficiariesLoading || isPopulationLoading,
		title: `Outlook (${year})`,
		contentRef: containerRef,
		infoText: infoData ? infoData.text : '',
		withMarkupRender: false,
		additionalMarkup: `<h1 class="wfp--module__title">Outlook (${year})</h1>`,
	});

	return (
		<>
			<div
				className="header-panel-dashboard country"
				ref={containerRef}
				data-testid="header-country-dashboard"
			>
				<div className="header-panel-dashboard__wrap country">
					<EmployeesHeaderPanel
						level="country"
						data={employeesData}
						loading={isEmployeesLoading}
					/>
					<BeneficiariesHeaderPanel
						level="country"
						data={beneficiariesData}
						loading={isBeneficiariesLoading}
						showProjectedDisclaimer={showProjectedDisclaimer}
					/>
					<PopulationHeaderPanel
						level="country"
						data={populationData}
						loading={isPopulationLoading}
					/>
				</div>
				<HeaderBottomIndicators
					data={employeesData}
					loading={isEmployeesLoading}
				/>
			</div>

			<div className="header-footer">
				<ExportLinks
					pdfEnabled={pdfEnabled}
					level="country"
					excelFullEnabled={false}
					pdfClickHandler={() =>
						exportRequest(countryCode, 'outlook', 'single', 'pdf', html)
					}
					excelClickHandler={(mode) =>
						exportRequest(countryCode, 'outlook', mode)
					}
				/>
				<HeaderInfo data={infoData} />
			</div>
		</>
	);
};

export default HeaderCountryDashboard;
