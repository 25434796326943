import { useQuery } from '@tanstack/react-query';

import config from 'config';

import { api } from './base';

const getOutputIndicators = async () =>
	api.get(config.API_V2_URLS.GLOBAL_OUTPUT_INDICATORS);

export const useOutputIndicators = (options) =>
	useQuery({
		queryFn: getOutputIndicators,
		...options,
	});
