import React from 'react';

import { Link } from '@wfp/ui';

const Documents = () => (
	<div className="footer-links">
		<h3>Documents</h3>
		<ul className="footer-links__list">
			<li>
				<Link
					href="https://docs.wfp.org/api/documents/WFP-0000125255/download/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Navigation guide
				</Link>
			</li>
		</ul>
	</div>
);

export default Documents;
