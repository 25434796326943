import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'react-tippy';

import { Legend } from 'components/atoms';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat, getHumanNumberWithSymbol } from 'utils';

export const ToolTipContent = ({ lines, data, labelMap }) => {
	const items = lines.map((line) => {
		const value = getHumanNumberFormat(data[line]);
		return (
			<div
				className="bar-tooltip__item horizontal"
				key={`tooltip-content-${line}-${labelMap[line].label}`}
			>
				<div className="label">{labelMap[line].label}</div>
				<div className="value">{value || ''}</div>
			</div>
		);
	});
	return (
		<div className="bar-tooltip">
			<div className="bar-tooltip__title">{data.label}</div>
			{items}
		</div>
	);
};

const GroupedBars = ({
	lines,
	data,
	maxValue: maxValueProp,
	colorScale,
	theme,
	tooltipOpenPosition,
	labelMap,
}) => {
	let valueSum = 0;

	const bars = lines.map((line, index) => {
		const [showedValue, symbol] = getHumanNumberWithSymbol(data[line]);
		const maxValue = maxValueProp > 0 ? maxValueProp : 1;
		const calcWith = (data[line] * 85) / maxValue;
		valueSum += data[line];
		const style = {
			width: `${calcWith < 1 ? 1 : calcWith}%`,
			backgroundColor: colorScale[index],
		};

		return (
			<div className="indicator" key={`line-${line}`}>
				<div className="bar" style={style} />
				<span className="value">
					{showedValue}
					<span className="value-symbol">{symbol}</span>
				</span>
			</div>
		);
	});

	if (!valueSum) return null;

	return (
		<div className="horizontal-bar-chart__group">
			<div className="label">{data.label}</div>
			<div className="bars-wrap">
				<Tooltip
					{...tooltipStyle}
					theme={theme}
					position={tooltipOpenPosition}
					className="tooltip"
					html={
						<ToolTipContent data={data} lines={lines} labelMap={labelMap} />
					}
				/>
				{bars}
			</div>
		</div>
	);
};

const HorizontalBarChart = ({
	key,
	data,
	colorScale,
	theme,
	maxRowAmmount = 1,
	tooltipOpenPosition,
}) => {
	const lines = ['needs_based_plan', 'implementation_plan', 'expenditures'];
	const labelMap = {
		needs_based_plan: {
			label: 'Approved needs-based plan',
			subLabel: 'entire year',
		},
		implementation_plan: {
			label: 'Updated implementation plan',
			subLabel: 'entire year',
		},
		expenditures: {
			label: 'Expenditures',
			subLabel: 'year-to-date',
		},
	};

	let maxValue = 0;
	data.forEach((item) => {
		const arr = lines.map((line) => item[line]);
		const currentMaxValue = Math.max(...arr);
		if (currentMaxValue > maxValue) maxValue = currentMaxValue;
	});

	const length = data?.length || 0;
	const maxItemsInRow = Math.ceil(length / maxRowAmmount);

	const mappedData = data?.reduce((resultArray, item, index) => {
		const chunkIndex =
			typeof maxItemsInRow !== 'undefined'
				? Math.floor(index / maxItemsInRow)
				: 0;

		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = []; // start a new chunk
		}

		resultArray[chunkIndex].push(item);

		return resultArray;
	}, []) || [[]];

	const lastMappedDataIndex = mappedData.length - 1;

	return (
		<div className="horizontal-bar-chart">
			<div className="horizontal-bar-chart__items">
				{mappedData.map((set, setIndex) => (
					<>
						<div
							className="horizontal-bar-chart__item-set"
							key={`horizontal-bar-chart-item-set-${setIndex}-${key}`}
						>
							{set.map((item) => (
								<GroupedBars
									key={`grouped-bars-${item.label}-bar-${item.implementation_plan}`}
									data={item}
									colorScale={colorScale}
									lines={lines}
									maxValue={maxValue}
									theme={theme}
									tooltipOpenPosition={tooltipOpenPosition}
									labelMap={labelMap}
								/>
							))}
						</div>
						{setIndex !== lastMappedDataIndex && (
							<div className="horizontal-bar-chart__separator" />
						)}
					</>
				))}
			</div>
			<Legend colorScale={colorScale} labelMap={labelMap} lines={lines} />
		</div>
	);
};

HorizontalBarChart.propTypes = {
	theme: PropTypes.string,
	tooltipOpenPosition: PropTypes.string,
	colorScale: PropTypes.array,
};

HorizontalBarChart.defaultProps = {
	theme: 'dark',
	tooltipOpenPosition: 'bottom',
	colorScale: ['#005F9B', '#4AA1E2', '#9DEAFF'],
};

export default HorizontalBarChart;
