import React from 'react';

import OneLineIndicator from '../OneLineIndicator';

const CountryIndicators = ({ countries = [] }) => {
	const classNames =
		'countries-indicators__item countries-indicators__list-item';

	if (!countries.length) return null;

	return (
		<div className="header-dashboard-item countries-indicators">
			<OneLineIndicator
				className="total"
				value={countries.length}
				label="Involved countries"
			/>
			<div className="countries-indicators__items">
				{countries.map(({ name }, index) => {
					let withComa = true;
					if (countries.length === index + 1) withComa = false;

					return (
						<p className={classNames} key={`header-countries-${name}`}>
							{name}
							{withComa && ', '}
						</p>
					);
				})}
			</div>
		</div>
	);
};

export default CountryIndicators;
