import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectCacheTime } from 'features/global/selectors';

export default () => {
	const expireTime = useSelector(selectCacheTime);

	useEffect(() => {
		if (!expireTime) return;
		const keys = Object.keys(localStorage);
		const timeouts = [];

		keys.forEach((key) => {
			try {
				const item = JSON.parse(localStorage.getItem(key));
				const now = Date.now();
				const isExpired = now > item.exp;

				if (item.isCachedPanel) {
					if (isExpired) {
						localStorage.removeItem(key);
					} else {
						const timeout = setTimeout(() => {
							localStorage.removeItem(key);
						}, item.exp - now);
						timeouts.push(timeout);
					}
				}
			} catch (error) {}
		});

		return () => timeouts.forEach((timeout) => clearTimeout(timeout));
	}, [expireTime]);

	return null;
};
