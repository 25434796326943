import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getRegionHeaderPopulation = async ({ code }) =>
	api.get(`${appConfig.API_V2_URLS.REGION_HEADER_POPULATION + code}/`);

export const useRegionHeaderPopulation = ({ code }, options) =>
	useQuery({
		queryFn: () => getRegionHeaderPopulation({ code }),
		...options,
	});
