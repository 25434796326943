import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { api } from './base';

const getCountryHeaderPopulation = async ({ code }) =>
	api.get(`${appConfig.API_V2_URLS.COUNTRY_HEADER_POPULATION + code}/`);

export const useCountryHeaderPopulation = ({ code }, options) =>
	useQuery({
		queryFn: () => getCountryHeaderPopulation({ code }),
		...options,
	});
