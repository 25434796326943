import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { getHeaderEmployeesWithPhoto } from './getHeaderEmployeesWithPhoto';

const getRegionHeaderEmployees = async ({ code }) =>
	getHeaderEmployeesWithPhoto(
		`${appConfig.API_V2_URLS.REGION_HEADER_EMPLOYEES + code}/`,
	);

export const useRegionHeaderEmployees = ({ code }, options) => {
	console.log('useRegionHeaderEmployees');

	return useQuery({
		queryFn: () => getRegionHeaderEmployees({ code }),
		...options,
	});
};
