/* istanbul ignore file */

export const selectUserData = (state) => state.global.userData;
export const selectUserConfig = (state) => state.global.userData.config;
export const selectCacheTime = (state) => state.global.config.LOCAL_STORAGE_TTL;
export const selectMenu = (state) => state.global.menu;
export const selectFavorites = (state) =>
	state.global.userData.config.favorites;
export const selectGlobal = (state) => state.global;
export const selectIsAdvanced = (state) =>
	state.global.userData.config.is_advanced;
export const selectCurrentYear = (state) => state.global.config.CURRENT_YEAR;
export const selectConfig = (state) => state.global.config;
