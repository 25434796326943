import {
	PublicClientApplication,
	InteractionRequiredAuthError,
} from '@azure/msal-browser';

import fetch from 'cross-fetch';

export const msalConfig = {
	auth: {
		clientId: process.env.INFO_APP_OIDC_CLIENT_ID,
		authority:
			'https://login.microsoftonline.com/462ad9ae-d7d9-4206-b874-71b1e079776f',
		redirectUri: `${window.location.origin}`,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
};

export const redirectRequest = {
	scopes: ['openid', 'profile', 'User.Read', 'email'],
};

export const graphConfig = {
	profilePhotoUrl: 'https://graph.microsoft.com/v1.0/me/photo/$value',
	userPhotoUrl: 'https://graph.microsoft.com/v1.0/users/{id}/photo/$value',
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const signIn = async (
	_redirectRequest = redirectRequest,
	_msalInstance = msalInstance,
) => {
	await _msalInstance.loginRedirect(_redirectRequest);
};

export const signOut = async (_msalInstance = msalInstance) => {
	await _msalInstance.logoutRedirect();
};

export const getTokenRedirect = async (
	_redirectRequest = redirectRequest,
	_msalInstance = msalInstance,
) => {
	const accounts = _msalInstance.getAllAccounts();
	const account = accounts && accounts.length === 1 ? accounts[0] : null;
	const requestWithAccount = { ..._redirectRequest, account };

	if (!account) {
		await signIn();
		return null;
	}

	return await _msalInstance
		.acquireTokenSilent(requestWithAccount)
		.catch(async (error) => {
			if (error instanceof InteractionRequiredAuthError) {
				return _msalInstance.acquireTokenRedirect(requestWithAccount);
			}

			throw new Error(error);
		});
};

export const getAccessToken = async (_getTokenRedirect = getTokenRedirect) =>
	_getTokenRedirect().then((response) => {
		if (!response) {
			throw new Error('Auth error. Token redirect response is empty');
		}

		return response.accessToken;
	});

export const getPhoto = async (
	email,
	_getAccessToken = getAccessToken,
	_graphConfig = graphConfig,
) => {
	const accessToken = await _getAccessToken();

	const response = fetch(
		email
			? graphConfig.userPhotoUrl.replace(/{id}/g, email)
			: graphConfig.profilePhotoUrl,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		},
	).catch((error) => {
		console.log(error);
		return null;
	});

	return response;
};
