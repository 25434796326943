import { useQuery } from '@tanstack/react-query';

import config from 'config';

import { api } from './base';

const getSiteMessages = async () => api.get(config.API_V2_URLS.SITE_MESSAGES);

export const useSiteMessages = (options) =>
	useQuery({
		queryKey: ['site-messages'],
		queryFn: getSiteMessages,
		...options,
	});
