import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { NoData } from 'components/atoms';
import { BarIndicatorWithLabels } from 'components/molecules';

import { getPercents } from 'utils';

const CompareGroupedIndicators = ({
	baseValue,
	maxAsBase,
	data,
	sumAsBase,
	autoOrdering,
	barLabelWidth,
	className,
	children,
}) => {
	let fixedBaseValue = baseValue;

	if (!baseValue && maxAsBase && data.length) {
		const current = Math.max(...data.map((i) => i.current.value));
		const prev = Math.max(...data.map((i) => i.previous.value));
		fixedBaseValue = current > prev ? current : prev;
	}

	if (!baseValue && sumAsBase && data.length)
		fixedBaseValue = data.reduce((a, b) => a + b.current.value, 0);

	let sortedData = data;
	if (autoOrdering)
		sortedData = data.sort((a, b) => b.current.value - a.current.value);

	const maxValue = useMemo(() => {
		const currentValues = data.map(({ current }) => current.value);
		const previousValues = data.map(({ previous }) => previous.value);
		const all = [...currentValues, ...previousValues];
		return Math.max(...all);
	}, [data]);

	const indicators = sortedData.map((item, index) => {
		let key = index;
		const { label, current, previous } = item;
		if (label) key = label.replace(' ', '-').toLowerCase();

		if (!current.value && !previous.value) return <React.Fragment key={key} />;

		let percents = getPercents(current.value, fixedBaseValue);
		if (fixedBaseValue < 0) {
			percents = -100;
		} else if (percents > 100) {
			percents = 100;
		}

		let prevLength = getPercents(previous.value, maxValue);
		if (prevLength > 100) prevLength = 100;
		if (prevLength < 0) prevLength = 0;

		const indicatorProps = {
			label,
			order: index + 1,
			value: current.value,
			length: current.value <= 0 ? 0 : getPercents(current.value, maxValue),
			percents,
			previous: [
				{
					value: previous.value,
					length: prevLength,
					borderStyle: 'solid',
					label: previous.year,
					year: previous.year,
				},
			],
			withTooltip: true,
			tooltipData: {
				values: [
					{ label: `${current.year} NGO exp.`, value: current.value },
					{ label: `${previous.year} NGO exp.`, value: previous.value },
				],
			},
		};

		if (barLabelWidth) {
			indicatorProps.truncateWidth = barLabelWidth;
		}

		return <BarIndicatorWithLabels key={key} {...indicatorProps} />;
	});

	return (
		<div className={`grouped-indicators ${className}`}>
			{data && data.length ? <></> : <NoData />}
			{indicators}
			{!!children && children}
		</div>
	);
};

CompareGroupedIndicators.propTypes = {
	baseValue: PropTypes.number,
	data: PropTypes.array,
	sumAsBase: PropTypes.bool,
	maxAsBase: PropTypes.bool,
	hideStick: PropTypes.bool,
	theme: PropTypes.string,
	barLabelWidth: PropTypes.number,
	type: PropTypes.string,
	className: PropTypes.string,
	autoOrdering: PropTypes.bool,
};

CompareGroupedIndicators.defaultProps = {
	baseValue: null,
	data: [],
	sumAsBase: false,
	maxAsBase: false,
	hideStick: false,
	theme: 'dark',
	type: '',
	className: '',
	autoOrdering: true,
};

export default CompareGroupedIndicators;
