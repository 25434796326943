import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Module, ModuleBody, ModuleFooter, ModuleHeader } from '@wfp/ui';

import { InfoIndicator, ExportLinks } from 'components/molecules';

import { exportRequest } from 'utils';

const Footer = ({
	enableFooter,
	panelKey,
	componentHTML,
	className,
	allowFullExcelExport,
	allowRawDataExcelExport,
	allowPdfExport,
	loading,
	infoHelpText,
	hoverText,
	isHover,
	infoTitle,
	theme,
	infoText,
}) => {
	const { countryCode, regionCode } = useParams();

	if (!enableFooter) return null;

	let level = 'global';
	if (countryCode) {
		level = 'country';
	} else if (regionCode) {
		level = 'region';
	}

	const formattedHtml = componentHTML.replace(
		`.${className}`,
		`.${className}-pdf`,
	);

	const code = countryCode || regionCode || 'global';

	return (
		<ModuleFooter className="panel-v2__footer">
			<ExportLinks
				isExcelDivided={level === 'global' || level === 'region'}
				level={level}
				pdfClickHandler={() =>
					exportRequest(code, panelKey, 'single', 'pdf', formattedHtml)
				}
				excelClickHandler={(mode = 'single') =>
					exportRequest(code, panelKey, mode)
				}
				excelFullEnabled={allowFullExcelExport}
				excelRawDataEnabled={allowRawDataExcelExport}
				pdfEnabled={allowPdfExport && !loading}
			/>
			<div className="help-text">{infoHelpText}</div>
			<InfoIndicator
				hoverText={hoverText}
				isHover={isHover}
				title={infoTitle}
				parentName={panelKey}
				theme={theme}
			>
				{infoText}
			</InfoIndicator>
		</ModuleFooter>
	);
};

const Panel = (props) => {
	const {
		className,
		title,
		titleSwitcher,
		horizontal,
		enablePDF,
		children,
		errorBannerText,
		allowFullExcelExport,
		allowRawDataExcelExport,
	} = props;

	const contentRef = useRef(null);

	let panelClassName = '';
	let headerClassName = '';
	let contentClassName = '';

	if (className) {
		panelClassName = `${className}`;
		headerClassName = `${className}__header`;
		contentClassName = `${className}__content`;
	}

	return (
		<Module className={`panel-v2 ${panelClassName}`} light>
			<ModuleHeader className={`panel-v2__header ${headerClassName}`}>
				<div>{title}</div>
				<div className="title-switcher">{titleSwitcher}</div>
			</ModuleHeader>

			<ModuleBody
				className={classNames(
					{
						'panel-v2__content': true,
						'panel-v2__content-horizontal': horizontal,
					},
					[contentClassName, !!contentClassName],
				)}
			>
				{enablePDF ? (
					<div ref={contentRef} className="pdf-wrap">
						{children}
					</div>
				) : (
					children
				)}
				{!!errorBannerText && (
					<div className="panel-v2__banner-error panel-v2__banner">
						<p>{errorBannerText}</p>
					</div>
				)}
			</ModuleBody>
			<Footer
				{...props}
				allowPdfExport={enablePDF}
				allowFullExcelExport={allowFullExcelExport}
				allowRawDataExcelExport={allowRawDataExcelExport}
			/>
		</Module>
	);
};

Panel.propTypes = {
	title: PropTypes.string,
	titleSwitcher: PropTypes.object,
	enableFooter: PropTypes.bool,
	className: PropTypes.string,
	allowFullExcelExport: PropTypes.bool,
	errorBannerText: PropTypes.string,
	enablePDF: PropTypes.bool,
	enableXLSX: PropTypes.bool,
	panelKey: PropTypes.string,
	horizontal: PropTypes.bool,
	allowRawDataExcelExport: PropTypes.bool,
	componentHTML: PropTypes.string,
};

Panel.defaultProps = {
	title: 'Example title',
	titleSwitcher: null,
	enableFooter: false,
	allowFullExcelExport: true,
	allowRawDataExcelExport: true,
	className: null,
	enablePDF: false,
	enableXLSX: true,
	errorBannerText: '',
};

export default Panel;
