import { useQuery } from '@tanstack/react-query';

import appConfig from 'config';

import { getHeaderEmployeesWithPhoto } from './getHeaderEmployeesWithPhoto';

const getGlobalHeaderEmployees = async () =>
	getHeaderEmployeesWithPhoto(appConfig.API_V2_URLS.GLOBAL_HEADER_EMPLOYEES);

export const useGlobalHeaderEmployees = (options) =>
	useQuery({
		queryFn: () => getGlobalHeaderEmployees(),
		...options,
	});
