/* istanbul ignore file */

import ReactGA from 'react-ga4';

const productionFunction = (callback) => {
	if (process.env.INFO_APP_GA_ID) {
		return callback;
	}
	return () => {};
};

export const gaOfficeSelectionEvent = productionFunction((office) => {
	ReactGA.event({
		category: 'Office selection',
		action: `WEB_Select_${office}`,
		label: `WEB_Select_${office}`,
	});
});

export const gaTabSelectionEvent = productionFunction((office, tab) => {
	ReactGA.event({
		category: 'Tab selection',
		action: `WEB_${tab}_${office}`,
		label: `WEB_${tab}_${office}`,
	});
});

export const gaSearchUsingEvent = productionFunction((type = 'Main') => {
	ReactGA.event({
		category: 'Search',
		action: `WEB_${type}_Search`,
		label: `WEB_${type}_Search`,
	});
});

export const gaInfoLinkEvent = productionFunction((office, panel) => {
	ReactGA.event({
		category: 'Info link',
		action: `WEB_${panel}_info_${office}`,
		label: `WEB_${panel}_info_${office}`,
	});
});

export const gaGlossaryEvent = productionFunction(() => {
	ReactGA.event({
		category: 'Glossary',
		action: 'WEB_Glossary',
		label: 'WEB_Glossary',
	});
});

export const gaHamburgerMenuOptionEvent = productionFunction((option) => {
	ReactGA.event({
		category: 'Hamburger menu',
		action: `WEB_Hamburger_${option}`,
		label: `WEB_Hamburger_${option}`,
	});
});
