import fetch from 'cross-fetch';

import { getAccessToken } from 'auth/azure-service';

export const api = {
	get: async (url) => {
		const accessToken = await getAccessToken();

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		const data = await response.json();

		if (!response.ok) {
			throw new Error('Request failed');
		}

		return data;
	},
	post: async (url, body) => {
		const accessToken = await getAccessToken();

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json;charset=UTF-8',
			},
			body: JSON.stringify(body),
		});

		const data = response.json();

		if (!response.ok) {
			throw new Error('Request failed');
		}

		return data;
	},
};
